import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../../messages/messagesActions";
import {
  IInvoiceInformation,
  initialAccountingInvoiceInformationState,
} from "./InvoiceInformation.types";

export const FETCH_ACCOUNTING_INVOICE_INFORMATION_PROGRESS =
  "FETCH_ACCOUNTING_INVOICE_INFORMATION_PROGRESS";
export const FETCH_ACCOUNTING_INVOICE_INFORMATION_SUCCESS =
  "FETCH_ACCOUNTING_INVOICE_INFORMATION_SUCCESS";
export const FETCH_ACCOUNTING_INVOICE_INFORMATION_FAILURE =
  "FETCH_ACCOUNTING_INVOICE_INFORMATION_FAILURE";

export const fetchAccountingInvoiceInformationProgress = () =>
  action(FETCH_ACCOUNTING_INVOICE_INFORMATION_PROGRESS);

export const fetchAccountingInvoiceInformationSuccess = (
  data: IInvoiceInformation
) => action(FETCH_ACCOUNTING_INVOICE_INFORMATION_SUCCESS, { data });

export const fetchAccountingInvoiceInformationFailed = (errorMessage: string) =>
  action(FETCH_ACCOUNTING_INVOICE_INFORMATION_FAILURE, { errorMessage });

export const fetchAccountingInvoiceInformationAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingInvoiceInformationProgress());

      const res = await api.get(`/accounting/get-account-invoice-information`);
      const data: IInvoiceInformation[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchAccountingInvoiceInformationSuccess(data[0]));
      } else {
        dispatch(
          fetchAccountingInvoiceInformationSuccess(
            initialAccountingInvoiceInformationState["data"]
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchAccountingInvoiceInformationFailed("Something went to be wrong!")
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addAccountingInvoiceInformationAysnc =
  (
    data: IInvoiceInformation,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      await api.post("/accounting/edit-account-invoice-information", {
        ...data,
      });
      let message = "Invoice Information saved successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const FETCH_ACCOUNTING_INVOICE_INFORMATION_LIST_PROGRESS =
  "FETCH_ACCOUNTING_INVOICE_INFORMATION_LIST_PROGRESS";
export const FETCH_ACCOUNTING_INVOICE_INFORMATION_LIST_SUCCESS =
  "FETCH_ACCOUNTING_INVOICE_INFORMATION_LIST_SUCCESS";
export const FETCH_ACCOUNTING_INVOICE_INFORMATION_LIST_FAILURE =
  "FETCH_ACCOUNTING_INVOICE_INFORMATION_LIST_FAILURE";

export const fetchAccountingInvoiceInformationListProgress = () =>
  action(FETCH_ACCOUNTING_INVOICE_INFORMATION_LIST_PROGRESS);

export const fetchAccountingInvoiceInformationListSuccess = (
  data: IInvoiceInformation[]
) => action(FETCH_ACCOUNTING_INVOICE_INFORMATION_LIST_SUCCESS, { data });

export const fetchAccountingInvoiceInformationListFailed = (
  errorMessage: string
) =>
  action(FETCH_ACCOUNTING_INVOICE_INFORMATION_LIST_FAILURE, { errorMessage });

export const fetchAccountingInvoiceInformationListAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingInvoiceInformationListProgress());

      const res = await api.get(`/accounting/get-account-invoice-information`);
      const data: IInvoiceInformation[] = res.data.data;
      dispatch(fetchAccountingInvoiceInformationListSuccess(data));
    } catch (err: any) {
      dispatch(
        fetchAccountingInvoiceInformationListFailed(
          "Something went to be wrong!"
        )
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_ACCOUNTING_INVOICE_INFORMATION =
  "CLEAR_ACCOUNTING_INVOICE_INFORMATION";
export const clearAccountingInvoiceInformation = () =>
  action(CLEAR_ACCOUNTING_INVOICE_INFORMATION);
