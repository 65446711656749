import React from "react";
import { CustomChip } from "../CustomChip";
import { NotificationsActive } from "@mui/icons-material";
import { capitalizeWords } from "../../helpers";

interface IActiveInActiveStatusProps {
  status: string;
}

export const StatusRenderer: React.FC<IActiveInActiveStatusProps> = (props) => {
  const status = (props.status || "").toLowerCase();
  if (status === "active") {
    return <CustomChip color="success" content={"Active"} size="small" />;
  } else if (status === "open") {
    return <CustomChip color="warning" content={"Open"} size="small" />;
  } else if (status === "closed") {
    return <CustomChip color="error" content={"Closed"} size="small" />;
  } else if (status === "re-opened") {
    return <CustomChip color="info" content={"Re-Opened"} size="small" />;
  } else if (status === "inactive") {
    return <CustomChip color="error" content={"Inactive"} size="small" />;
  } else if (status === "new submission") {
    return (
      <CustomChip color="success" content={"New Submission"} size="small" />
    );
  } else if (status === "quote received") {
    return (
      <CustomChip
        color="warning"
        content={"Quote Received"}
        sx={{ color: "#000" }}
        size="small"
      />
    );
  } else if (status === "remote work") {
    return (
      <CustomChip color="secondary" content={"Remote Work"} size="small" />
    );
  } else if (status === "on-leave") {
    return (
      <CustomChip color="info" content={"On-Leave"} size="small" />
    );
  }


  else if (status === "completed") {
    return (
      <CustomChip color="success" content={"Completed"} size="small" />
    );
  }
  else if (status === "pending") {
    return (
      <CustomChip color="warning" content={"Pending"} size="small" />
    );
  }

  else if (status === "opened") {
    return (
      <CustomChip color="success" content={"Opened"} size="small" />
    );
  }
  else if (status === "closed") {
    return (
      <CustomChip color="error" content={"Closed"} size="small" />
    );
  }
  else if(status === "pending_for_signature"){
    return (
      <CustomChip color="warning" content={"Pending for signature"} sx={{color: '#000'}} size="small" />
    );
  }
  else if(status === "overdue"){
    return (
      <CustomChip color="error" content={"Overdue"}  size="small" />
    );
  }
  else {
    return <CustomChip color="info" content={capitalizeWords(status) || "N/A"} size="small" />;
  }
};
