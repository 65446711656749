import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../../messages/messagesActions";
import {
  IPaymentAccountsRecievable,
  initialAccountingPaymentAccountsRecievableState,
} from "./PaymentAccountsRecievable.types";

export const FETCH_ACCOUNTING_PAYMENT_ACCOUNTS_RECIEVABLE_PROGRESS =
  "FETCH_ACCOUNTING_PAYMENT_ACCOUNTS_RECIEVABLE_PROGRESS";
export const FETCH_ACCOUNTING_PAYMENT_ACCOUNTS_RECIEVABLE_SUCCESS =
  "FETCH_ACCOUNTING_PAYMENT_ACCOUNTS_RECIEVABLE_SUCCESS";
export const FETCH_ACCOUNTING_PAYMENT_ACCOUNTS_RECIEVABLE_FAILURE =
  "FETCH_ACCOUNTING_PAYMENT_ACCOUNTS_RECIEVABLE_FAILURE";

export const fetchAccountingPaymentAccountsRecievableProgress = () =>
  action(FETCH_ACCOUNTING_PAYMENT_ACCOUNTS_RECIEVABLE_PROGRESS);

export const fetchAccountingPaymentAccountsRecievableSuccess = (
  data: IPaymentAccountsRecievable
) => action(FETCH_ACCOUNTING_PAYMENT_ACCOUNTS_RECIEVABLE_SUCCESS, { data });

export const fetchAccountingPaymentAccountsRecievableFailed = (
  errorMessage: string
) =>
  action(FETCH_ACCOUNTING_PAYMENT_ACCOUNTS_RECIEVABLE_FAILURE, {
    errorMessage,
  });

export const fetchAccountingPaymentAccountsRecievableAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingPaymentAccountsRecievableProgress());
      const res = await api.get(
        `/accounting/get-account-payment-accounts-receivable`
      );
      const data: IPaymentAccountsRecievable[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchAccountingPaymentAccountsRecievableSuccess(data[0]));
      } else {
        dispatch(
          fetchAccountingPaymentAccountsRecievableSuccess(
            initialAccountingPaymentAccountsRecievableState["data"]
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchAccountingPaymentAccountsRecievableFailed(
          "Something went to be wrong!"
        )
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addAccountingPaymentAccountsRecievableAysnc =
  (
    data: IPaymentAccountsRecievable,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      await api.post("/accounting/edit-account-payment-accounts-receivable", {
        ...data,
      });
      let message = "Payment Accounts Recievable saved successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted())
    }
  };



  export const FETCH_ACCOUNTING_PAYMENT_ACCOUNTS_RECIEVABLE_LIST_PROGRESS =
  "FETCH_ACCOUNTING_PAYMENT_ACCOUNTS_RECIEVABLE_LIST_PROGRESS";
export const FETCH_ACCOUNTING_PAYMENT_ACCOUNTS_RECIEVABLE_LIST_SUCCESS =
  "FETCH_ACCOUNTING_PAYMENT_ACCOUNTS_RECIEVABLE_LIST_SUCCESS";
export const FETCH_ACCOUNTING_PAYMENT_ACCOUNTS_RECIEVABLE_LIST_FAILURE =
  "FETCH_ACCOUNTING_PAYMENT_ACCOUNTS_RECIEVABLE_LIST_FAILURE";

export const fetchAccountingPaymentAccountsRecievableListProgress = () =>
  action(FETCH_ACCOUNTING_PAYMENT_ACCOUNTS_RECIEVABLE_LIST_PROGRESS);

export const fetchAccountingPaymentAccountsRecievableListSuccess = (
  data: IPaymentAccountsRecievable[]
) => action(FETCH_ACCOUNTING_PAYMENT_ACCOUNTS_RECIEVABLE_LIST_SUCCESS, { data });

export const fetchAccountingPaymentAccountsRecievableListFailed = (
  errorMessage: string
) =>
  action(FETCH_ACCOUNTING_PAYMENT_ACCOUNTS_RECIEVABLE_LIST_FAILURE, {
    errorMessage,
  });

export const fetchAccountingPaymentAccountsRecievableListAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingPaymentAccountsRecievableListProgress());
      const res = await api.get(
        `/accounting/get-account-payment-accounts-receivable`
      );
      const data: IPaymentAccountsRecievable[] = res.data.data;
        dispatch(fetchAccountingPaymentAccountsRecievableListSuccess(data));
    } catch (err: any) {
      dispatch(
        fetchAccountingPaymentAccountsRecievableListFailed(
          "Something went to be wrong!"
        )
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_ACCOUNTING_PAYMENT_ACCOUNTS_RECIEVABLE =
  "CLEAR_ACCOUNTING_PAYMENT_ACCOUNTS_RECIEVABLE";
export const clearAccountingPaymentAccountsRecievable = () =>
  action(CLEAR_ACCOUNTING_PAYMENT_ACCOUNTS_RECIEVABLE);
