import { LoadState } from "../../../constants/enums";

export interface IAccountingChequeState {
  list: IAccountingCheque[];
  listLoading: LoadState;
  data: IAccountingCheque;
  loading: LoadState;
  error: string | null;
}

export interface IAccountingCheque {
  payment_id: string | null;
  payment_method_cheque: string | null;
  cheque_number: string | null;
  cheque_date: string | null;
  cheque_amount: string | null;
  cheque_voided: string | null;
  cheque_voided_date: string | null;
  cheque_printed: string | null;
  cheque_printed_date: string | null;
  cheque_cleared: string | null;
  cheque_cleared_date: string | null;
  clear_pay_y_n: string | null;
  clear_pay_code: string | null;
  clear_pay_branch_code: string | null;
  status: string | null;
}

export const initialAccountingChequeState: IAccountingChequeState = {
  list: [],
  listLoading: LoadState.NotLoaded,
  data: {
    payment_id: null,
    payment_method_cheque: null,
    cheque_number: null,
    cheque_date: null,
    cheque_amount: null,
    cheque_voided: null,
    cheque_voided_date: null,
    cheque_printed: null,
    cheque_printed_date: null,
    cheque_cleared: null,
    cheque_cleared_date: null,
    clear_pay_y_n: null,
    clear_pay_code: null,
    clear_pay_branch_code: null,
    status: "Active",
  },
  loading: LoadState.NotLoaded,
  error: null,
};
