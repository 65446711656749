import React from "react";
import { DOCU_SIGN_FIELD_TYPES } from "./enums";
import { Box, Paper, SelectChangeEvent, Stack, useTheme } from "@mui/material";
import { ControlledCustomSelect } from "../formsComponents";
import { SignableField } from "./widgets/signableField/SignableField";
import { useSelector } from "react-redux";
import { IStoreState } from "../../redux/initialStoreState";
import { InitialSignField } from "./widgets/initialSignField/InitialSignField";
import { SignNameField } from "./widgets/SignNameField/SignNameField";
import { CompanyField } from "./widgets/companyField/CompanyField";

interface IOptionsBarProps {
  selected: IOptionsBarSelectedItem;
  onSelect: (value: IOptionsBarSelectedItem) => void;
}

export interface IOptionsBarSelectedItem {
  recipient: string;
  recipient_name: string;
  type: DOCU_SIGN_FIELD_TYPES;
}

export const OptionsBar: React.FC<IOptionsBarProps> = (props) => {
  const { selected, onSelect } = props;
  const theme = useTheme();

  const recipientList = useSelector(
    (storeState: IStoreState) => storeState.g4Sign.documents.recipientList
  );

  const handleRecipientChange = (e: SelectChangeEvent<unknown>) => {
    const value = e.target.value;
    const data = recipientList.find((x) => x.recipient_code === value);
    onSelect({
      type: selected.type,
      recipient: e.target.value as string,
      recipient_name: data?.signer_name as string,
    });
  };

  const handleSignature =(type: DOCU_SIGN_FIELD_TYPES)=> () => {
    let finalType = type;
    if(type === selected.type && selected.type !== DOCU_SIGN_FIELD_TYPES.NONE){
      finalType = DOCU_SIGN_FIELD_TYPES.NONE;
    } 
    onSelect({
      type: finalType,
      recipient: selected.recipient,
      recipient_name: selected.recipient_name,
    });
  };

  return (
    <Paper
      sx={{
        height: "60%",
        mt: 2,
        p: 2,

        border: `1.5px solid ` + theme.palette.grey[300],
      }}
    >
      <Stack direction={"column"} spacing={3} justifyContent={"center"}>
        <ControlledCustomSelect
          value={selected.recipient}
          options={recipientList.map((item) => {
            return { label: item.signer_name, value: item.recipient_code };
          })}
          onChange={handleRecipientChange}
          displayEmpty
          placeholder="Select recipient"
        />

        <Box
          sx={
            selected.type === DOCU_SIGN_FIELD_TYPES.SIGNATURE
              ? {
                  border: `1.5px solid ` + theme.palette.primary.main,
                  p: 1,
                }
              : {}
          }
          onClick={handleSignature(DOCU_SIGN_FIELD_TYPES.SIGNATURE)}
        >
          <SignableField dragDisabled />
        </Box>
        <Box
          sx={
            selected.type === DOCU_SIGN_FIELD_TYPES.FREE_SIGNATURE
              ? {
                  border: `1.5px solid ` + theme.palette.primary.main,
                  p: 1,
                }
              : {}
          }
          onClick={handleSignature(DOCU_SIGN_FIELD_TYPES.FREE_SIGNATURE)}
        >
          <InitialSignField dragDisabled />
        </Box>
        <Box
          sx={
            selected.type === DOCU_SIGN_FIELD_TYPES.FULL_NAME
              ? {
                  border: `1.5px solid ` + theme.palette.primary.main,
                  p: 1,
                }
              : {}
          }
          onClick={handleSignature(DOCU_SIGN_FIELD_TYPES.FULL_NAME)}
        >
          <SignNameField dragDisabled />
        </Box>
        <Box
          sx={
            selected.type === DOCU_SIGN_FIELD_TYPES.COMPANY
              ? {
                  border: `1.5px solid ` + theme.palette.primary.main,
                  p: 1,
                }
              : {}
          }
          onClick={handleSignature(DOCU_SIGN_FIELD_TYPES.COMPANY)}
        >
          <CompanyField dragDisabled />
        </Box>
      </Stack>
    </Paper>
  );
};
