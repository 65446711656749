import { LoadState } from "../../../constants/enums";

export interface IAccountingPaymentAccountsRecievableState {
  list: IPaymentAccountsRecievable[];
  listLoading: LoadState;
  data: IPaymentAccountsRecievable;
  loading: LoadState;
  error: string | null;
}

export interface IPaymentAccountsRecievable {
  payment_id: number | null;
  invoice_id: string | null;
  incremental_number: string | null;
  type_trust_or_misc: string | null;
  service: string | null;
  gl_accounts_id: string | null;
  payment_date: string | null;
  payment_amount: string | null;
  payment_method: string | null;
  reference: string | null;
  status: string;
}

export const initialAccountingPaymentAccountsRecievableState: IAccountingPaymentAccountsRecievableState =
  {
    list: [],
    listLoading: LoadState.NotLoaded,
    data: {
      payment_id: null,
      invoice_id: null,
      incremental_number: null,
      type_trust_or_misc: null,
      service: null,
      gl_accounts_id: null,
      payment_date: null,
      payment_amount: null,
      payment_method: null,
      reference: null,
      status: "ACTIVE",
    },
    loading: LoadState.NotLoaded,
    error: null,
  };
