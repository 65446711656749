import moment, { Moment } from "moment";
import { LoadState } from "../../../constants/enums";
import { getUniqueId } from "../../../helpers";

export interface IHabitationalApplicationState {
  data: IhabitationalStepOne[];
  loading: LoadState;
  step1: {
    data1: IhabitationalStepOne;
    loading1: LoadState;
    error1: string | null;
  };
  
}

export interface IhabitationalStepOne {
  customer_policy_id: number;
  insured_company: string | null;
  company_type: string | null;
  binder_number: string | null;
  policy_number: number;
  applicant_name: string | null;
  applicant_address: string | null;
  applicant_unit_or_suite: string | null;
  applicant_city: string | null;
  applicant_province_or_state: string | null;
  applicant_country: string | null;
  applicant_postal_or_zip_code: string | null;
  applicant_contact_number: string | null;
  applicant_home: string | null;
  applicant_cell: string | null;
  applicant_business: string | null;
  applicant_fax: string | null;
  applicant_email: string | null;
  applicant_website: string | null;
  applicant_preferred_language: string | null;
  broker_name: string | null;
  broker_address: string | null;
  broker_unit_or_suite: string | null;
  broker_city: string | null;
  broker_province_or_state: string | null;
  broker_country: string | null;
  broker_postal_or_zip_code: string | null;
  broker_contact_name: string | null;
  broker_home: string | null;
  broker_cell: string | null;
  broker_business: string | null;
  broker_fax: string | null;
  broker_email: string | null;
  broker_contract_no: string | null;
  broker_sub_contract_no: string | null;
  broker_client_id: string | null;
  broker_company_client_id: string | null;
  broker_group_name: string | null;
  broker_group_id: string | null;
  policy_period_effective_date: string;
  policy_period_effective_time: string;
  policy_period_expiry_date: string;
  policy_period_expiry_time: string;
  applicant_data: {
    key: string;
    applicant_name: string | null;
    occupation: string | null;
    years_continuously_employed: string | null;
    date_of_birth: string;
  }[];

  loss_history: {
    key: string;
    date_of_loss: string;
    loc_no: string | null;
    cause_of_loss: string | null;
    status: string | null;
    amount_paid: string | null;
    insurance_company_1: string | null;
    policy_number: string | null;
  }[];
  policy_history: {
    key: string;
    insurance_company_2: string | null;
    policy_number: string | null;
    effective_date: string;
    end_date: string;
    reason_for_ending: string | null;
    if_terminated_by_insurer_reason: string | null;
  }[];
  cross_reference_information: {
    key: string;
    line_of_business: string | null;
    policy_number: string | null;
  }[];

  risk_address: string | null;
  risk_unit_suite: string | null;
  risk_city: string | null;
  risk_province_or_state: string | null;
  risk_postal_or_zip_code: string | null;
  risk_country: string | null;
  risk_location_construction: {
    year_built: string | null;
    no_of_storeys: string | null;
    no_of_families: string | null;
    no_of_units: string | null;
    total_living_area: string | null;
    total_living_area_unit: string | null;
    access_type: string | null;
    smokers: string | null;
    replacement_cost_emulator_product: string | null;
    data_evaluation_completed: string;
    date_of_birth_of_eldest_occupant: string;
    relationship_to_applicant: string | null;
    occupancy_type: string | null;
    structure_type_1: string | null;
    structure_type_2: string | null;
    structure_type_3: string | null;
    finished_basement_percentage: string | null;
    exterior_wall_framing_type: string | null;
    exterior_wall_finish_type: string | null;
  };
  interior_wall_construction_type: {
    key: string;
    type: string | null;
    percentage: string | null;
  }[];
  interior_wall_height: {
    key: string;
    height: string | null;
    height_unit: string | null;
    percentage: string | null;
  }[];
  interior_floor_finish_type: {
    key: string;
    type: string | null;
    percentage: string | null;
  }[];
  ceiling_construction_type: {
    key: string;
    type: string | null;
    percentage: string | null;
  }[];
  risk_location_upgrades: {
    key: string;
    upgrade_name: string;
    full_yy: string | null;
    partial_yy: string | null;
  }[];
  risk_location_roof_covering_type: string | null;
  risk_location_electrical_wiring_type: string | null;
  risk_location_electrical_panel_type: string | null;
  risk_location_service: string | null;
  primary_heating_type: {
    apparatus: string | null;
    fuel: string | null;
    location: string | null;
    professionally_installed: string | null;
    approved_by_ulc_csa_or_wh: string | null;
  };
  auxiliary_heating_type: {
    apparatus: string | null;
    fuel: string | null;
    location: string | null;
    professionally_installed: string | null;
    approved_by_ulc_csa_or_wh: string | null;
    no_of_face_cords_per_year: string | null;
    solid_fuel_heating_questionaire_attached: string | null;
    radiant_heating_area: string | null;
    radiant_heating_area_unit: string | null;
    make: string | null;
    year: string | null;
    oil_tank_year: string | null;
    oil_tank_year_type: string | null;
    fuel_oil_tank_questionaire_attached: string | null;
  };
  plumbing_type: {
    copper_percentage: string | null;
    gaivanized_percentage: string | null;
    abs_percentage: string | null;
    pvc_percentage: string | null;
    pex_percentage: string | null;
    poly_b_percentage: string | null;
    lead_percentage: string | null;
    other: string | null;
  };
  water_heater_type: {
    apparatus: string | null;
    water_heater_type: string | null;
    fuel: string | null;
    professionally_installed: string | null;
    approved_by_ulc_csa_or_wh: string | null;
  };
  primary_water_mitigation_type: {
    sump_pump_type: string | null;
    auxiliary_power: string | null;
    backup_value: string | null;
  }[];
  auxiliary_water_mitigation_type: {
    sump_pump_type: string | null;
    auxiliary_power: string | null;
    backup_value: string | null;
    main_water_value_shut_off_type: string | null;
    no_of_main_water_value_shut_off_sensor: string | null;
    sewer_backeup_questionaire_attached: string | null;
  }[];
  fire_protection: {
    distance_to_hydrant: string | null;
    hydrant_type: string | null;
    distance_to_responding_fire_hall: string | null;
    fire_hall_name: string | null;
  }[];
  security_system: {
    fire: string | null;
    burglary: string | null;
    smoke_detectors: string | null;
    smoke_detector_type: string | null;
    no_of_detectors: string | null;
    if_any_of_the_above_are_monitored_monitored_by: string | null;
    home_sprinklered: string | null;
    alarm_certificate: boolean;
    premises_type_security_system: string | null;
  };
  bathrooms: {
    no_of_full: string | null;
    no_of_half: string | null;
  }[];
  kitchens: {
    no_of_kitchens: string | null;
    kitchen_1_quality: string | null;
    kitchen_2_quality: string | null;
  }[];
  garage_or_carport: {
    no_of_cars: string | null;
    garage_type: string | null;
  }[];
  swimming_pool: {
    year: string | null;
    pool_type: string | null;
    pool_fenced: string | null;
  }[];
  detached_outbuildings_structures: {
    key: string;
    s_no: number;
    year: string | null;
    structure_type: string | null;
    exterior_wall_framing_type: string | null;
    heating_apparatus_type: string | null;
    fuel_type: string | null;
    total_area: string | null;
    total_area_type: string | null;
    value: string | null
  }[];

  mortgage_or_loss_payee: {
    key: string;
    name: string | null;
    nature_of_interest: string | null;
    address: string | null;
    city_province_or_state: string | null;
    postal_or_zip_code: string | null;
  }[],
  liability_exposures: {
    do_you_own_rent_more_than_one_location: null;
    number_of_weeks_location_rented_to_others: null;
    number_of_rooms_rented_to_others: null;
    daycare_operation_number_of_children: null;
    do_you_own_a_trampoline: null;
    do_you_have_a_garden_tractor: null;
    do_you_have_a_golf_cart: null;
    no_of_saddle_draft_animals: null;
    do_you_own_any_unlicensed_recreational_vehicles: null;
    renewable_energy_installation_on_premises: null;
    do_you_own_any_watercrafts: null;
    number_of_full_residence_employees: null;
    is_there_a_co_occupant_that_requires_coverage: null;
    co_occupant_name: null;
    is_there_any_kind_of_business_operation: null;
    if_yes_describe_business: null;
    number_of_dogs_in_the_household: null;
    breed_of_dogs: null;
    total_property_area: null;
    number_of_cannabis_plants_grown_on_premises: null;
    other_exposures: null;
  }[];
  address_form: {
    key: string;
    serial_number: number;
    address_type: string | null;
    address: string | null;
    unit_of_suit: string | null;
    state: string | null;
    city: string | null;
    postal_code: string | null;
    country: string | null;
    date_moved_in: string;
    date_moved_out: string;
  }[];

  attachments: {
    key: string;
    attachments_serial_number: number;
    attachments_date_completed: string;
    attachments_description: string | null;
    attachments_documents_link: string | null;
  }[]

  coverages_form: {
    key: string;
    coverage_discription: string | null;
    requested_or_declined: string | null;
    amount_of_insurance: string | null;
    deductible: string | null;
    deductible_type: string | null;
    type_of: string | null;
    premium: string | null;
  }[];
  liability_extension_and_exclusion_form: {
    key: string;
    liability_coverage_discription: string | null;
    requested_or_declined: string | null;
    amount_of_insurance: string | null;
    deductible: string | null;
    deductible_type: string | null;
    type_of: string | null;
    estimated_premium: string | null;
  }[];
  discount_and_surcharges_form: {
    key: string;
    discount_or_surcharge_discription: string | null;
    percentage: string | null;
    applied_to_premium: string | null;
    est_discount_or_surchage: string | null;
  }[];
  premium_info_type_of_payment_plan: string | null;
  premium_info_estimated_policy_premium: string | null;
  premium_info_provincial_sales_tax: string | null;
  premium_info_additional_charges: string | null;
  premium_info_total_estimated_cost: string | null;
  premium_info_amount_paid_with_application: string | null;
  premium_info_amount_still_due: string | null;
  premium_info_no_of_remaining_instalments: string | null;
  premium_info_amount_of_each_instalment: string | null;
  premium_info_instalment_due_date: string;
  premium_info_Remarks: string | null;
  status: string | null;
}

export const defaultHabitationalApplication: IHabitationalApplicationState = {
  data: [],
  loading: LoadState.NotLoaded,

  step1: {
    data1: {
      customer_policy_id: 0,
      insured_company: null,
      company_type: null,
      binder_number: null,
      policy_number: 0,
      applicant_name: null,
      applicant_address: null,
      applicant_unit_or_suite: null,
      applicant_city: null,
      applicant_province_or_state: null,
      applicant_country: null,
      applicant_postal_or_zip_code: null,
      applicant_home: null,
      applicant_contact_number: null,
      applicant_cell: null,
      applicant_business: null,
      applicant_fax: null,
      applicant_email: null,
      applicant_website: null,
      applicant_preferred_language: null,
      broker_name: null,
      broker_address: null,
      broker_unit_or_suite: null,
      broker_city: null,
      broker_province_or_state: null,
      broker_country: null,
      broker_postal_or_zip_code: null,
      broker_contact_name: null,
      broker_home: null,
      broker_cell: null,
      broker_business: null,
      broker_fax: null,
      broker_email: null,
      broker_contract_no: null,
      broker_sub_contract_no: null,
      broker_client_id: null,
      broker_company_client_id: null,
      broker_group_name: null,
      broker_group_id: null,
      policy_period_effective_date: moment().format("YYYY-MM-DD"),
      policy_period_effective_time: moment().format(),
      policy_period_expiry_date: moment().format("YYYY-MM-DD"),
       policy_period_expiry_time: moment().format(),
      applicant_data: [
        {
          key: getUniqueId(),
          applicant_name: null,
          occupation: null,
          years_continuously_employed: null,
          date_of_birth: moment().format("YYYY-MM-DD"),
        },
        {
          key: getUniqueId(),
          applicant_name: null,
          occupation: null,
          years_continuously_employed: null,
          date_of_birth: moment().format("YYYY-MM-DD"),
        },
      ],
      loss_history: [
        {
          key: getUniqueId(),
          date_of_loss: moment().format("YYYY-MM-DD"),
          loc_no: null,
          cause_of_loss: null,
          status: null,
          amount_paid: null,
          insurance_company_1: null,
          policy_number: null,
        },
        {
          key: getUniqueId(),
          date_of_loss: moment().format("YYYY-MM-DD"),
          loc_no: null,
          cause_of_loss: null,
          status: null,
          amount_paid: null,
          insurance_company_1: null,
          policy_number: null,
        },
        {
          key: getUniqueId(),
          date_of_loss: moment().format("YYYY-MM-DD"),
          loc_no: null,
          cause_of_loss: null,
          status: null,
          amount_paid: null,
          insurance_company_1: null,
          policy_number: null,
        },
        {
          key: getUniqueId(),
          date_of_loss: moment().format("YYYY-MM-DD"),
          loc_no: null,
          cause_of_loss: null,
          status: null,
          amount_paid: null,
          insurance_company_1: null,
          policy_number: null,
        },
      ],
      policy_history: [
        {
          key: getUniqueId(),
          insurance_company_2: null,
          policy_number: null,
          effective_date: moment().format("YYYY-MM-DD"),
          end_date: moment().format("YYYY-MM-DD"),
          reason_for_ending: null,
          if_terminated_by_insurer_reason: null,
        },
        {
          key: getUniqueId(),
          insurance_company_2: null,
          policy_number: null,
          effective_date: moment().format("YYYY-MM-DD"),
          end_date: moment().format("YYYY-MM-DD"),
          reason_for_ending: null,
          if_terminated_by_insurer_reason: null,
        },
        {
          key: getUniqueId(),
          insurance_company_2: null,
          policy_number: null,
          effective_date: moment().format("YYYY-MM-DD"),
          end_date: moment().format("YYYY-MM-DD"),
          reason_for_ending: null,
          if_terminated_by_insurer_reason: null,
        },
        {
          key: getUniqueId(),
          insurance_company_2: null,
          policy_number: null,
          effective_date: moment().format("YYYY-MM-DD"),
          end_date: moment().format("YYYY-MM-DD"),
          reason_for_ending: null,
          if_terminated_by_insurer_reason: null,
        },
      ],
      cross_reference_information: [
        {
          key: getUniqueId(),
          line_of_business: null,
          policy_number: null,
        },
        {
          key: getUniqueId(),
          line_of_business: null,
          policy_number: null,
        },
        {
          key: getUniqueId(),
          line_of_business: null,
          policy_number: null,
        },
        {
          key: getUniqueId(),
          line_of_business: null,
          policy_number: null,
        },
      ],

      risk_address: null,
      risk_unit_suite: null,
      risk_city: null,
      risk_province_or_state: null,
      risk_postal_or_zip_code: null,
      risk_country: null,
      risk_location_construction:
      {
        year_built: null,
        no_of_storeys: null,
        no_of_families: null,
        no_of_units: null,
        total_living_area: null,
        total_living_area_unit: null,
        access_type: null,
        smokers: null,
        replacement_cost_emulator_product: null,
        data_evaluation_completed: moment().format("YYYY-MM-DD"),
        date_of_birth_of_eldest_occupant: moment().format("YYYY-MM-DD"),
        relationship_to_applicant: null,
        occupancy_type: null,
        structure_type_1: null,
        structure_type_2: null,
        structure_type_3: null,
        finished_basement_percentage: null,
        exterior_wall_framing_type: null,
        exterior_wall_finish_type: null,
      },
      interior_wall_construction_type: [
        {
          key: getUniqueId(),
          type: null,
          percentage: null,
        },
        {
          key: getUniqueId(),
          type: null,
          percentage: null,
        },
        {
          key: getUniqueId(),
          type: null,
          percentage: null,
        },
      ],
      interior_wall_height: [
        {
          key: getUniqueId(),
          height: null,
          height_unit: null,
          percentage: null,
        },
        {
          key: getUniqueId(),
          height: null,
          height_unit: null,
          percentage: null,
        },
        {
          key: getUniqueId(),
          height: null,
          height_unit: null,
          percentage: null,
        },
      ],
      interior_floor_finish_type: [
        {
          key: getUniqueId(),
          type: null,
          percentage: null,
        },
        {
          key: getUniqueId(),
          type: null,
          percentage: null,
        },
        {
          key: getUniqueId(),
          type: null,
          percentage: null,
        },
      ],
      ceiling_construction_type: [
        {
          key: getUniqueId(),
          type: null,
          percentage: null,
        },
        {
          key: getUniqueId(),
          type: null,
          percentage: null,
        },
        {
          key: getUniqueId(),
          type: null,
          percentage: null,
        },
      ],
      risk_location_upgrades: [
        {
          key: getUniqueId(),
          upgrade_name: "Roof",
          full_yy: null,
          partial_yy: null,
        },
        {
          key: getUniqueId(),
          upgrade_name: "Electrical",
          full_yy: null,
          partial_yy: null,
        },
        {
          key: getUniqueId(),
          upgrade_name: "Heating",
          full_yy: null,
          partial_yy: null,
        },
        {
          key: getUniqueId(),
          upgrade_name: "Plumbing",
          full_yy: null,
          partial_yy: null,
        },
      ],
      risk_location_roof_covering_type: null,
      risk_location_electrical_wiring_type: null,
      risk_location_electrical_panel_type: null,
      risk_location_service: null,
      primary_heating_type:
      {
        apparatus: null,
        fuel: null,
        location: null,
        professionally_installed: null,
        approved_by_ulc_csa_or_wh: null,
      },
      auxiliary_heating_type:
      {
        apparatus: null,
        fuel: null,
        location: null,
        professionally_installed: null,
        approved_by_ulc_csa_or_wh: null,
        no_of_face_cords_per_year: null,
        solid_fuel_heating_questionaire_attached: null,
        radiant_heating_area: null,
        radiant_heating_area_unit: null,
        make: null,
        year: null,
        oil_tank_year: null,
        oil_tank_year_type: null,
        fuel_oil_tank_questionaire_attached: null,
      },
      plumbing_type:
      {
        copper_percentage: null,
        gaivanized_percentage: null,
        abs_percentage: null,
        pvc_percentage: null,
        pex_percentage: null,
        poly_b_percentage: null,
        lead_percentage: null,
        other: null,
      },
      water_heater_type:
      {
        apparatus: null,
        water_heater_type: null,
        fuel: null,
        professionally_installed: null,
        approved_by_ulc_csa_or_wh: null,
      },
      primary_water_mitigation_type: [
        {
          sump_pump_type: null,
          auxiliary_power: null,
          backup_value: null,
        },
      ],
      auxiliary_water_mitigation_type: [
        {
          sump_pump_type: null,
          auxiliary_power: null,
          backup_value: null,
          main_water_value_shut_off_type: null,
          no_of_main_water_value_shut_off_sensor: null,
          sewer_backeup_questionaire_attached: null,
        },
      ],
      fire_protection: [
        {
          distance_to_hydrant: null,
          hydrant_type: null,
          distance_to_responding_fire_hall: null,
          fire_hall_name: null,
        },
      ],
      security_system:
      {
        fire: null,
        burglary: null,
        smoke_detectors: null,
        smoke_detector_type: null,
        no_of_detectors: null,
        if_any_of_the_above_are_monitored_monitored_by: null,
        home_sprinklered: null,
        alarm_certificate: false,
        premises_type_security_system: null,
      },
      bathrooms: [
        {
          no_of_full: null,
          no_of_half: null,
        },
      ],
      kitchens: [
        {
          no_of_kitchens: null,
          kitchen_1_quality: null,
          kitchen_2_quality: null,
        },
      ],
      garage_or_carport: [
        {
          no_of_cars: null,
          garage_type: null,
        },
      ],
      swimming_pool: [
        {
          year: null,
          pool_type: null,
          pool_fenced: null,
        },
      ],
      detached_outbuildings_structures: [
        {
          key: getUniqueId(),
          s_no: 1,
          year: null,
          structure_type: null,
          exterior_wall_framing_type: null,
          heating_apparatus_type: null,
          fuel_type: null,
          total_area: null,
          total_area_type: null,
          value: null,
        },
        {
          key: getUniqueId(),
          s_no: 2,
          year: null,
          structure_type: null,
          exterior_wall_framing_type: null,
          heating_apparatus_type: null,
          fuel_type: null,
          total_area: null,
          total_area_type: null,
          value: null,
        },
        {
          key: getUniqueId(),
          s_no: 3,
          year: null,
          structure_type: null,
          exterior_wall_framing_type: null,
          heating_apparatus_type: null,
          fuel_type: null,
          total_area: null,
          total_area_type: null,
          value: null,
        },
      ],
      mortgage_or_loss_payee: [
        {
          key: getUniqueId(),
          name: null,
          nature_of_interest: null,
          address: null,
          city_province_or_state: null,
          postal_or_zip_code: null,
        },
        {
          key: getUniqueId(),
          name: null,
          nature_of_interest: null,
          address: null,
          city_province_or_state: null,
          postal_or_zip_code: null,
        },
        {
          key: getUniqueId(),
          name: null,
          nature_of_interest: null,
          address: null,
          city_province_or_state: null,
          postal_or_zip_code: null,
        },
      ],
      liability_exposures: [
        {
          do_you_own_rent_more_than_one_location: null,
          number_of_weeks_location_rented_to_others: null,
          number_of_rooms_rented_to_others: null,
          daycare_operation_number_of_children: null,
          do_you_own_a_trampoline: null,
          do_you_have_a_garden_tractor: null,
          do_you_have_a_golf_cart: null,
          no_of_saddle_draft_animals: null,
          do_you_own_any_unlicensed_recreational_vehicles: null,
          renewable_energy_installation_on_premises: null,
          do_you_own_any_watercrafts: null,
          number_of_full_residence_employees: null,
          is_there_a_co_occupant_that_requires_coverage: null,
          co_occupant_name: null,
          is_there_any_kind_of_business_operation: null,
          if_yes_describe_business: null,
          number_of_dogs_in_the_household: null,
          breed_of_dogs: null,
          total_property_area: null,
          number_of_cannabis_plants_grown_on_premises: null,
          other_exposures: null,
        },
      ],
      address_form: [
        {
          key: getUniqueId(),
          serial_number: 1,
          address_type: null,
          address: null,
          unit_of_suit: null,
          state: null,
          city: null,
          postal_code: null,
          country: null,
          date_moved_in: moment().format("YYYY-MM-DD"),
          date_moved_out: moment().format("YYYY-MM-DD"),
        },
        {
          key: getUniqueId(),
          serial_number: 2,
          address_type: null,
          address: null,
          unit_of_suit: null,
          state: null,
          city: null,
          postal_code: null,
          country: null,
          date_moved_in: moment().format("YYYY-MM-DD"),
          date_moved_out: moment().format("YYYY-MM-DD"),
        },
        {
          key: getUniqueId(),
          serial_number: 3,
          address_type: null,
          address: null,
          unit_of_suit: null,
          state: null,
          city: null,
          postal_code: null,
          country: null,
          date_moved_in: moment().format("YYYY-MM-DD"),
          date_moved_out: moment().format("YYYY-MM-DD"),
        },
      ],
      attachments: [
        {
          key: getUniqueId(),
          attachments_serial_number: 1,
          attachments_date_completed: moment().format("YYYY-MM-DD"),
          attachments_description: null,
          attachments_documents_link: null,
        },
        {
          key: getUniqueId(),
          attachments_serial_number: 2,
          attachments_date_completed: moment().format("YYYY-MM-DD"),
          attachments_description: null,
          attachments_documents_link: null,
        },
        {
          key: getUniqueId(),
          attachments_serial_number: 3,
          attachments_date_completed: moment().format("YYYY-MM-DD"),
          attachments_description: null,
          attachments_documents_link: null,
        },
        {
          key: getUniqueId(),
          attachments_serial_number: 4,
          attachments_date_completed: moment().format("YYYY-MM-DD"),
          attachments_description: null,
          attachments_documents_link: null,
        },
        {
          key: getUniqueId(),
          attachments_serial_number: 5,
          attachments_date_completed: moment().format("YYYY-MM-DD"),
          attachments_description: null,
          attachments_documents_link: null,
        },
        {
          key: getUniqueId(),
          attachments_serial_number: 6,
          attachments_date_completed: moment().format("YYYY-MM-DD"),
          attachments_description: null,
          attachments_documents_link: null,
        },
      ],
      coverages_form: [
        {
          key: getUniqueId(),
          coverage_discription: "Dwelling Buildings",
          deductible: null,
          requested_or_declined: null,
          amount_of_insurance: null,
          deductible_type: null,
          type_of: null,
          premium: null,
        },
        {
          key: getUniqueId(),
          coverage_discription: "Detached Private Structures",
          deductible: null,
          requested_or_declined: null,
          amount_of_insurance: null,
          deductible_type: null,
          type_of: null,
          premium: null,
        },
        {
          key: getUniqueId(),
          coverage_discription: "Personal Property",
          deductible: null,
          requested_or_declined: null,
          amount_of_insurance: null,
          deductible_type: null,
          type_of: null,
          premium: null,
        },
        {
          key: getUniqueId(),
          coverage_discription: "Additional Living Expenses",
          deductible: null,
          requested_or_declined: null,
          amount_of_insurance: null,
          deductible_type: null,
          type_of: null,
          premium: null,
        },
        {
          key: getUniqueId(),
          coverage_discription: "Legal Liability",
          deductible: null,
          requested_or_declined: null,
          amount_of_insurance: null,
          deductible_type: null,
          type_of: null,
          premium: null,
        },
        {
          key: getUniqueId(),
          coverage_discription: "Voluntary Medical Payments",
          deductible: null,
          requested_or_declined: null,
          amount_of_insurance: null,
          deductible_type: null,
          type_of: null,
          premium: null,
        },
        {
          key: getUniqueId(),
          coverage_discription: "Voluntary Payment Damage",
          deductible: null,
          requested_or_declined: null,
          amount_of_insurance: null,
          deductible_type: null,
          type_of: null,
          premium: null,
        },
        {
          key: getUniqueId(),
          coverage_discription: "Sewer Backup",
          deductible: null,
          requested_or_declined: null,
          amount_of_insurance: null,
          deductible_type: null,
          type_of: null,
          premium: null,
        },
        {
          key: getUniqueId(),
          coverage_discription: null,
          deductible: null,
          requested_or_declined: null,
          amount_of_insurance: null,
          deductible_type: null,
          type_of: null,
          premium: null,
        },
        {
          key: getUniqueId(),
          coverage_discription: null,
          deductible: null,
          requested_or_declined: null,
          amount_of_insurance: null,
          deductible_type: null,
          type_of: null,
          premium: null,
        },
        {
          key: getUniqueId(),
          coverage_discription: null,
          deductible: null,
          requested_or_declined: null,
          amount_of_insurance: null,
          deductible_type: null,
          type_of: null,
          premium: null,
        },
        {
          key: getUniqueId(),
          coverage_discription: null,
          deductible: null,
          requested_or_declined: null,
          amount_of_insurance: null,
          deductible_type: null,
          type_of: null,
          premium: null,
        },
        {
          key: getUniqueId(),
          coverage_discription: null,
          deductible: null,
          requested_or_declined: null,
          amount_of_insurance: null,
          deductible_type: null,
          type_of: null,
          premium: null,
        },
        {
          key: getUniqueId(),
          coverage_discription: null,
          deductible: null,
          requested_or_declined: null,
          amount_of_insurance: null,
          deductible_type: null,
          type_of: null,
          premium: null,
        },
        {
          key: getUniqueId(),
          coverage_discription: null,
          deductible: null,
          requested_or_declined: null,
          amount_of_insurance: null,
          deductible_type: null,
          type_of: null,
          premium: null,
        },
        {
          key: getUniqueId(),
          coverage_discription: null,
          deductible: null,
          requested_or_declined: null,
          amount_of_insurance: null,
          deductible_type: null,
          type_of: null,
          premium: null,
        },
        {
          key: getUniqueId(),
          coverage_discription: null,
          deductible: null,
          requested_or_declined: null,
          amount_of_insurance: null,
          deductible_type: null,
          type_of: null,
          premium: null,
        },
        {
          key: getUniqueId(),
          coverage_discription: null,
          deductible: null,
          requested_or_declined: null,
          amount_of_insurance: null,
          deductible_type: null,
          type_of: null,
          premium: null,
        },
        {
          key: getUniqueId(),
          coverage_discription: null,
          deductible: null,
          requested_or_declined: null,
          amount_of_insurance: null,
          deductible_type: null,
          type_of: null,
          premium: null,
        },
        {
          key: getUniqueId(),
          coverage_discription: null,
          deductible: null,
          requested_or_declined: null,
          amount_of_insurance: null,
          deductible_type: null,
          type_of: null,
          premium: null,
        },
      ],
      liability_extension_and_exclusion_form: [
        {
          key: getUniqueId(),
          liability_coverage_discription: null,
          deductible: null,
          requested_or_declined: null,
          amount_of_insurance: null,
          deductible_type: null,
          type_of: null,
          estimated_premium: null,
        },
        {
          key: getUniqueId(),
          liability_coverage_discription: null,
          deductible: null,
          requested_or_declined: null,
          amount_of_insurance: null,
          deductible_type: null,
          type_of: null,
          estimated_premium: null,
        },
        {
          key: getUniqueId(),
          liability_coverage_discription: null,
          deductible: null,
          requested_or_declined: null,
          amount_of_insurance: null,
          deductible_type: null,
          type_of: null,
          estimated_premium: null,
        },
        {
          key: getUniqueId(),
          liability_coverage_discription: null,
          deductible: null,
          requested_or_declined: null,
          amount_of_insurance: null,
          deductible_type: null,
          type_of: null,
          estimated_premium: null,
        },
      ],
      discount_and_surcharges_form: [
        {
          key: getUniqueId(),
          discount_or_surcharge_discription: null,
          percentage: null,
          applied_to_premium: null,
          est_discount_or_surchage: null,
        },
        {
          key: getUniqueId(),
          discount_or_surcharge_discription: null,
          percentage: null,
          applied_to_premium: null,
          est_discount_or_surchage: null,
        },
        {
          key: getUniqueId(),
          discount_or_surcharge_discription: null,
          percentage: null,
          applied_to_premium: null,
          est_discount_or_surchage: null,
        },
        {
          key: getUniqueId(),
          discount_or_surcharge_discription: null,
          percentage: null,
          applied_to_premium: null,
          est_discount_or_surchage: null,
        },
        {
          key: getUniqueId(),
          discount_or_surcharge_discription: null,
          percentage: null,
          applied_to_premium: null,
          est_discount_or_surchage: null,
        },
        {
          key: getUniqueId(),
          discount_or_surcharge_discription: null,
          percentage: null,
          applied_to_premium: null,
          est_discount_or_surchage: null,
        },
      ],
      premium_info_type_of_payment_plan: null,
      premium_info_estimated_policy_premium: null,
      premium_info_provincial_sales_tax: null,
      premium_info_additional_charges: null,
      premium_info_total_estimated_cost: null,
      premium_info_amount_paid_with_application: null,
      premium_info_amount_still_due: null,
      premium_info_no_of_remaining_instalments: null,
      premium_info_amount_of_each_instalment: null,
      premium_info_instalment_due_date: moment().format("YYYY-MM-DD"),
      premium_info_Remarks: null,
      status: "ACTIVE",
    },
    loading1: LoadState.Loaded,
    error1: null,
  },
};
