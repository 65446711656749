import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { getUserAuth } from "../../helpers";
import { action } from "typesafe-actions";
import {
  ADD_UNDER_WRITER,
  ADD_UNDER_WRITER_PROGESS,
  CLEAR_UNDER_WRITER_STATE,
  FETCH_UNDER_WRITER_PROGRESS,
  FETCH_UNDER_WRITER_SUCCESS,
} from "../constants";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { IInsurerUnderWriter, IUnderWriter } from "./underWriter.types";

export const UNDER_WRITER_ERROR = "UNDER_WRITER_ERROR";
export const ADD_UNDER_WRITER_ERROR = "ADD_UNDER_WRITER_ERROR";

export const addUnderWriterProgress = () => action(ADD_UNDER_WRITER_PROGESS);

export const addUnderWriter = (data: IInsurerUnderWriter) =>
  action(ADD_UNDER_WRITER, { data: data });

export const addUnderWriterAsync =
  (
    data: IInsurerUnderWriter,
    onSuccess: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const token = getUserAuth();
    try {
      dispatch(saveLoaderProgress());
      if (token) {
        dispatch(addUnderWriterProgress());
        await api.post(
          "/insurance/create-underwriters",
          {
           ...data
          },
          {
            headers: {
              "auth-Token": token,
            },
          }
        );
        dispatch(addUnderWriter(data));
        dispatch(
          showMessage({
            type: "success",
            message: "Underwriter saved successfully!",
            displayAs: "snackbar",
          })
        );
        onSuccess();
      }
    } catch (err: any) {
      dispatch(underWriterError(null));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchUnderWriterProgress = () =>
  action(FETCH_UNDER_WRITER_PROGRESS);

export const fetchUnderWriterSuccess = (data: IUnderWriter) =>
  action(FETCH_UNDER_WRITER_SUCCESS, { data: data });

export const fetchUnderWriterAsync =
  (codeId: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const token = getUserAuth();
    try {
      if (token) {
        dispatch(fetchUnderWriterProgress());
        const res = await api.get(
          `insurance/get-underwriters?underwriters_primary_id=${codeId}`,
          {
            headers: {
              "auth-Token": token,
            },
          }
        );
        const data: IInsurerUnderWriter[] = res.data.data;
        if (data.length > 0) {
          dispatch(addUnderWriter(data[0]));
        } else {
          dispatch(
            underWriterError(
              "Oops! We couldn't find any records associated with your under writer at the moment. Please ensure that the provided information is accurate and try again."
            )
          );
        }
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const underWriterError = (message: string | null) =>
  action(UNDER_WRITER_ERROR, {
    message: message,
  });

export const clearUnderWriterState = () => action(CLEAR_UNDER_WRITER_STATE);
