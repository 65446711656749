import React from "react";
import { IOptionsRightPanelProps } from "./OptionsRightPanel.types";
import { useFormik } from "formik";
import { RightPanel } from "../RightPanel";
import { Button, Grid, SelectChangeEvent } from "@mui/material";
import { ControlledCustomSelect, CustomFormLabel } from "../formsComponents";
import { useSelector } from "react-redux";
import { IStoreState } from "../../redux/initialStoreState";
import { IG4SignEditior } from "../../redux/g4SignDocuments/g4SignDocuments.types";

export const OptionsRightPanel: React.FC<IOptionsRightPanelProps> = (props) => {
  const { open, currentData, onSave,onDelete, onClose } = props;
  const recipientList = useSelector(
    (storeState: IStoreState) => storeState.g4Sign.documents.recipientList
  );

  const { values, handleChange, handleSubmit, setValues } = useFormik({
    initialValues: currentData,
    onSubmit: (values) => {
      onSave({...values, isNewPlaceholder: currentData.isNewPlaceholder});
    },
  });

  const handleRecipientChange = (e: SelectChangeEvent<unknown>) => {
    const value = e.target.value;
    const data = recipientList.find((x) => x.recipient_code === value) || {
      signer_name: "",
    };
    setValues({
      ...values,
      recipient_code: e.target.value as string,
      customer_text: data.signer_name as string,
    });
  };



  return (
 <div onMouseDown={(e)=>{
  e.stopPropagation()
 }}>
     <RightPanel
      open={open}
      heading="Placeholder Editing Panel"
      onClose={onClose}
      isWrappedWithForm={true}
      onFormSubmit={handleSubmit}
      actionButtons={() => {
        return (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Button variant="contained" type="submit" color="secondary" fullWidth>
                Save
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button variant="contained" color="error" fullWidth onClick={props.onDelete}>
                Delete
              </Button>
            </Grid>
         
          </Grid>
        );
      }}
    >
     
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12}>
            <CustomFormLabel>Recipient</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={values.recipient_code}
              options={recipientList.map((item) => {
                return { label: item.signer_name, value: item.recipient_code };
              })}
              onChange={handleRecipientChange}
              displayEmpty
              placeholder="Select recipient"
            />
          </Grid>
        </Grid>
    
    </RightPanel>
 </div>
  );
};
