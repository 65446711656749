import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { IDate } from "../../components/Table/hooks/useDateFilter";
import { uploadFile } from "../../helpers";
import moment from "moment";
import { ISupportTickets } from "./supportTickets.types";

export const FETCH_SUPPORT_TICKETS_LIST_PROGRESS =
  "FETCH_SUPPORT_TICKETS_LIST_PROGRESS";
export const FETCH_SUPPORT_TICKETS_LIST_SUCCESS =
  "FETCH_SUPPORT_TICKETS_LIST_SUCCESS";
export const FETCH_SUPPORT_TICKETS_LIST_FAILED =
  "FETCH_SUPPORT_TICKETS_LIST_FAILED";

export const fetchSupportTicketsListProgress = () =>
  action(FETCH_SUPPORT_TICKETS_LIST_PROGRESS);
export const fetchSupportTicketsListSuccess = (
  data: ISupportTickets[],
  totalRecords: number
) => action(FETCH_SUPPORT_TICKETS_LIST_SUCCESS, { data, totalRecords });
export const fetchSupportTicketsListFailed = () =>
  action(FETCH_SUPPORT_TICKETS_LIST_FAILED);

export const fetchSupportTicketsListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    status: string,
    date: IDate,
    searchValue: string,
    searchType: string[]
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchSupportTicketsListProgress());

      let url = "";
      if (status !== "-1") {
        url = "&status=" + status;
      }

      let finalUrl = `/other/get-support-ticket?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}${url}`;
      if (searchType.length > 0 && searchValue) {
        finalUrl = `/other/get-support-ticket?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&column=${[
          searchType,
        ]}&value=${searchValue}`;
      }

      const res = await api.get(finalUrl);
      const data: ISupportTickets[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchSupportTicketsListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchSupportTicketsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_SUPPORT_TICKETS_PROGRESS = "FETCH_SUPPORT_TICKETS_PROGRESS";
export const FETCH_SUPPORT_TICKETS_SUCCESS = "FETCH_SUPPORT_TICKETS_SUCCESS";
export const FETCH_SUPPORT_TICKETS_FAILED = "FETCH_SUPPORT_TICKETS_FAILED";

export const fetchSupportTicketsProgress = () =>
  action(FETCH_SUPPORT_TICKETS_PROGRESS);
export const fetchSupportTicketsSuccess = (data: ISupportTickets) =>
  action(FETCH_SUPPORT_TICKETS_SUCCESS, { data });
export const fetchSupportTicketsFailed = (errorMessage?: string) =>
  action(FETCH_SUPPORT_TICKETS_FAILED, { errorMessage });

export const fetchSupportTicketsAsync =
  (supportTicketsCode: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchSupportTicketsProgress());
      const res = await api.get(
        `/other/get-support-ticket?support_tickets_code=${supportTicketsCode}`
      );
      const data: ISupportTickets[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchSupportTicketsSuccess(data[0]));
      } else {
        dispatch(
          fetchSupportTicketsFailed(
            "Oops! We couldn't find any records associated with your branch office at the moment. Please ensure that the provided information is accurate and try again."
          )
        );
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertSupportTicketsAsync =
  (
    data: ISupportTickets,
    file: File | null,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const asPayload = {
        support_tickets_id: data.support_tickets_code,
      };
      const path = await uploadFile(
        file,
        "",
        data.file_upload || "",
        asPayload
      );
      await api.post("/other/create-support-ticket", {
        ...data,
        file_upload: path,
      //   // from: moment(data.from).format("YYYY-MM-DD"),
      //   // to:  moment(data.to).format("YYYY-MM-DD"),
      });

      dispatch(
        showMessage({
          type: "success",
          message: "Your Ticket saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_SUPPORT_TICKETS = "CLEAR_SUPPORT_TICKETS";
export const clearSupportTickets = () => action(CLEAR_SUPPORT_TICKETS);



