/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { CustomerBaiscDetailsLayout } from "../../CustomerBasicDetailsLayout/CustomerBasicDetailsLayout";
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { StandadCard } from "../../../../../components/Cards";
import { Box } from "@mui/material";
import { PolicyTabCard } from "../../../../../components/PolicyTabCard/PolicyTabCard";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../hooks";
import { PageLoader } from "../../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../../constants/enums";
import { BreadcrumbProvider } from "../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import {
  clearCommAutomobileBasicPolicyState,
  fetchCommAutomobileCustomerInfoAsync,
} from "../../../../../redux/commAutoPolicy/commAutoBasicDetails/commAutoBasicDetailsActions";
import { batch } from "react-redux";
import { clearCommAutomobileDriverState } from "../../../../../redux/commAutoPolicy/commAutoDriver/commAutoDriverActions";
import { clearCommAutomobileVehicleState } from "../../../../../redux/commAutoPolicy/commAutoVehicle/commAutoVehicleActions";
import { clearCommAutomobileGarageApplicationState } from "../../../../../redux/commAutoPolicy/commAutoGarageApplication/commAutoGarageApplicationActions";
import { clearCommAutomobileGaragePlatesState } from "../../../../../redux/commAutoPolicy/commAutoGaragePlates/commAutoGaragePlatesActions";
import { clearCommAutomobileCSVState } from "../../../../../redux/commAutoPolicy/commAutoCSV/commAutoCSVActions";
import { clearCommAutomobileSchedule } from "../../../../../redux/commAutoPolicy/commAutoSchedule/commAutoScheduleActions";
import { clearAutomobileAuthorizationState } from "../../../../../redux/automobilePolicyAuthorization/automobilePolicyAuthorizationActions";
import { clearAutomobileApplicationState } from "../../../../../redux/automobileApplication/automobileApplicationActions";
import { clearAutonmobilePolicyMarketingState } from "../../../../../redux/automobileMarketingHistory/automobileMarketingHistoryActions";
import { clearCommAutoCertificatesState } from "../../../../../redux/commAutoPolicy/commAutoCertificates/commAutoCertificatesActions";
import { clearNotesState } from "../../../../../redux/notes/notesActions";

export const CommAutomobilePolicyLayout: React.FC = () => {
  const { customerId, customerPolicyId } = useParams() as {
    customerId?: number;
    customerPolicyId?: number;
  };
  const {
    customerPoliycId: savedCustomerPolicyId,
    customerInfo: { loading, error },
  } = useSelector(
    (storeState: IStoreState) => storeState.commAuto.basicDetails
  );
  const navigate = useNavigate();
  const dispatch = useDispatchWrapper();
  const location = useLocation();
  const currentPathname = location.pathname;
  const isMainTab = currentPathname.includes("basic-details");

  const handleStep = (step: string) => () => {
    const isAlreadyInSamePage = currentPathname.includes(step);
    if (!isAlreadyInSamePage) {
      navigate(
        `/comm-automobile-policy/${customerId}/${step}/${savedCustomerPolicyId}?tab=0`
      );
    }
  };

  const renderComponent = () => {
    if (isMainTab) {
      return <Outlet />;
    } else if (!isMainTab && !customerPolicyId) {
      return <Navigate to={`/customer-overview/${customerId}`} />;
    } else if (!isMainTab && savedCustomerPolicyId) {
      return <Outlet context={[customerId, savedCustomerPolicyId]} />;
    } else {
      console.log("__FALIED____");
      return null;
    }
  };

  React.useEffect(() => {
    if (customerPolicyId) {
      dispatch(fetchCommAutomobileCustomerInfoAsync(customerPolicyId));
    }
  }, [customerPolicyId]);

  React.useEffect(() => {
    return () => {
      batch(() => {
        dispatch(clearCommAutomobileBasicPolicyState());
        dispatch(clearCommAutomobileDriverState());
        dispatch(clearCommAutomobileVehicleState());
        dispatch(clearCommAutomobileGarageApplicationState());
        dispatch(clearCommAutomobileGaragePlatesState());
        dispatch(clearCommAutomobileCSVState());
        dispatch(clearCommAutomobileSchedule());
        dispatch(clearAutomobileAuthorizationState());
        dispatch(clearAutomobileApplicationState());
        dispatch(clearAutonmobilePolicyMarketingState());
        dispatch(clearCommAutoCertificatesState());
        dispatch(clearNotesState());
      });
    };
  }, []);

  return (
    <>
      <BreadcrumbProvider>
        <CustomerBaiscDetailsLayout customerId={customerId} fromPolicy policyId={savedCustomerPolicyId as number}>
          <StandadCard sx={{ my: 2 }}>
            <Box display={"flex"} flexWrap={"wrap"}>
              <PolicyTabCard
                label="Basic Details"
                onClick={handleStep("basic-details")}
                isSelected={currentPathname.includes("basic-details")}
              />
              <PolicyTabCard
                label="Driver"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("driver-tab")}
                isSelected={currentPathname.includes("driver-tab")}
              />
              <PolicyTabCard
                label="Vehicle"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("vehicle-tab")}
                isSelected={currentPathname.includes("vehicle-tab")}
              />
              <PolicyTabCard
                label="Garage Plates"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("garage-plate-tab")}
                isSelected={currentPathname.includes("garage-plate-tab")}
              />
              <PolicyTabCard
                label="Commercial Vehicle Supplement"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("comm-vehicle-supplement-tab")}
                isSelected={currentPathname.includes(
                  "comm-vehicle-supplement-tab"
                )}
              />
              <PolicyTabCard
                label="Coverage"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("vehicle-coverage-schedule-tab")}
                isSelected={currentPathname.includes(
                  "vehicle-coverage-schedule-tab"
                )}
              />
              <PolicyTabCard
                label="Application"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("application-tab")}
                isSelected={currentPathname.includes("application-tab")}
              />
              <PolicyTabCard
                label="Marketing"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("marketing-tab")}
                isSelected={currentPathname.includes("marketing-tab")}
              />

              <PolicyTabCard
                label="Policy Forms"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("policy-forms-tab")}
                isSelected={currentPathname.includes("policy-forms-tab")}
              />

              <PolicyTabCard
                label="History"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("history-tab")}
                isSelected={currentPathname.includes("history-tab")}
              />
              <PolicyTabCard
                label="Attachments"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("attachments-tab")}
                isSelected={currentPathname.includes("attachments-tab")}
              />
              <PolicyTabCard
                label="Notes"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("notes-tab")}
                isSelected={currentPathname.includes("notes-tab")}
              />
              <PolicyTabCard
                label="Business Tracker"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("task-activity-tab")}
                isSelected={currentPathname.includes("task-activity-tab")}
              />
            </Box>
          </StandadCard>
          <PageLoader
            loading={loading === LoadState.InProgress}
            error={error ? { message: error } : null}
          >
            {renderComponent()}
          </PageLoader>
        </CustomerBaiscDetailsLayout>
      </BreadcrumbProvider>
    </>
  );
};
