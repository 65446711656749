import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../../messages/messagesActions";
import { IChartsOfAccounts } from "./ChartsOfAccounts.types";
import { ERROR_MESSAGES } from "../../../constants/enums";

export const FETCH_CHARTS_OF_ACCOUNTS_PROGRESS =
  "FETCH_CHARTS_OF_ACCOUNTS_PROGRESS";
export const FETCH_CHARTS_OF_ACCOUNTS_SUCCESS =
  "FETCH_CHARTS_OF_ACCOUNTS_SUCCESS";
export const FETCH_CHARTS_OF_ACCOUNTS_FAILURE =
  "FETCH_CHARTS_OF_ACCOUNTS_FAILURE";

export const fetchChartsOfAccountsProgress = () =>
  action(FETCH_CHARTS_OF_ACCOUNTS_PROGRESS);
export const fetchChartsOfAccountsSuccess = (data: IChartsOfAccounts) =>
  action(FETCH_CHARTS_OF_ACCOUNTS_SUCCESS, { data });
export const fetchChartsOfAccountsFailed = (errorMessage: string) =>
  action(FETCH_CHARTS_OF_ACCOUNTS_FAILURE, { errorMessage });

export const fetchChartsOfAccountsAsync =
  (incrementalNumber: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchChartsOfAccountsProgress());

      const res = await api.get(
        `/accounting/get-account-company-charts-of-accounts?column=incremental_number&value=${incrementalNumber}`
      );
      const data = res.data.data;
      if (data.length > 0) {
        dispatch(fetchChartsOfAccountsSuccess(data[0]));
      } else {
        dispatch(
          fetchChartsOfAccountsFailed(
            "Unfortunately, there are no records available at the moment."
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchChartsOfAccountsFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addChartsOfAccountsAysnc =
  (
    data: IChartsOfAccounts,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      await api.post(
        "/accounting/edit-account-company-charts-of-accounts",
        data
      );
      let message = "Charts of Account saved successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const FETCH_CHARTS_OF_ACCOUNT_LIST_PROGRESS =
  "FETCH_CHARTS_OF_ACCOUNT_LIST_PROGRESS";
export const FETCH_CHARTS_OF_ACCOUNT_LIST_SUCCESS =
  "FETCH_CHARTS_OF_ACCOUNT_LIST_SUCCESS";
export const FETCH_CHARTS_OF_ACCOUNT_LIST_FAILURE =
  "FETCH_CHARTS_OF_ACCOUNT_LIST_FAILURE";

export const fetchChartsOfAccountListProgress = () =>
  action(FETCH_CHARTS_OF_ACCOUNT_LIST_PROGRESS);
export const fetchChartsOfAccountListSuccess = (data: IChartsOfAccounts[]) =>
  action(FETCH_CHARTS_OF_ACCOUNT_LIST_SUCCESS, { data });
export const fetchChartsOfAccountListFailed = (errorMessage: string) =>
  action(FETCH_CHARTS_OF_ACCOUNT_LIST_FAILURE, { errorMessage });

export const fetchChartsOfAccountListAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchChartsOfAccountListProgress());
      const res = await api.get(
        `/accounting/get-account-company-charts-of-accounts`
      );
      const data: IChartsOfAccounts[] = res.data.data;
      dispatch(fetchChartsOfAccountListSuccess(data));
    } catch (err: any) {
      dispatch(fetchChartsOfAccountListFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

  export const CLEAR_CHARTS_OF_ACCOUNTS = "CLEAR_CHARTS_OF_ACCOUNTS";
  export const clearChartsOfAccounts = () => action(CLEAR_CHARTS_OF_ACCOUNTS);
