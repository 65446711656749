import { action } from "typesafe-actions";
import {
  ITask,
  ITaskFileUpload,
  ITaskFileUploadAttachment,
  ITaskFileUploadFetch,
} from "./task.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { IDate } from "../../components/Table/hooks/useDateFilter";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { uploadTaskFile } from "../../helpers";
import { ERROR_MESSAGES } from "../../constants/enums";
import { IOldBusinessTrackerState } from "../../views/tasks/BeforeCreate/OldBusinessTrackerDialog/OldBusinessTrackerDialog.types";

export const FETCH_TASKS_LIST_PROGRESS = "FETCH_TASKS_LIST_PROGRESS";
export const FETCH_TASKS_LIST_SUCCESS = "FETCH_TASKS_LIST_SUCCESS";
export const FETCH_TASKS_LIST_FAILED = "FETCH_TASKS_LIST_FAILED";
export const fetchTasksListProgress = () => action(FETCH_TASKS_LIST_PROGRESS);
export const fetchTasksListSuccess = (
  tasksList: ITask[],
  totalRecords: number
) => action(FETCH_TASKS_LIST_SUCCESS, { tasksList, totalRecords });
export const fetchTasksListFailed = () => action(FETCH_TASKS_LIST_FAILED);

export const fetchTasksListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    status: string,
    date: IDate,
    searchValue: string,
    searchType: string[],
    customerPolicyId?: number,
    subType?: string,
    groupType?: string,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchTasksListProgress());

      let url = "";
      if (status !== "-1") {
        url = "&status=" + status;
      }
      let finalUrl = `/tasks/get-task?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}${url}`;
      if (searchType.length > 0 && searchValue) {
        finalUrl = `/tasks/get-task?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${
          date.fromDate
        }&to_date=${date.toDate}${url}&column=${[
          searchType,
        ]}&value=${searchValue}`;
      }
      if (customerPolicyId) {
        finalUrl += "&customer_policy_id=" + customerPolicyId;
      }
      if (subType) {
        finalUrl += "&task_sub_type=" + subType;
      }
      if (groupType !== "-1") {
        finalUrl += "&group_of=" + groupType;
      }
      const res = await api.get(finalUrl);
      const data: ITask[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchTasksListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchTasksListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_TASK_PROGRESS = "FETCH_TASK_PROGRESS";
export const FETCH_TASK_SUCCESS = "FETCH_TASK_SUCCESS";
export const FETCH_TASK_FAILED = "FETCH_TASK_FAILED";

export const fetchTaskProgress = () => action(FETCH_TASK_PROGRESS);
export const fetchTaskSuccess = (data: ITask) =>
  action(FETCH_TASK_SUCCESS, { data });
export const fetchTaskFailed = (errorMessage: string) =>
  action(FETCH_TASK_FAILED, { errorMessage });

export const fetchTaskAysnc =
  (taskCode: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchTaskProgress());
      const res = await api.get(`/tasks/get-task?task_code=${taskCode}`);
      const data: ITask[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchTaskSuccess(data[0]));
      } else {
        dispatch(
          fetchTaskFailed(
            "Oops! We couldn't find any records associated with your task number at the moment. Please ensure that the provided information is accurate and try again."
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchTaskFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertTaskAysnc =
  (
    task: ITask,
    onCallback: (isSuccess: boolean, task?: ITask) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.post("/tasks/upsert-task", {
        ...task,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Task is saved succesfully!",
          displayAs: "snackbar",
        })
      );
      dispatch(
        fetchTaskSuccess({ ...task, task_code: res.data.data.task_code })
      );
      onCallback(true, res.data.data);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchTaskMultiFileUploadAysnc =
  (
    taskCode: string,
    onCallback: (isSuccess: boolean, data?: ITaskFileUploadFetch[]) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/tasks/get-tasks-multi-attachment?task_code=${taskCode}`
      );
      const data: ITaskFileUploadFetch[] = res.data.data;

      onCallback(true, data);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertTaskMutliFileUploadAysnc =
  (
    data: ITaskFileUpload,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch) => {
    try {
      dispatch(saveLoaderProgress());
      const asPayload = {
        transaction: "POLICY_CHANGE",
        sub_type: "GENERAL_CHANGE",
        task_code: data.task_code,
      };

      const updateFilesJson = await uploadTaskFile(
        data.attachments,
        "TASK",
        asPayload
      );
      await api.post(`/tasks/upsert-tasks-multi-attachment`, {
        ...data,
        attachments: updateFilesJson,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "General change is saved succesfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const previewPoliciesCancellationListPDFAsync =
  (
    taskCode: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/tasks/get-tasks-cancellation-and-reinstatement-pdf?task_code=${taskCode}&policy_type=cancellation&is_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchTasksListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const downloadPoliciesCancellationListPDFAsync =
  (
    taskCode: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/tasks/get-tasks-cancellation-and-reinstatement-pdf?task_code=${taskCode}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchTasksListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertOldBusinessTrackerAysnc =
  (
    data: IOldBusinessTrackerState,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.put("/other/update-old-business-tracker", { ...data });
      dispatch(
        showMessage({
          type: "success",
          message: "Old Business Tracker saved succesfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_TASK = "CLEAR_TASK";
export const clearTaskCancel = () => action(CLEAR_TASK);
