/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Step, StepLabel, Stepper, useTheme } from "@mui/material";
import { StandadCard } from "../../../../components/Cards";
import { useParams } from "react-router-dom";
import { TabPanel } from "../../../../components/Tabs/TabPanel";
import { SendReceipt } from "./SendReceipt";
import { ManageDocument } from "./ManageDocument";
import { useDispatchWrapper } from "../../../../hooks";
import { clearG4DocumentsState } from "../../../../redux/g4SignDocuments/g4SignDocumentsAction";
import { IBreadcrumbProps } from "../../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { Breadcrumb } from "../../../../layout/fullLayout/breadcrumb/Breadcrumb";
import { DocumentPreview } from "./DocumentPreview";

export const UpsertDocument: React.FC = (props) => {
  const { documentCode } = useParams() as { documentCode?: string };

  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard",
      title: "dashboard",
    },
    {
      to: "/g4sign/documents",
      title: "Documents List",
    },
    {
      title: documentCode ? "Create Document" : "Edit Document",
    },
  ];

  const [activeStep, setActiveStep] = React.useState(0);
  const [code, setCode] = React.useState<string | null>(null);
  const dispatch = useDispatchWrapper();

  const changeStep = (value: number) => {
    setActiveStep(value);
  };

  const handleDocumentCreated = (code: string) => {
    changeStep(1);
    setCode(code);
  };

  React.useEffect(() => {
    return () => {
      dispatch(clearG4DocumentsState());
    };
  }, []);

  return (
    <>
      <Breadcrumb title="" items={BCrumb} />
      {activeStep !== 2 && (
        <StandadCard heading="Get your document signed">
          <Stepper activeStep={activeStep} sx={{ mt: 4 }}>
            {["Document Selection", "Send Receipt", "Document Preview"].map(
              (label, index) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: {
                  optional?: React.ReactNode;
                } = {};

                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              }
            )}
          </Stepper>
          <TabPanel value={activeStep} index={0}>
            <ManageDocument
              documentCode={documentCode}
              onDocumentCreated={handleDocumentCreated}
            />
          </TabPanel>

          {code && (
            <>
              <TabPanel value={activeStep} index={1}>
                <SendReceipt documentCode={code} changeStep={changeStep} />
              </TabPanel>
            </>
          )}
        </StandadCard>
      )}
      {code && (
        <TabPanel value={activeStep} index={2}>
          <DocumentPreview documentCode={code} />
        </TabPanel>
      )}
    </>
  );
};
