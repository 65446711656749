import React from "react";
import { Dialog } from "../../../../../../../components/Dialogs/Dialog";
import { Grid } from "@mui/material";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { useFormik } from "formik";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomTextField,
} from "../../../../../../../components/formsComponents";
import { CustomDatePicker } from "../../../../../../../components/formsComponents/CustomDatePicker";
import moment from "moment";
import { USER_ROLES } from "../../../../../../../constants/enums";
import { addClaimAsync } from "../../../../../../../redux/businessPolicy/businessPolicyBasicDetails/businessPolicyBasicDetailsActions";
import { BranchCodeAutoSearch } from "../../../../../../../components/AutoCompleteSearches/BranchCodeAutoSearch";
import { UsersAutoSearchByRole } from "../../../../../../../components/AutoCompleteSearches/UsersAutoSearchByRole";
import { ILifeInsuranceAddClaimDialogProps } from "./AddClaimDialog.types";
import { addLifeInsuranceClaimAsync } from "../../../../../../../redux/lifeInsurancePolicy/lifeInsuranceBasicDetail/lifeInsuranceBasicDetailActions";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import { useSelector } from "react-redux";
import { CurrencyTextField } from "../../../../../../../components/CurrencyTextField/CurrencyTextField";

export const LifeInsuranceAddClaimDialog: React.FC<
  ILifeInsuranceAddClaimDialogProps
> = (props) => {
  const { open, claim, onClose } = props;

  const [saveLoading, setSaveLoading] = React.useState(false);

  const customerProspect = useSelector(
    (storeState: IStoreState) => storeState.customer.customerProspect.data
  );

  const dispatch = useDispatchWrapper();
  const { values, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: claim,
    validate: (values) => {
      const errors: any = {};
      return errors;
    },
    onSubmit: async (values) => {
      setSaveLoading(true);
      dispatch(
        addLifeInsuranceClaimAsync(
          {
            ...values,
          },
          (isSuccess) => {
            if (isSuccess) {
              onClose();
            }
            setSaveLoading(false);
          }
        )
      );
    },
  });

  React.useEffect(() => {
    setFieldValue("branch", customerProspect.branch_code);
    setFieldValue("producer", customerProspect.producer);
  }, [customerProspect]);

  return (
    <Dialog
      open={open}
      title="Create/Edit Claims"
      onClose={onClose}
      size="md"
      contentWrappedWithForm={{ onSubmit: handleSubmit }}
      actions={[
        { type: "button", label: "Close", variant: "text", onClick: onClose },
        {
          type: "submit",
          label: "Save",
          variant: "contained",
          disabled: saveLoading,
        },
      ]}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} lg={4}>
          <CustomFormLabel>Claim #</CustomFormLabel>
          <CustomTextField
            name="claim_number"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            disabled
            value={values.claim_number}
            // error={errors.name ? true : false}
            // helperText={errors.name}
            // disabled={isUpdateLead}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} lg={4}>
          <CustomFormLabel>Date of loss</CustomFormLabel>
          <CustomDatePicker
            fullWidth
            value={values.date_of_loss || ""}
            onChange={(newValue) => {
              setFieldValue("date_of_loss", newValue);
            }}
          />
        </Grid>

        <Grid item xs={12} lg={4}>
          <CustomFormLabel>Type</CustomFormLabel>
          <ControlledCustomSelect
            fullWidth
            value={values.type}
            name="type"
            onChange={handleChange}
            placeholder="Select one"
            displayEmpty
            options={[
              "Death",
              "Critical Illness",
              "Disability",
              "Travel Insurance",
              "Health Insurance",
              "Super Visa",
              "Life",
            ].map((template) => {
              return { label: template, value: template };
            })}
          ></ControlledCustomSelect>
        </Grid>

        <Grid item xs={12} lg={4}>
          <UsersAutoSearchByRole
            label="Producer"
            value={values.producer}
            onSelect={(value) => {
              setFieldValue("producer", value.user_fact_id);
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CustomFormLabel>Branch</CustomFormLabel>
          <BranchCodeAutoSearch
            value={values.branch}
            onSelect={(value) => {
              setFieldValue("branch", value.value);
            }}
          />
        </Grid>

        <Grid item xs={12} lg={4}>
          <CustomFormLabel>Amount Paid ($)</CustomFormLabel>
          <CurrencyTextField
            fullWidth
            value={values.amount_paid}
            onChange={(value) => {
              setFieldValue("amount_paid", value);
            }}
          />
        </Grid>

        <Grid item xs={12} lg={4}>
          <CustomFormLabel>Status</CustomFormLabel>
          <ControlledCustomSelect
            fullWidth
            value={values.status}
            name="status"
            onChange={handleChange}
            placeholder="Select one"
            displayEmpty
            options={["Open", "Closed", "Re-opened"].map((template) => {
              return { label: template, value: template };
            })}
          ></ControlledCustomSelect>
        </Grid>

        <Grid item xs={12} lg={12}>
          <CustomFormLabel>Loss Details</CustomFormLabel>
          <CustomTextField
            name="loss_details"
            variant="outlined"
            multiline
            rows={5}
            size="small"
            type="text"
            fullWidth
            value={values.loss_details}
            // error={errors.name ? true : false}
            // helperText={errors.name}
            // disabled={isUpdateLead}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};
