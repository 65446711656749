import { LoadState } from "../../../constants/enums";
import { getUniqueId } from "../../../helpers";

export interface IAccountingInvoiceInformationState {
  list: IInvoiceInformation[];
  listLoading: LoadState;
  data: IInvoiceInformation;
  loading: LoadState;
  error: string | null;
}

export interface IInvoiceInformation {
  payment_id: string | null;
  invoice_date: string | null;
  invoice_number: string | null;
  invoice_due_date: string | null;
  payment_terms: string | null;
  accounting_information_id: string | null;
  company_id: string | null;
  tax_id: string | null;
  invoicingTable: {
    key: string;
    transaction_type: string | null;
    transaction_detail: string | null;
    transaction_description: string | null;
    transaction_amount: string | null;
  }[];
  transactions: string | null;
  sub_total: string | null;
  tax_total: string | null;
  total: string | null;
  invoice_notes: string | null;
  invoice_footer_information: string | null;
  status: string | null;
}

export const initialAccountingInvoiceInformationState: IAccountingInvoiceInformationState =
  {
    list: [],
    listLoading: LoadState.NotLoaded,
    data: {
      payment_id: null,
      invoice_date: null,
      invoice_number: null,
      invoice_due_date: null,
      payment_terms: null,
      accounting_information_id: null,
      company_id: null,
      tax_id: null,
      invoicingTable: [
        {
          key: getUniqueId(),
          transaction_amount: null,
          transaction_description: null,
          transaction_detail: null,
          transaction_type: null
        }
      ],
      transactions: null,
      sub_total: null,
      tax_total: null,
      total: null,
      invoice_notes: null,
      invoice_footer_information: null,
      status: "Active",
    },
    loading: LoadState.NotLoaded,
    error: null,
  };
