import { LoadState } from "../../constants/enums";

export interface ISupportTicketsState {
  data: ISupportTickets;
  loading: LoadState;
  error: string | null;
  list: ISupportTickets[];
  totalRecords: number;
  listLoading: LoadState;
}

export interface ISupportTickets {
  support_tickets_id: number;
  support_tickets_code: string;
  issue_type: string | null;
  customer_code: string | null;
  customer_name: string | null;
  policy_number: string | null;
  any_desk_id : string | null;
  details: string | null;
  file_upload: string | null;
  date_created: null | string,
  date_resolved: string | null,
  created_by_id: string | null,
  modified_by_id: string | null,
  status: string;
}

export const initialSupportTicketsState: ISupportTicketsState = {
  data: {
    support_tickets_id: 0,
    support_tickets_code: "",
    issue_type: null,
    customer_code: null,
    customer_name: null,
    policy_number: null,
    any_desk_id: null,
    details: null,
    file_upload: null,
    date_created: null,
    date_resolved: null,
    created_by_id: null,
    modified_by_id: null,
    status: "ACTIVE",
  },
  loading: LoadState.NotLoaded,
  list: [],
  listLoading: LoadState.NotLoaded,
  totalRecords: 0,
  error: null,
};
