import { Divider, Grid, Stack, Typography } from "@mui/material";
import { StandardCellList } from "../../../../components/Standards";
import React from "react";
import { fetchTaskGeneralChangeAysnc } from "../../../../redux/generalChange/generalChangeActions";
import { useDispatchWrapper } from "../../../../hooks";
import { IGeneralChange } from "../../../../redux/generalChange/generalChange.types";
import { IStoreState } from "../../../../redux/initialStoreState";
import { useSelector } from "react-redux";
import { formatTime } from "../../../../helpers";
import { PageLoader } from "../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../constants/enums";
import { ITaskFileUploadAttachment } from "../../../../redux/tasks/task.types";
import { FileUpload } from "../../../../components/FileUpload/FileUpload";
import { StandadCard } from "../../../../components/Cards";

export const ViewGeneralChange: React.FC<{
  taskCode: string;
  attachment: ITaskFileUploadAttachment[];
}> = (props) => {
  const { taskCode, attachment } = props;
  const { data, loading } = useSelector(
    (storeState: IStoreState) => storeState.tasks.generalChange.generalChange
  );
  const dispatch = useDispatchWrapper();
  const [taskDetails, setTaskDetails] = React.useState<IGeneralChange>();

  React.useEffect(() => {
    dispatch(fetchTaskGeneralChangeAysnc(taskCode));
  }, [taskCode]);

  React.useEffect(() => {
    if (data) {
      setTaskDetails(data);
    }
  }, [data]);

  return (
    <PageLoader loading={loading === LoadState.InProgress}>
      {taskDetails && (
        <Grid container spacing={1} sx={{ mt: 2 }}>
          <Grid item xs={12} md={12}>
            <StandardCellList
              heading="General Change"
              colSize={3}
              list={[
                {
                  heading: "Effective Date",
                  colSize: 2,
                  data: {
                    type: "text",
                    value: taskDetails.effective_date_of_change,
                  },
                },
                {
                  heading: "Effective Time",
                  colSize: 2,
                  data: {
                    type: "text",
                    value: formatTime(
                      taskDetails.effective_time_of_change || ""
                    ),
                  },
                },
                {
                  heading: "Details",
                  colSize: 8,
                  data: {
                    type: "text",
                    value: taskDetails.details,
                  },
                },
              ]}
            >
              <Divider sx={{ mt: 2, mb: 2 }} />
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="body1"
                    fontSize={"1.2rem"}
                    fontWeight={600}
                  >
                    Attachments
                  </Typography>
                  {attachment.length > 0 ? (
                    attachment.map((item) => {
                      return (
                        <Stack
                          key={item.key}
                          direction={"row"}
                          justifyContent={"center"}
                          gap={2}
                          alignItems={"center"}
                        >
                          <Typography
                            variant="h4"
                            textTransform={"capitalize"}
                            width={"30%"}
                          >
                            {" "}
                            {item.fileName}{" "}
                          </Typography>
                          <FileUpload value={item.url} deleteDisabled />
                        </Stack>
                      );
                    })
                  ) : (
                    <Typography variant="body1">No attachments</Typography>
                  )}
                </Grid>
              </Grid>
            </StandardCellList>
          </Grid>
        </Grid>
      )}
    </PageLoader>
  );
};
