import { LoadState } from "../../constants/enums";
import { IInterestedParty } from "../InterestedParties/IntrestedParties.types";
import { ICommAutomobileVehicleInformation } from "../commAutoPolicy/commAutoVehicle/commAutoVehicle.types";

export interface ICommonState {
  policies: {
    list: IPolicies[];
    loadState: LoadState;
  };
  generalHistory: {
    list: IGeneralHistory[];
    loading: LoadState;
  };
  recordCounts: {
    list: IRecordCount[];
    loading: LoadState;
  };
  vehiclesByPolicyNumber: {
    list: IVehcileByPolicyNumber[];
    loadState: LoadState;
    policyId: number | null;
  };
  driversByPolicy: {
    list: IDriverByPolicyNumber[];
    loadState: LoadState;
    policyId: number | null;
  };
  riskLocationByPolicy: {
    list: IRiskLocationByPolicyNumber[];
    loadState: LoadState;
    policyId: number | null;
  };
}

export interface IVehcileByPolicyNumber {
  customer_name: string;
  policy_number: string;
  incremental_number: number;
  unit_number: string;
  year: string;
  make: string;
  model: string;
  vin: string;
  puchase_condition: string | null;
  purchase_price: string | null;
  plate_number: string | null;
  purchase_date: string | null;
  annual_kms: string | null;
  list_price_new: string | null;
}

export interface IDriverByPolicyNumber {
  customer_policy_id: number;
  driver_number: number;
  driver_name: string;
  date_of_birth: string;
  age: string;
  gender: string;
  marital_status: string;
  driver_phone_number: string;
  license_number: string;
  expiry_date_of_license: string;
  province: string;
  license_status: string;
  mvr_status: string;
  last_mvr_date: string;
}

export interface IRiskLocationByPolicyNumber {
  policy_business_risk_location_id: number;
  customer_policy_id: number;
  location_number: number;
  customer_id: number;
  address: string;
  unit_or_suite: string;
  city: string;
  province_or_state: string;
  postal_code: string;
  country: string;
  year_built: string;
  number_of_stories: string;
  total_building_area: string;
  area_occupied: string;
  risk_inspected: string;
  date_inspected: string;
  walls: string;
  floors: string;
  roof: string;
  roof_coverings: string;
  electrical: string;
  heating: string;
  plumbing: string;
  basement: string;
}

export interface IRecordCount {
  name: string;
  cnt: number;
}

export interface IPolicies {
  type: string;
  subTypes: {
    policy_id: number;
    name: string;
  }[];
}

export interface IGeneralHistory {
  history_id: number;
  history_code: string;
  module_name: string;
  module_code: string;
  record_id: number;
  message: string;
  module_column_name: string;
  created_by_id: number;
  modified_by_id: number;
  create_ts: string;
  insert_ts: string;
  created_by_name: string;
}

export const defaultCommonState: ICommonState = {
  policies: {
    list: [],
    loadState: LoadState.NotLoaded,
  },
  generalHistory: {
    list: [],
    loading: LoadState.NotLoaded,
  },
  recordCounts: {
    list: [],
    loading: LoadState.NotLoaded,
  },
  vehiclesByPolicyNumber: {
    list: [],
    loadState: LoadState.NotLoaded,
    policyId: null,
  },
  driversByPolicy: {
    list: [],
    loadState: LoadState.NotLoaded,
    policyId: null,
  },
  riskLocationByPolicy: {
    list: [],
    loadState: LoadState.NotLoaded,
    policyId: null,
  },
};

export interface IMail {
  emails: string[];
  subject: string;
  body: string;
  templateName: string | null;
  objectVariables: any;
  cc: string[];
  bcc: string[];
  reply_to?: {
    email: string;
    name: string;
  };
  attachments?: {
    content: string;
    filename: string;
  }[];
}
