import { useSelector } from "react-redux";
import { IStoreState } from "../redux/initialStoreState";

export const useCustomerPolicyId = () =>
  useSelector(
    (storeState: IStoreState) =>
      storeState.business.basicDetails.customerPoliycId || 0
  );


