/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Grid,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { IManageDocumentProps } from "./ManageDocument.types";
import { ICustomerFullDetails } from "../../../../redux/customer/customer.types";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../redux/initialStoreState";
import { useDispatchWrapper, usePoliciesByCustomer } from "../../../../hooks";
import { useFormik } from "formik";
import { CustomerAutoSearch } from "../../../../components/AutoCompleteSearches/CustomerAutoSearch";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomTextField,
} from "../../../../components/formsComponents";
import {
  IDataTableProps,
  RenderType,
} from "../../../../components/Table/interfaces/IDataTableProps";
import { StatusRenderer } from "../../../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import { FileUploadV2 } from "../../../../components/FileUpload/FileUploadV2";
import { DataTable } from "../../../../components/Table/DataTable";
import { fetchG4SignDocAsync, upsertG4SignDoc } from "../../../../redux/g4SignDocuments/g4SignDocumentsAction";
import { LoadState } from "../../../../constants/enums";
import { PageLoader } from "../../../../components/PageLoader/PageLoader";
import { useLocation, useNavigate } from "react-router-dom";

export const ManageDocument: React.FC<IManageDocumentProps> = (props) => {
  const { documentCode, onDocumentCreated } = props;

  const { document, documentLoading, errorMessage } = useSelector(
    (storeState: IStoreState) => storeState.g4Sign.documents
  );
  const dispatch = useDispatchWrapper();
  const { policiesList, policiesLoading, fetchPoliciesByCustomerId } =
    usePoliciesByCustomer();

  const [file, setFile] = React.useState<File | null>(null);
  const [saveLoading, setSaveLoading] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: document,
    validate: (values) => {
      const errors: any = {};
      return errors;
    },
    onSubmit: (values) => {
       setSaveLoading(true);
       dispatch(upsertG4SignDoc(values, file, (isSuccess, code)=>{
        if(isSuccess && code){
            onDocumentCreated(code);
            if(!documentCode){
              const currentPath = location.pathname;
              const newURL = `${currentPath}/${code}`;
        
              // Replace the current URL with the new URL
              navigate(newURL, { replace: true });
            }
            
        }
        setSaveLoading(false);
       }))
    },
  });

  const handleCustomer = (data: ICustomerFullDetails) => {
    if (data.customer_id) {
      setValues({
        ...values,
        customer_id: data.customer_id as number,
        customer_name: data.customer_name,
        customer_policy_id: null,
        policy_number: null,
      });
    }
  };

  const handlePolicyChange = (e: SelectChangeEvent<unknown>) => {
    const policyId = e.target.value as string;
    const policy = policiesList.find(x=>x.customer_policy_id === Number(policyId));
    if(policy){
    setFieldValue("customer_policy_id", policyId);
    setValues({
      ...values,
      customer_policy_id: policyId,
      policy_number: policy.policy_number
    })
    }
  };

  const attachmentTableProps: IDataTableProps = {
    isPagination: false,
    // isDataLoading: loading === LoadState.InProgress,

    columns: [
      {
        key: "location_number",
        headerName: "#",
        fieldName: "location_number",
        renderType: RenderType.TEXT,
        exportCellWidth: 15,
      },

      {
        key: "customer_policy_id",
        headerName: "Customer Policy ID",
        fieldName: "customer_policy_id",
        renderType: RenderType.CHIP_WARNING,
        exportCellWidth: 20,
      },

      {
        key: "document_name",
        headerName: "Document Name",
        fieldName: "document_name",
        renderType: RenderType.TEXT,
        exportCellWidth: 20,
      },

      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: any) => {
          return <StatusRenderer status={row.status} />;
        },
      },

      // {
      //   key: "view",
      //   headerName: "Actions",
      //   fieldName: "",
      //   renderType: RenderType.CUSTOM_RENDER,
      //   onRowCellRender: (value, row: IAutomobileAttachment) => {
      //     return (
      //       <StandardTableActions
      //       onEditClick={() => setOpenAttachment(row)}
      //         //  more={{ menuItems: menuItems }}
      //       />
      //     );
      //   },
      // },
    ],
    items: [],
  };

  const policyInfo = React.useMemo(() => {
    if (values.customer_policy_id) {
      const data = policiesList.find(
        (x) => x.customer_policy_id === Number(values.customer_policy_id as any)
      );
      if (data) {
        return data;
      }
    }
    return null;
  }, [values.customer_policy_id]);

  React.useEffect(()=>{
    if(values.customer_id){
      fetchPoliciesByCustomerId(values.customer_id as number);
    }
  },[values.customer_id])

  React.useEffect(() => {
    setValues(document);
  }, [document]);

  React.useEffect(() => {
    if (documentCode) {
      dispatch(fetchG4SignDocAsync(documentCode));
    }
  }, [documentCode]);

  return (
    <PageLoader
      loading={documentLoading === LoadState.InProgress}
      error={errorMessage ? { message: errorMessage } : null}
    >
      <form onSubmit={handleSubmit}>
        <Stack marginTop={4}>
          <>
            <Grid container spacing={2} mb={5}>
            <Grid item xs={12} md={4}>
               <CustomFormLabel>Title</CustomFormLabel>
               <CustomTextField
                fullWidth
                id="title"
                value={values.title}
                onChange={handleChange}
               />
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomerAutoSearch
                  label="Customer"
                  value={values.customer_id}
                  onSelect={handleCustomer}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomFormLabel>Policy</CustomFormLabel>
                <ControlledCustomSelect
                  variant="outlined"
                  size="small"
                  type="string"
                  fullWidth
                  displayEmpty
                  name="customer_policy_id"
                  value={Number(values.customer_policy_id)}
                  onChange={handlePolicyChange}
                  placeholder="Select One"
                  options={policiesList.map((item) => {
                    return {
                      label: item.policy_number,
                      value: item.customer_policy_id,
                    };
                  })}
                />
              </Grid>
            </Grid>
            {policyInfo && (
              <Box>
                <Typography
                  variant="body1"
                  fontSize={"1rem"}
                  fontWeight={600}
                  mb={2}
                >
                  We have found the following details associated with the
                  selected policy number:
                </Typography>
                <Grid container spacing={2} mb={5}>
                  <Grid item xs={12} md={2}>
                    <Stack display={"flex"} justifyContent={"space-between"}>
                      <Typography
                        variant="body1"
                        fontSize={"1rem"}
                        fontWeight={600}
                      >
                        Customer Name
                      </Typography>
                      <Typography>{policyInfo.customer_name}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Stack display={"flex"} justifyContent={"space-between"}>
                      <Typography
                        variant="body1"
                        fontSize={"1rem"}
                        fontWeight={600}
                      >
                        Customer Code
                      </Typography>
                      <Typography>{policyInfo.customer_code}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Stack display={"flex"} justifyContent={"space-between"}>
                      <Typography
                        variant="body1"
                        fontSize={"1rem"}
                        fontWeight={600}
                      >
                        Policy
                      </Typography>
                      <Typography>{policyInfo.line_of_business}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Stack display={"flex"} justifyContent={"space-between"}>
                      <Typography
                        variant="body1"
                        fontSize={"1rem"}
                        fontWeight={600}
                      >
                        Branch Code
                      </Typography>
                      <Typography>{policyInfo.branch_code}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Stack display={"flex"} justifyContent={"space-between"}>
                      <Typography
                        variant="body1"
                        fontSize={"1rem"}
                        fontWeight={600}
                      >
                        Producer 1
                      </Typography>
                      <Typography>{policyInfo.producer_one_code}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Stack display={"flex"} justifyContent={"space-between"}>
                      <Typography
                        variant="body1"
                        fontSize={"1rem"}
                        fontWeight={600}
                      >
                        Policy Status
                      </Typography>
                      <Typography>{policyInfo.policy_status}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            )}
            <Box>
              <FileUploadV2
                value={values.attachment}
                onChange={(data) => {
                    setFile(data);
                }}
                onDelete={() => {
                    setFile(null);
                    setFieldValue("attachment", null);
                }}
                // onChange={(data) => setFile(file)}
                // onDelete={() => setFile(null)}
              />
            </Box>

            <Stack textAlign={"center"} mt={2} mb={2}>
              <Typography variant="h5" textAlign={"center"} fontWeight={600}>
                OR
              </Typography>
            </Stack>
            <Box>
              <Typography
                variant="body1"
                fontSize={"1rem"}
                fontWeight={600}
                mb={2}
              >
                Attachments
              </Typography>
              <DataTable {...attachmentTableProps} />
            </Box>

            <Box display={"flex"} justifyContent={"start"} mt={8}>
              <Button variant="contained" type="submit" disabled={saveLoading}>Save & Next</Button>
            </Box>
          </>
        </Stack>
      </form>
    </PageLoader>
  );
};
