import { useSelector } from "react-redux";
import { IStoreState } from "../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../hooks";
import React from "react";
import { Divider, Grid, Stack, Typography } from "@mui/material";
import { StandardCellList } from "../../../../components/Standards";
import { formatTime } from "../../../../helpers";
import { IAddGaragePlate } from "../../../../redux/TaskReducer/GaragePlate/garagePlate.types";
import { fetchAddGaragePlateAysnc } from "../../../../redux/TaskReducer/GaragePlate/garagePlateActions";
import { fetchAddInterestedPartyAysnc } from "../../../../redux/TaskReducer/InterestedParty/interestedPartyActions";
import { IAddInterestedParty } from "../../../../redux/TaskReducer/InterestedParty/interestedParty.types";
import { LoadState } from "../../../../constants/enums";
import { PageLoader } from "../../../../components/PageLoader/PageLoader";
import { ITaskFileUploadAttachment } from "../../../../redux/tasks/task.types";
import { FileUpload } from "../../../../components/FileUpload/FileUpload";

export const ViewCertificateofInsurance: React.FC<{
  taskCode: string;
  attachment: ITaskFileUploadAttachment[];
}> = (props) => {
  const { taskCode, attachment } = props;

  const { data, loading } = useSelector(
    (storeState: IStoreState) =>
      storeState.tasks.interestedParty.addInterestedParty
  );

  const dispatch = useDispatchWrapper();
  const [interestedPartyDetails, setInterestedPartyDetails] =
    React.useState<IAddInterestedParty>();

  React.useEffect(() => {
    dispatch(fetchAddInterestedPartyAysnc(taskCode));
  }, [taskCode]);

  React.useEffect(() => {
    setInterestedPartyDetails(data);
  }, [data]);

  return (
    <PageLoader loading={loading === LoadState.InProgress}>
      {interestedPartyDetails && (
        <Grid container spacing={1} sx={{ mt: 2 }}>
 
          <Grid item xs={12} lg={12}>
            <StandardCellList
              heading="Certificate of Insurance"
              colSize={3}
              list={[
                {
                  heading: "Party Code",
                  colSize: 1,
                  data: {
                    type: "text",
                    value: interestedPartyDetails.interested_party_code,
                  },
                },
                {
                  heading: "Type",
                  colSize: 2,
                  data: {
                    type: "text",
                    value: interestedPartyDetails.type,
                  },
                },
                {
                  heading: "Name",
                  colSize: 2,
                  data: {
                    type: "text",
                    value: interestedPartyDetails.name,
                  },
                },
                {
                  heading: "Address",
                  colSize: 4,
                  data: {
                    type: "text",
                    value:
                      interestedPartyDetails.address +
                      " " +
                      interestedPartyDetails.unit_or_suite +
                      " " +
                      interestedPartyDetails.city +
                      " " +
                      interestedPartyDetails.province_or_state +
                      " " +
                      interestedPartyDetails.country +
                      " " +
                      interestedPartyDetails.postal_code,
                  },
                },
                {
                  heading: "Effective Date",
                  colSize: 1.5,
                  data: {
                    type: "text",
                    value: interestedPartyDetails.effective_date_of_change,
                  },
                },
                {
                  heading: "Effective Time",
                  colSize: 1.5,
                  data: {
                    type: "text",
                    value: formatTime(
                      interestedPartyDetails.effective_time_of_change.toString()
                    ),
                  },
                },
                {
                  heading: "Details",
                  colSize: 10,
                  data: {
                    type: "text",
                    value: interestedPartyDetails.details,
                  },
                },
              ]}
            >
                <Divider sx={{ mt: 2, mb: 2 }} />
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="body1"
                    fontSize={"1.2rem"}
                    fontWeight={600}
                  >
                    Attachments
                  </Typography>
                  {attachment.length > 0 ? (
                    attachment.map((item) => {
                      return (
                        <Stack
                          key={item.key}
                          direction={"row"}
                          justifyContent={"center"}
                          gap={2}
                          alignItems={"center"}
                        >
                          <Typography
                            variant="h4"
                            textTransform={"capitalize"}
                            width={"30%"}
                          >
                            {" "}
                            {item.fileName}{" "}
                          </Typography>
                          <FileUpload value={item.url} deleteDisabled />
                        </Stack>
                      );
                    })
                  ) : (
                    <Typography variant="body1">No attachments</Typography>
                  )}
                </Grid>
              </Grid>
            </StandardCellList>
          </Grid>
        </Grid>
      )}
    </PageLoader>
  );
};
