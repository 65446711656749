import { LoadState } from "../../../constants/enums";

export interface IAccountingRecievableState {
  list: IRecievable[];
  listLoading: LoadState;
  data: IRecievable;
  loading: LoadState;
  error: string | null;
}

export interface IRecievable {
  payment_id: number | null;
  gl_account_id: string | null;
  gl_code: string | null;
  cheque_amount: string | null;
  branch: string | null;
  gl_description: string | null;
  status: string;
}

export const initialAccountingRecievableState: IAccountingRecievableState = {
  list: [],
  listLoading: LoadState.NotLoaded,
  data: {
    payment_id: null,
    gl_account_id: null,
    gl_code: null,
    cheque_amount: null,
    branch: null,
    gl_description: null,
    status: "Active",
  },
  loading: LoadState.NotLoaded,
  error: null,
};
