import { LoadState } from "../../../constants/enums";

export interface IAccountingCompanyInformationState {
  data: ICompanyInformation;
  loading: LoadState;
  error: string;
}

export interface ICompanyInformation {
  company_name: string | null;
  mailing_address: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  phone: string | null;
  telephone: string | null;
  fax: string | null;
  default_language: string | null;
  email: string | null;
  accounts_email: string | null;
  cl_email: string | null;
  pl_email: string | null;
  status: string;
}

export const initialAccountingCompanyInformation: IAccountingCompanyInformationState = {
    data: {
        company_name: null,
        mailing_address: null,
        city: null,
        state: null,
        zip: null,
        phone: null,
        telephone: null,
        fax: null,
        default_language: null,
        email: null,
        accounts_email: null,
        cl_email: null,
        pl_email: null,
        status: "",
    },
    loading: LoadState.NotLoaded,
    error: "",
}
