import { action } from "typesafe-actions";

import { IDate } from "../../components/Table/hooks/useDateFilter";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../messages/messagesActions";
import { uploadFile } from "../../helpers";
import { IAppForm } from "./appForm.types";

export const FETCH_APP_FORMS_LIST_PROGRESS = "FETCH_APP_FORMS_LIST_PROGRESS";
export const FETCH_APP_FORMS_LIST_SUCCESS = "FETCH_APP_FORMS_LIST_SUCCESS";
export const FETCH_APP_FORMS_LIST_FAILED = "FETCH_APP_FORMS_LIST_FAILED";

export const fetchAppFormListProgress = () =>
  action(FETCH_APP_FORMS_LIST_PROGRESS);
export const fetchAppFormListSuccess = (
  list: IAppForm[],
  totalRecords: number
) => action(FETCH_APP_FORMS_LIST_SUCCESS, { list, totalRecords });
export const fetchAppFormListFailed = () => action(FETCH_APP_FORMS_LIST_FAILED);

export const fetchAppFormListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    status: string,
    date: IDate,
    searchValue: string,
    searchType: string[],
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAppFormListProgress());
      let url = "";
      if (status !== "-1") {
        url = "&status=" + status;
      }

      let finalUrl = `/other/get-apps-and-forms?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}${url}`;

      if (searchType.length > 0 && searchValue) {
        finalUrl = `/other/get-apps-and-forms?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&column=${[
          searchType,
        ]}&value=${searchValue}`;
      }
      const res = await api.get(finalUrl);
      const data: IAppForm[] = res.data.data;
      const totalRecords = res.data.totalRecords;
      dispatch(fetchAppFormListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchAppFormListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertAppFormsAysnc =
  (
    appForm: IAppForm,
    file: any | null,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      const asPayload = {
      };
      const path = await uploadFile(
        file,
        "NOTICE",
        appForm.file || "",
        asPayload
      );
      await api.post("/other/edit-apps-and-forms", { ...appForm, file: path });
      dispatch(
        showMessage({
          type: "success",
          message: "App from saved succesfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const CLEAR_APP_FORM_STATE = "CLEAR_APP_FORM_STATE";

export const clearAppFormState = () => action(CLEAR_APP_FORM_STATE);
