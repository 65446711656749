import {
  AppRegistration,
  NotificationsActive,
  ThumbUpAlt,
} from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";
import React from "react";
import { IBreadcrumbProps } from "../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { useSelector } from "react-redux";
import { IStoreState } from "../../redux/initialStoreState";
import { IUserProfile } from "../../redux/UserProfileList/userProfile.types";
import { BeforeProfileTypes } from "../Profile/UsersList/UsersList.types";
import { useDateFilter } from "../../components/Table/hooks/useDateFilter";
import { useDispatchWrapper, useRecordCounts } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { fetchUsersListAsync } from "../../redux/UserProfileList/userProfileActions";
import { CustomSelect } from "../../components/formsComponents";
import { capitalizeWords, renderDropdownValue } from "../../helpers";
import { Box, Button, MenuItem, Stack, Typography } from "@mui/material";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../components/Table/interfaces/IDataTableProps";
import { LoadState, MODULE_IDS } from "../../constants/enums";
import { DateFilterDropdown } from "../../components/Table/components/DateFilterDropdown/DateFilterDropdown";
import { CustomDatePicker } from "../../components/formsComponents/CustomDatePicker";
import { RoleBasedCSVExportPlugin } from "../../security/RoleBasedComponents/RoleBasedCSVExportPlugin/RoleBasedCSVExportPlugin";
import { RefreshPlugin, SearchPlugin } from "../../components/Table/plugins";
import { deepOrange, deepPurple } from "@mui/material/colors";
import { CustomLink } from "../../components/CustomLink/CustomLink";
import { RoleBasedMessages } from "../../security/RoleBasedComponents/RoleBaseMessages/RoleBaseMessages";
import { RoleBasedEmailRightPanel } from "../../security/RoleBasedComponents/RoleBasedEmailRightPanel/RoleBasedEmailRightPanel";
import { CustomChip } from "../../components/CustomChip";
import { StandardTableActions } from "../../components/StandardTableActions/StandardTableActions";
import {
  clearRecordCounts,
  fetchRecordCountsAsync,
} from "../../redux/common/commonActions";
import { PageContainer } from "../../components/container/PageContainer";
import { Breadcrumb } from "../../layout/fullLayout/breadcrumb/Breadcrumb";
import { IconicMultiColumnCard } from "../../components/iconicMultiColumnCard/IconicMultiColumnCard";
import { StandadCard } from "../../components/Cards";
import { DataTable } from "../../components/Table/DataTable";
import { AddNewUserDialog } from "../Profile/UsersList/AddNewUser";
import { ViewProfileDialog } from "../Profile/ViewProfile/ViewProfileDialog";
import { useRoles } from "../../security/RolesProvider/RolesProvider";
import { StatusRenderer } from "../../components/ActiveInActiveStatus/ActiveInActiveStatus";

export const StaffDirectory: React.FC = () => {
  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard",
      title: "dashboard",
    },
    {
      title: "staff directory",
    },
  ];

  const { data, totalNumberOfRecords, loading } = useSelector(
    (storeState: IStoreState) => storeState.userprofile.users
  );
  const [openCreateUser, setOpenCreateUser] = React.useState(false);
  const [openProfileDialog, setOpenProfileDialog] =
    React.useState<IUserProfile | null>(null);

  const [tablePagination, setTablePagination] = React.useState({
    pageNumber: 1,
    rowsInPerPage: 25,
  });
  const [selectedOpp, setSelectedOppr] = React.useState<{
    isAllSelected: boolean;
    leads: BeforeProfileTypes[];
  }>({
    isAllSelected: false,
    leads: [],
  });
  const [status, setStatus] = React.useState<
    BeforeProfileTypes["status"] | "-1"
  >("ACTIVE");

  const { date, type, handleDateSelectChange, dateDropdownList, setDate } =
    useDateFilter({ defaultType: "allTimes" });

  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();

  const [searchType, setSearchType] = React.useState<string[]>(["user_code"]);
  const [search, setSearch] = React.useState<string>("");

  const fetchList = async () => {
    dispatch(
      fetchUsersListAsync(
        tablePagination.pageNumber,
        tablePagination.rowsInPerPage,
        status,
        date,
        search,
        searchType
      )
    );
  };

  React.useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablePagination, date, search, status]);

  const userRole = useRoles();

  const menuItems =
    selectedOpp.leads.length > 0 || selectedOpp.isAllSelected
      ? [
          {
            key: "action",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={""}
                displayEmpty
                renderValue={renderDropdownValue(`Choose Bulk Action`)}
                //onChange={(e) => setBranchOffice(e.target.value as string)}
              >
                <MenuItem value="1">Email</MenuItem>
              </CustomSelect>
            ),
          },
        ]
      : [];

  const usersTableProps: IDataTableProps = {
    isPagination: true,
    totalRecords: totalNumberOfRecords,
    rowsPerPageOptions: tablePagination.rowsInPerPage,
    isDataLoading: loading === LoadState.InProgress,
    selectionMode: "multiple",
    uniqueRowKeyName: "id",
    tableCommandBarProps: {
      leftItems: {
        customPlugins: [
          ...menuItems,
          {
            key: "status",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={status}
                onChange={(e) => setStatus(e.target.value as "ACTIVE")}
              >
                <MenuItem value="-1">All Status</MenuItem>
                <MenuItem value="ACTIVE">Active</MenuItem>
                <MenuItem value="INACTIVE">Inactive</MenuItem>
                <MenuItem value="ON-LEAVE">On-Leave</MenuItem>
                <MenuItem value="REMOTE WORK">Remote Work</MenuItem>
              </CustomSelect>
            ),
          },
          {
            key: "byDate",
            onRender: () => (
              <DateFilterDropdown
                value={type}
                options={dateDropdownList}
                onChange={handleDateSelectChange}
              />
            ),
          },
          {
            key: "fromDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "150px" }}
                  value={date.fromDate}
                  onChange={(newValue) => {
                    setDate({ ...date, fromDate: newValue });
                  }}
                />
              ) : (
                <></>
              ),
          },
          {
            key: "toDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "150px" }}
                  value={date.toDate}
                  onChange={(newValue) => {
                    setDate({ ...date, toDate: newValue });
                  }}
                />
              ) : (
                <></>
              ),
          },
          {
            key: "csvExport",
            onRender: (columns, items) => (
              <RoleBasedCSVExportPlugin
                columns={columns}
                items={items}
                filePrefixName="Report"
                moduleId={MODULE_IDS.STAFF_USER}
              />
            ),
          },
          {
            key: "refresh",
            onRender: () => <RefreshPlugin onClick={fetchList} />,
          },
        ],
      },
      rightItems: {
        plugins: {
          // searchField: {
          //   searchKeys: [],
          //   items: data,
          // },
        },
        customPlugins: [
          {
            key: "search",
            onRender: () => {
              return (
                <SearchPlugin
                  selectedDropdownValue={searchType}
                  dropdownOptions={[
                    { label: "User Code", value: "user_code" },
                    { label: "MGA/AGA Code", value: "branch_code" },
                    { label: "First Name", value: "first_name" },
                    { label: "Last Name", value: "last_name" },
                    { label: "Mobile", value: "mobile" },
                    { label: "MGA/AGA", value: "branch" },
                    { label: "Role", value: "role_name" },
                  ]}
                  onDropdownChange={(value) => setSearchType(value)}
                  onChange={(newValue) => {
                    setSearch(newValue);
                  }}
                />
              );
            },
          },
        ],
      },
    },
    mobileLogo: {
      type: MobileLogoRenderType.reactNode,
      onMobileLogoRender: (data: any) => {
        return (
          <Avatar
            sx={{
              width: 45,
              height: 45,
              color: "#fff",
              ml: "-8px",
              mr: 2,
            }}
          ></Avatar>
        );
      },
    },
    columns: [
      {
        key: "view",
        headerName: "Actions",
        isFirstColumnSticky: true,
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IUserProfile) => {
          return (
            <StandardTableActions
              onViewClick={() => {
                if (userRole.roles[0].role_name === "Admin") {
                  navigate("/users/view-profile/" + row.user_fact_id);
                } else {
                  setOpenProfileDialog(row as IUserProfile);
                }
              }}
            />
          );
        },
      },
      {
        key: "first_name",
        headerName: "Name",
        fieldName: "first_name",
        exportCellWidth: 25,
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IUserProfile) => {
          return (
            <Stack direction="row" alignItems={"center"} spacing={1}>
              {value.value && (
                <>
                  <Avatar
                    sx={{
                      bgcolor:
                        value.column.rowIndex % 2 === 0
                          ? deepOrange[400]
                          : deepPurple[400],
                    }}
                  >
                    {value.value.substring(0, 1)}
                  </Avatar>
                  <Typography variant="body2" fontWeight={"700"}>
                    {row.first_name} {row.last_name}
                  </Typography>
                </>
              )}
            </Stack>
          );
        },
      },

      {
        key: "user_code",
        headerName: "User Code",
        fieldName: "user_code",
        renderType: RenderType.CHIP_ERROR,
        exportCellWidth: 30,
        enableSorting: true,
      },
      {
        key: "mobile",
        headerName: "Mobile",
        fieldName: "mobile",
        exportCellWidth: 25,
        renderType: RenderType.TEXT_DARK,
        onRowCellRender: (value, row: IUserProfile) => {
          if (row.mobile) {
            return (
              <RoleBasedMessages
                phone={row.mobile}
                moduleId={MODULE_IDS.STAFF_USER}
              />
            );
          }

          return "--";
        },
      },

      {
        key: "assigned_phone_number",
        headerName: "Phone",
        fieldName: "assigned_phone_number",
        exportCellWidth: 25,
        renderType: RenderType.TEXT_DARK,
        onRowCellRender: (value, row: IUserProfile) => {
          if (row.assigned_phone_number) {
            return (
              <RoleBasedMessages
                phone={row.assigned_phone_number || "--"}
                moduleId={MODULE_IDS.STAFF_USER}
              />
            );
          }

          return "--";
        },
      },

      {
        key: "phone_ext",
        headerName: "Ext",
        fieldName: "phone_ext",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 30,
      },

      {
        key: "email",
        headerName: "Email",
        fieldName: "email",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 30,
        enableSorting: true,
        onRowCellRender: (value, row) => {
          return (
            <RoleBasedEmailRightPanel
              displayLabel={row.email}
              toEmail={row.email}
              moduleId={MODULE_IDS.STAFF_USER}
            />
          );
        },
      },

      {
        key: "branch_code",
        headerName: "MGA/AGA Code",
        fieldName: "branch_code",
        renderType: RenderType.CHIP_SUCCESS,
        enableSorting: true,
        exportCellWidth: 30,
      },

      {
        key: "role_name",
        headerName: "Role",
        fieldName: "role_name",
        renderType: RenderType.CHIP_WARNING,
        exportCellWidth: 30,
        enableSorting: true,
      },

      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IUserProfile) => {
          return <StatusRenderer status={row.status || ""} />;
        },
      },

  
    ],
    items: data,
    onPageChange: (newPageNumber: number) => {
      setTablePagination({ ...tablePagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setTablePagination({
        pageNumber: pageNumber,
        rowsInPerPage: rowsPerPage,
      });
    },
    onSelection: (isAllSelected: boolean, rows: BeforeProfileTypes[]) => {
      setSelectedOppr({ isAllSelected: isAllSelected, leads: rows });
    },
  };

  const handleToggleCreate = () => {
    setOpenCreateUser(!openCreateUser);
    // navigate("/users/manage");
  };

  const { list: recordsCounts, loading: recordCountsLoading } =
    useRecordCounts();

  React.useEffect(() => {
    dispatch(
      fetchRecordCountsAsync(
        "latest_user",
        "status",
        date.fromDate,
        date.toDate
      )
    );
    return () => {
      dispatch(clearRecordCounts());
    };
  }, [date.fromDate, date.toDate]);

  return (
    <PageContainer
      title="Staff Directory List"
      description="this is Staff Directory list"
    >
      <Breadcrumb title="" items={BCrumb} />

      <IconicMultiColumnCard
        loading={recordCountsLoading !== LoadState.Loaded}
        columns={recordsCounts.map((item) => {
          return {
            heading: capitalizeWords(item.name),
            value: item.cnt,
            colSize: 2,
          };
        })}
      />

      <StandadCard>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          sx={{
            mx: 5,
            my: 2,
          }}
        >
          <Typography variant="h2" fontWeight={"700"}>
            Staff Directory
          </Typography>
        </Box>

        <DataTable {...usersTableProps} />
      </StandadCard>
      {openCreateUser && (
        <AddNewUserDialog open={openCreateUser} onClose={handleToggleCreate} />
      )}
      {openProfileDialog && (
        <ViewProfileDialog
          open={true}
          onClose={() => setOpenProfileDialog(null)}
          information={openProfileDialog}
        />
      )}
    </PageContainer>
  );
};
