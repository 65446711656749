import produce from "immer";
import { RecievableActions } from ".";
import { IStoreState } from "../../initialStoreState";
import {
  CLEAR_ACCOUNTING_RECIEVABLE,
  FETCH_ACCOUNTING_RECIEVABLE_FAILURE,
  FETCH_ACCOUNTING_RECIEVABLE_LIST_FAILURE,
  FETCH_ACCOUNTING_RECIEVABLE_LIST_PROGRESS,
  FETCH_ACCOUNTING_RECIEVABLE_LIST_SUCCESS,
  FETCH_ACCOUNTING_RECIEVABLE_PROGRESS,
  FETCH_ACCOUNTING_RECIEVABLE_SUCCESS,
} from "./RecievableActions";
import { LoadState } from "../../../constants/enums";
import { initialAccountingRecievableState } from "./Recievable.types";

export const AccountingReceivableReducer = (
  state: IStoreState["accounting"]["receivable"] = initialAccountingRecievableState,
  action: RecievableActions
) => {
  switch (action.type) {
    case FETCH_ACCOUNTING_RECIEVABLE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_RECIEVABLE_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_RECIEVABLE_FAILURE: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_RECIEVABLE_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_RECIEVABLE_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.Loaded;
        draftState.list = data;
      });
      return newState;
    }

    case FETCH_ACCOUNTING_RECIEVABLE_LIST_FAILURE: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_ACCOUNTING_RECIEVABLE: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.NotLoaded;
        draftState.data = initialAccountingRecievableState.data;
        draftState.error = null;
      });
      return newState;
    }

    default: {
      return state;
    }
  }
};
