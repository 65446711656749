import { combineReducers } from "redux";
import { customizerReducer } from "./customizer/customizerReducer";
import { IStoreState } from "./initialStoreState";
import { insurerReducer } from "./insurer/insurerReducer";
import { messageReducer } from "./messages/messagesReducer";
import { customerReducer } from "./customer/customerReducer";
import { underWriterReducer } from "./underWriter/underWriterReducer";
import { csioCompanyReducer } from "./csioCompany/csioCompanyReducer";
import { branchOfficeReducer } from "./branchOffice/branchOfficeReducer";
import { brokerCodeReducer } from "./brokerCode/brokerCodeReducer";
import { subInsurerReducer } from "./subInsurer/subInsurerReducer";
import { policyCommissionReducer } from "./policyCommission/policyCommissionReducer";
import { insurerContactsReducer } from "./insurerContacts/insurerContactsReducer";
import { businesspolicBasicDetailsReducer } from "./businessPolicy/businessPolicyBasicDetails/businessPolicyBasicDetailsReducer";
import { businessPolicyUnderWritingReducer } from "./businessPolicy/businessPolicyUnderWriting/businePolicyUnderWritingReducer";
import { businessPolicyCoverageReducer } from "./businessPolicy/businessPolicyCoverage/businesPolicyCoverageReducer";
import { businessPolicyRiskLocationReducer } from "./businessPolicy/buisenessPolicyRiskLocation/businessPolicyRiskLocationReducer";
import { businessPolicyFineArtReducer } from "./businessPolicy/businessPolicyFineArt/businessPolicyFineArtReducer";
import { businessPolicyToolFloaterReducer } from "./businessPolicy/businessPolicyToolFloater/businessPolicyToolFloatreReducer";
import { businessPolicyEquipmentReducer } from "./businessPolicy/businessPolicyEquipment/businessPolicyEquipmentReducer";
import { businessPolicyAdditionalReducer } from "./businessPolicy/businessPolicyAdditional/businessPolicyAdditionalReducer";
import { businessPolicyInterestedReducer } from "./businessPolicy/businessPolicyInterested/businessPolicyInterestedReducer";
import { businessPolicyPremiumFinanceReducer } from "./businessPolicy/businessPolicyPremiumFinance/businessPolicyPremiumFinanceReducer";
import { businessPolicyAuthorizationReducer } from "./businessPolicy/businessPolicyAuthorization/businessPolicyAuthorizationReducer";
import { businessPolicySubscriptionReducer } from "./businessPolicy/businessPolicySubscription/businessPolicySubscriptionReducer";
import { businessPolicUnderLyingReducer } from "./businessPolicy/businessPolicyUnderLyingInsurance/businessPolicyUnderLyingInsuranceReducer";
import { commonReducer } from "./common/commonReducer";
import { AutomobileGaragePlatesReducer } from "./automobileGaragePlates/automobileGaragePlatesReducer";
import { AutomobileVehicleReducer } from "./automobileVehicle/automobileVehicleReducer";
import { automobilespolicBasicDetailsReducer } from "./automobileBasicDetails/automobileBasicDetailsReducer";
import { AutomobileCSVReducer } from "./automobileCSV/automobileCSVReducer";
import { AutomobileDriverReducer } from "./automobileDriver/automobileDriverReducer";
import { AutomobileScheduleReducer } from "./automobileSchedule/automobileScheduleReducer";
import { AutomobilePolicyAuthorizationReducer } from "./automobilePolicyAuthorization/automobilePolicyAuthorizationReducer";
import { habitationalBasicDetailsReducer } from "./habitationalPolicy/habitationalBasicDetails/habitationalBasicDetailsReducer";
import { HabitationalClaimReducer } from "./habitationalPolicy/habitationalClaim/habitationalCLaimReducer";
import { UserProfileReducer } from "./UserProfileList/userProfileReducer";
import { tasksReducer } from "./tasks/tasksReducer";
import { tasksCalenderReducer } from "./tasksCalender/tasksCalenderReducer";
import { generalChangeReducer } from "./generalChange/generalChangeReducer";
import { taskDriverReducer } from "./TaskReducer/Driver/driverReducer";
import { taskVehicleReducer } from "./TaskReducer/Vehicle/taskVehicleReducer";
import { taskGaragePlateReducer } from "./TaskReducer/GaragePlate/garagePlateReducer";
import { taskInterestedPartyReducer } from "./TaskReducer/InterestedParty/interestedPartyReducer";
import { taskLessorReducer } from "./TaskReducer/Lessor/lessorReducer";
import { taskRiskLocationReducer } from "./TaskReducer/RiskLocation/taskriskLocationReducer";
import { opportunitesReducer } from "./opportunites/opportunitesReducer";
import { leadsReducer } from "./leads/leadsReducer";
import { businessPolicyRestrutantReducer } from "./businessPolicy/businessPolicyRestrutant/businessPolicyRestrutantReducer";
import { businessPolicyContractorReducer } from "./businessPolicy/businessPolicyContractor/businessPolicyContractorReducer";
import { businessPolicyHistoryReducer } from "./businessPolicy/businessPolicyHistory/businessPolicyHistoryReducer";
import { businessPolicyMarketingReducer } from "./businessPolicy/businessPolicyMarketing/businessPolicyMarketingReducer";
import { businessPolicyLiabilityReducer } from "./businessPolicy/businessPolicyLiability/businessPolicyLiabilityReducer";
import { businessPropertyInsuranceReducer } from "./businessPolicy/businessPropertyInsurance/businessPropertyInsuranceReducer";
import { businessGarageCertificateReducer } from "./businessPolicy/businessGarageCertificate/businessGarageCertificateReducer";
import { lifeInsuranceBasicDetailsReducer } from "./lifeInsurancePolicy/lifeInsuranceBasicDetail/lifeInsuranceBasicDetailReducer";
import { automobilePolicyMarketingReducer } from "./automobileMarketingHistory/automobileMarketingHistoryReducer";
import { habitationalBinderCsioReducer } from "./habitationalPolicy/habitationalBinderCsio/habitationalBinderCsioReducer";
import { automobileCertificatesReducer } from "./automobileCertificates/automobileCertificatesReducer";
import { habitationalPaymentAuthorizationReducer } from "./habitationalPolicy/habitationalPaymentAuthorization/habitationalPaymentAuthorizationReducer";
import { HabitationalNonCsioBinderReducer } from "./habitationalPolicy/habitationalNonBinderCsio/habitationalNonCsioBinderReducer";
import { AutomobileApplicationReducer } from "./automobileApplication/automobileApplicationReducer";
import { habitationalApplicationReducer } from "./habitationalPolicy/habitationalApplication/habitationalApplicationReducer";
import { lifeInsurancePoliciesReducer } from "./lifeInsurancePolicy/lifeInsurancePolicies/lifeInsurancePoliciesReducer";
import { lifeInsuranceComplianceCheckListReducer } from "./lifeInsurancePolicy/lifeInsurancePolicyComplianceCheckList/lifeInsurancePolicyComplianceCheckListReducer";
import { lifeInsurancePolicyExistingPropertyReducer } from "./lifeInsurancePolicy/lifeInsurancePolicyExistingProperty/lifeInsurancePolicyExistingPropertyReducer";
import { lifeInsurancePolicyFamilyTreeReducer } from "./lifeInsurancePolicy/lifeInsurancePolicyFamilyTree/lifeInsurancePolicyFamilyTreeReducer";
import { automobileSupplementReducer } from "./automobileSupplement/automobileSupplementReducer";
import { lifeInsuranceCoverageNeedReducer } from "./lifeInsurancePolicy/lifeInsuranceCoverageNeed/lifeInsuranceCoverageNeedReducer";
import { AutomobileGarageApplicationReducer } from "./automobileGarageApplication/automobileGarageApplicationReducer";
import { CommAutomobilespolicBasicDetailsReducer } from "./commAutoPolicy/commAutoBasicDetails/commAutoBasicDetailsReducer";
import { CommAutomobileApplicationReducer } from "./commAutoPolicy/commAutoApplication/commAutoApplicationReducer";
import { CommAutomobileCertificatesReducer } from "./commAutoPolicy/commAutoCertificates/commAutoCertificatesReducer";
import { CommAutomobileCSVReducer } from "./commAutoPolicy/commAutoCSV/commAutoCSVReducer";
import { CommAutomobileDriverReducer } from "./commAutoPolicy/commAutoDriver/commAutoDriverReducer";
import { CommAutomobileGarageApplicationReducer } from "./commAutoPolicy/commAutoGarageApplication/commAutoGarageApplicationReducer";
import { CommAutomobileGaragePlatesReducer } from "./commAutoPolicy/commAutoGaragePlates/commAutoGaragePlatesReducer";
import { CommAutomobilePolicyMarketingReducer } from "./commAutoPolicy/commAutoMarketingHistory/commAutoMarketingHistoryReducer";
import { CommAutomobilePolicyAuthorizationReducer } from "./commAutoPolicy/commAutoPolicyAuthorization/commAutoPolicyAuthorizationReducer";
import { CommAutomobileScheduleReducer } from "./commAutoPolicy/commAutoSchedule/commAutoScheduleReducer";
import { CommAutomobileSupplementReducer } from "./commAutoPolicy/commAutoSupplement/commAutoSupplementReducer";
import { CommAutomobileVehicleReducer } from "./commAutoPolicy/commAutoVehicle/commAutoVehicleReducer";
import { insuranceReducer } from "./insuranceReports/insuranceReportsReducer";
import { applicationReducer } from "./allApplications/allApplicationsReducer";
import { templateReducer } from "./templates/templateReducer";
import { bindersReducer } from "./Binder/binderReducer";
import { MarketingsReducer } from "./marketing/marketingReducer";
import { NewRemarketReducer } from "./NewRemarket/NewRemarketReducer";
import { IntrestedPartiesReducer } from "./InterestedParties/IntrestedPartiesReducer";
import { AdditionalInterestReducer } from "./AdditionalInterest/additionalInterestReducer";
import { tasksPaymentMethodsReducer } from "./taskPaymentMethod/taskPaymentMethodsReducer";
import { taskUrgentTransactionsReducer } from "./taskUrgentTransactions/taskUrgentTransactionsReducer";
import { tasksBoardReducer } from "./taskBoard/taskBoardReducer";
import { securityReducer } from "./security/securityReducer";
import legalsReducer from "./legals/legalsReducer";
import { noticesReducer } from "./notices/noticesReducer";
import eDocsReducer from "./eDocs/eDocsReducer";
import { notesReducer } from "./notes/notesReducer";
import { AccountingCompanyInformationReducer } from "./Accounting/CompanyInformation/companyInformationReducer";
import { AccountingTaxInformationReducer } from "./Accounting/TaxInformation/taxInformationReducer";
import { AccountingGLAccountsReducer } from "./Accounting/GLAccounts/glAccountsReducer";
import { ChartsOfAccountsReducer } from "./Accounting/ChartsOfAccounts/ChartsOfAccountsReducer";
import { appFormReducer } from "./appForm/appFormReducer";
import { AccountingIInteracEmailTransferReducer } from "./Accounting/InteracEmailTransfer/InteracEmailTransferReducer";
import { AccountingEftReducer } from "./Accounting/Eft/EftReducer";
import { AccountingReceivableReducer } from "./Accounting/Recievable/ReceivableReducer";
import { AccountingCompanyDetailReducer } from "./Accounting/CompanyDetail/CompanyDetailReducer";
import { AccountingCompanyOnboardingReducer } from "./Accounting/CompanyOnboarding/CompanyOnboardingReducer";
import { AccountingTaxInfoReducer } from "./Accounting/TaxInfo/TaxInfoReducer";
import { AccountingTransactionsReducer } from "./Accounting/Transactions/TransactionsReducer";
import { AccountingInformationReducer } from "./Accounting/AccountingInformation/AccountingInformationReducer";
import { AccountingInvoiceInformationReducer } from "./Accounting/InvoiceInformation/InvoiceInformationReducer";
import { AccountingBankAccountReducer } from "./Accounting/BankAccount/BankAccountReducer";
import { AccountingChequeReducer } from "./Accounting/Cheque/ChequeReducer";
import { AccountingAccountsPayableReducer } from "./Accounting/AccountsPayable/AccountsPayableReducer";
import { AccountingPaymentAccountsRecievableReducer } from "./Accounting/PaymentAccountsRecievable/PaymentAccountsRecievableReducer";
import { AccountingRecievableTrustReducer } from "./Accounting/RecievableTrust/RecievableTrustReducer";
import { AccountingAccountsPayableChequeReducer } from "./Accounting/AccountsPayableCheque/AccountsPayableChequeReducer";
import { AccountingVendorReducer } from "./Accounting/Vendor/VendorReducer";
import { g4SignDocumentsReducer } from "./g4SignDocuments/g4SignDocumentsReducer";
import { borkerContactsReducer } from "./brokerContacts/brokerContactsReducer";
import { supportTicketsReducer } from "./supportTickets/supportTicketsReducer";
import { allBindersReducer } from "./AllBinders/allbinderReducer";
import { renewalsReducer } from "./renewals/renewalsReducer";

const appReducer = combineReducers({
  customizerReducer: customizerReducer,
  borkerage: combineReducers({
    insurer: insurerReducer,
    subInsurer: subInsurerReducer,
    branchOffice: branchOfficeReducer,
    brokerCode: brokerCodeReducer,
    underwriter: underWriterReducer,
    csioCompany: csioCompanyReducer,
    insurerContacts: insurerContactsReducer,
    policyCommission: policyCommissionReducer,
    intrestedParties: IntrestedPartiesReducer,
    additionalInterest: AdditionalInterestReducer,
    brokerContacts: borkerContactsReducer,
  }),
  customer: customerReducer,
  message: messageReducer,
  business: combineReducers({
    basicDetails: businesspolicBasicDetailsReducer,
    underwriting: businessPolicyUnderWritingReducer,
    coverage: businessPolicyCoverageReducer,
    riskLocation: businessPolicyRiskLocationReducer,
    fineArt: businessPolicyFineArtReducer,
    toolFloater: businessPolicyToolFloaterReducer,
    equipment: businessPolicyEquipmentReducer,
    interested: businessPolicyInterestedReducer,
    additional: businessPolicyAdditionalReducer,
    premiumFinance: businessPolicyPremiumFinanceReducer,
    authorization: businessPolicyAuthorizationReducer,
    subscription: businessPolicySubscriptionReducer,
    underLying: businessPolicUnderLyingReducer,
    restrutant: businessPolicyRestrutantReducer,
    contractor: businessPolicyContractorReducer,
    history: businessPolicyHistoryReducer,
    marketing: businessPolicyMarketingReducer,
    lability: businessPolicyLiabilityReducer,
    insurance: businessPropertyInsuranceReducer,
    certificate: businessGarageCertificateReducer,
  }),
  automobile: combineReducers({
    basicDetails: automobilespolicBasicDetailsReducer,
    garagePlate: AutomobileGaragePlatesReducer,
    vehicle: AutomobileVehicleReducer,
    csv: AutomobileCSVReducer,
    driver: AutomobileDriverReducer,
    schedule: AutomobileScheduleReducer,
    authorization: AutomobilePolicyAuthorizationReducer,
    marketing: automobilePolicyMarketingReducer,
    certificates: automobileCertificatesReducer,
    appication: AutomobileApplicationReducer,
    supplement: automobileSupplementReducer,
    garageApplication: AutomobileGarageApplicationReducer,
  }),
  commAuto: combineReducers({
    basicDetails: CommAutomobilespolicBasicDetailsReducer,
    appication: CommAutomobileApplicationReducer,
    certificates: CommAutomobileCertificatesReducer,
    csv: CommAutomobileCSVReducer,
    driver: CommAutomobileDriverReducer,
    garageApplication: CommAutomobileGarageApplicationReducer,
    garagePlate: CommAutomobileGaragePlatesReducer,
    marketing: CommAutomobilePolicyMarketingReducer,
    authorization: CommAutomobilePolicyAuthorizationReducer,
    schedule: CommAutomobileScheduleReducer,
    supplement: CommAutomobileSupplementReducer,
    vehicle: CommAutomobileVehicleReducer,
  }),
  habitational: combineReducers({
    basicDetails: habitationalBasicDetailsReducer,
    claimDetails: HabitationalClaimReducer,
    binderCsio: habitationalBinderCsioReducer,
    paymentAuthorization: habitationalPaymentAuthorizationReducer,
    nonCsioBinder: HabitationalNonCsioBinderReducer,
    applications: habitationalApplicationReducer,
  }),
  lifeInsurance: combineReducers({
    basicDetails: lifeInsuranceBasicDetailsReducer,
    policies: lifeInsurancePoliciesReducer,
    complianceCheckList: lifeInsuranceComplianceCheckListReducer,
    existingProperty: lifeInsurancePolicyExistingPropertyReducer,
    familyTree: lifeInsurancePolicyFamilyTreeReducer,
    coverageNeed: lifeInsuranceCoverageNeedReducer,
  }),

  userprofile: UserProfileReducer,
  common: commonReducer,
  tasks: combineReducers({
    allTasks: tasksReducer,
    calender: tasksCalenderReducer,
    generalChange: generalChangeReducer,
    paymentMethods: tasksPaymentMethodsReducer,
    urgentTransactions: taskUrgentTransactionsReducer,
    driver: taskDriverReducer,
    vehicle: taskVehicleReducer,
    garagePlate: taskGaragePlateReducer,
    interestedParty: taskInterestedPartyReducer,
    lessor: taskLessorReducer,
    riskLocation: taskRiskLocationReducer,
    taskBoard: tasksBoardReducer,
    notices: noticesReducer,
  }),

  opportunites: opportunitesReducer,
  leads: leadsReducer,
  legals: legalsReducer,
  insuranceReports: insuranceReducer,
  binders: bindersReducer,
  allbinders: allBindersReducer,
  renewals: renewalsReducer,
  Marketings: MarketingsReducer,
  NewRemarketChange: NewRemarketReducer,

  //HR
  allApplications: applicationReducer,
  templates: templateReducer,

  security: securityReducer,

  //eDocs
  eDocs: eDocsReducer,
  policyNotes: notesReducer,

  //Accounting
  accounting: combineReducers({
    accountingCompanyInformation: AccountingCompanyInformationReducer,
    accountingTaxInformation: AccountingTaxInformationReducer,
    accountingGLAccounts: AccountingGLAccountsReducer,
    interacEmailTransfer: AccountingIInteracEmailTransferReducer,
    eft: AccountingEftReducer,
    receivable: AccountingReceivableReducer,
    companyDetail: AccountingCompanyDetailReducer,
    chartsOfAccounts: ChartsOfAccountsReducer,
    companyOnboarding: AccountingCompanyOnboardingReducer,
    taxInfo: AccountingTaxInfoReducer,
    transactions: AccountingTransactionsReducer,
    information: AccountingInformationReducer,
    invoiceInformation: AccountingInvoiceInformationReducer,
    bankAccount: AccountingBankAccountReducer,
    cheque: AccountingChequeReducer,
    accountsPayable: AccountingAccountsPayableReducer,
    recievableTrust: AccountingRecievableTrustReducer,
    paymentAccountsRecievable: AccountingPaymentAccountsRecievableReducer,
    accountsPayableCheque: AccountingAccountsPayableChequeReducer,
    vendor: AccountingVendorReducer,
  }),
  g4Sign: combineReducers({
    documents: g4SignDocumentsReducer,
  }),

  appForm: appFormReducer,
  supportTickets: supportTicketsReducer
});

export const rootReducer = (state: IStoreState | undefined, action: any) => {
  if (action.type === "USER_LOGOUT") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
