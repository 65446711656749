import React from "react";
import { DOCU_SIGN_FIELD_TYPES } from "../enums";
import { SignableField } from "../widgets/signableField/SignableField";
import { InitialSignField } from "../widgets/initialSignField/InitialSignField";
import { IWidgetRendererProps } from "./WidgetRenderer.types";
import { SignNameField } from "../widgets/SignNameField/SignNameField";
import { CompanyField } from "../widgets/companyField/CompanyField";

export const WidgetsRenderer: React.FC<IWidgetRendererProps> = (props) => {
  const {
    type,
    dragDisabled,
    onPlaceholderDelete,
    onPlaceholderUpdated,
    placeholder,
  } = props;

  switch (type) {
    case DOCU_SIGN_FIELD_TYPES.SIGNATURE:
      return (
        <SignableField
          placeholder={placeholder}
          onPlaceholderUpdated={onPlaceholderUpdated}
          onPlaceholderDelete={onPlaceholderDelete}
          dragDisabled={dragDisabled}
        />
      );
    case DOCU_SIGN_FIELD_TYPES.FREE_SIGNATURE:
      return (
        <InitialSignField
          placeholder={placeholder}
          onPlaceholderUpdated={onPlaceholderUpdated}
          onPlaceholderDelete={onPlaceholderDelete}
          dragDisabled={dragDisabled}
        />
      );
    case DOCU_SIGN_FIELD_TYPES.FULL_NAME:
      return (
        <SignNameField
          placeholder={placeholder}
          onPlaceholderUpdated={onPlaceholderUpdated}
          onPlaceholderDelete={onPlaceholderDelete}
          dragDisabled={dragDisabled}
        />
      );
      case DOCU_SIGN_FIELD_TYPES.COMPANY:
        return (
          <CompanyField
            placeholder={placeholder}
            onPlaceholderUpdated={onPlaceholderUpdated}
            onPlaceholderDelete={onPlaceholderDelete}
            dragDisabled={dragDisabled}
          />
        );
    default:
      return <></>;
  }
};
