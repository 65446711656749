/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line react/jsx-pascal-case

import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../redux/initialStoreState";
import { useDispatchWrapper, useQueryParms } from "../../../../../hooks";
import { fetchCustomerInfoAsync } from "../../../../../redux/businessPolicy/businessPolicyBasicDetails/businessPolicyBasicDetailsActions";
import { PageLoader } from "../../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../../constants/enums";
import { StandadCard } from "../../../../../components/Cards";
import { TabsList } from "../../../../../components/Tabs/TabsList";
import {
  AccountCircle,
  AdminPanelSettings,
  Receipt,
  StickyNote2,
  UnarchiveSharp,
} from "@mui/icons-material";
import { TabPanel } from "../../../../../components/Tabs/TabPanel";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useBreadcrumbContext } from "../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { fetchAutomobileVehicleListAsync } from "../../../../../redux/automobileVehicle/automobileVehicleActions";
import { fetchBindersListAsync } from "../../../../../redux/Binder/binderActions";
import { CommAutomobileAuthorizationList } from "./CommAutoAuthorizationForm/CommAutoAuthorizationFormList";
import { CommAutoOPCF16List } from "./CommAutoOPCF_16/CommAutoOPCF_16List";
import { CommAutoOPCF17List } from "./CommAutoOPCF_17/CommAutoOPCF_17List";
import { CommAutoOPCF28AList } from "./CommAutoOPCF_28/CommAutoOPCF_28List";
import { CommAutomobileCSIOCertificateList } from "./CommCSIOCertificate/CommCSIOCertificateList";
import { CommAutoLiabilitySlipsList } from "./CommAutoLiabilitySlips/CommAutoLiabilitySlipsList";
import { CommAutoCancellationList } from "./CommAutoCancellation/CommAutoCancellationList";
import { fetchCommAutomobileCustomerInfoAsync } from "../../../../../redux/commAutoPolicy/commAutoBasicDetails/commAutoBasicDetailsActions";
import {CommAutoConsentFormList} from "./CommAutoConsentForm/CommAutoConsentFormList";
import { CommAutoDwellingDeclarationList } from "./CommAutoDwellingDeclaration/CommAutoDwellingDeclarationList";
import { CommAutoDwellingList } from "./CommAutoDwelling/CommAutoDwellingList";
import { CommAutoRetireeDiscountList } from "./CommAutoRetireeDiscount/CommAutoRetireeDiscountList";
import { CommAutoOwnerFormList } from "./CommAutoOwnerForm/CommAutoOwnerFormList";
import { CommAutoGarageFormList } from "./CommAutoGarageForm/CommAutoGarageFormList";
import { CommAutoSharingFillableList } from "./CommAutoSharingFillable/CommAutoSharingFillableList";
import { CommAutoSupplementaryFormList } from "./CommAutoSupplementaryForm/CommAutoSupplementaryFormList";

export const CommAutoPolicyFormsRoot = () => {
  const data = useOutletContext() as any;
  const customerId = data[0];
  const customerPolicyId = data[1];
  const { addBreadcrumb } = useBreadcrumbContext();
  const { getParm } = useQueryParms();
  const tabValue = getParm("tab");
  const selectedTab = tabValue ? Number(tabValue) : 0;

  const { customerInfo } = useSelector(
    (storeState: IStoreState) => storeState.commAuto.basicDetails
  );

  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    navigate(
      `/comm-automobile-policy/${customerId}/policy-forms-tab/${customerPolicyId}?tab=${newValue}`
    );
  };

  React.useEffect(() => {
    if (customerPolicyId) {
      //   dispatch(fetchBindersListAsync(customerPolicyId));
      dispatch(fetchCommAutomobileCustomerInfoAsync(customerPolicyId));
    }
  }, [customerPolicyId]);

  React.useEffect(() => {
    addBreadcrumb({
      title: "",
      items: [
        {
          to: "/dashboard",
          title: "dashboard",
        },
        {
          to: "/view-customers",
          title: "customers",
        },
        {
          to: "/customer-overview/" + customerId,
          title: "customer-overview",
        },
        {
          to:
            "/habitational-policy/habitational/" +
            customerId +
            "/" +
            customerPolicyId,
          title: "binder",
        },
      ],
    });
  }, []);

  return (
    <PageLoader
      loading={customerInfo.loading === LoadState.InProgress}
      error={customerInfo.error ? { message: customerInfo.error } : null}
    >
      <StandadCard>
        <TabsList
          sx={{ mb: 1 , display: "flex"}}
          varaint="scrollable"
          selectedTab={selectedTab}
          tabs={[
            { label: "Liability Slips", icon: <AccountCircle /> },
            {
              label: "Certificate of Liability Insurance",
              icon: <AdminPanelSettings />,
            },
            {
              label: "OPCF 16",
              icon: <StickyNote2 />,
            },
            {
              label: "OPCF 17",
              icon: <Receipt />,
            },
            {
              label: "OPCF 28A",
              icon: <UnarchiveSharp />,
            },
            {
              label: "Payment Authorization",
              icon: <AdminPanelSettings />,
            },
            {
              label: "Cancellation",
              icon: <AccountCircle />,
            },
            {
              label: "Consent Form",
              icon: <Receipt />,
            },
            {
              label: "Dwelling Declaration",
              icon: <Receipt />,
            },
            {
              label: "Dwelling",
              icon: <Receipt />,
            },
            {
              label: "Retiree Discount",
              icon: <UnarchiveSharp />,
            },
            {
              label: "Garage Form",
              icon: <StickyNote2 />,
            },
            {
              label: "Owner Form",
              icon: <StickyNote2 />,
            },
            {
              label: "Sharing Fillable",
              icon: <AdminPanelSettings />,
            },
            {
              label: "Supplementary Form",
              icon: <StickyNote2/>,
            },
          ]}
          onChange={handleChange}
        />
        <TabPanel value={selectedTab} index={0}>
          <CommAutoLiabilitySlipsList
            customerId={customerId}
            customerPolicyId={customerPolicyId}
          />
        </TabPanel>

        <TabPanel value={selectedTab} index={1}>
          <CommAutomobileCSIOCertificateList
            customerId={customerId}
            customerPolicyId={customerPolicyId}
          />
        </TabPanel>

        <TabPanel value={selectedTab} index={2}>
          <CommAutoOPCF16List
            customerId={customerId}
            customerPolicyId={customerPolicyId}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={3}>
          <CommAutoOPCF17List
            customerId={customerId}
            customerPolicyId={customerPolicyId}
          />
        </TabPanel>
        
        <TabPanel value={selectedTab} index={4}>
          <CommAutoOPCF28AList
            customerId={customerId}
            customerPolicyId={customerPolicyId}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={5}>
          <CommAutomobileAuthorizationList />
        </TabPanel>
        <TabPanel value={selectedTab} index={6}>
          <CommAutoCancellationList
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={7}>
          <CommAutoConsentFormList
            customerId={customerId}
            customerPolicyId={customerPolicyId}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={8}>
          <CommAutoDwellingDeclarationList
            customerId={customerId}
            customerPolicyId={customerPolicyId}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={9}>
          <CommAutoDwellingList
            customerId={customerId}
            customerPolicyId={customerPolicyId}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={10}>
          <CommAutoRetireeDiscountList  
            customerId={customerId}
            customerPolicyId={customerPolicyId}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={11}>
          <CommAutoGarageFormList
            customerId={customerId}
            customerPolicyId={customerPolicyId}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={12}>
          <CommAutoOwnerFormList
            customerId={customerId}
            customerPolicyId={customerPolicyId}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={13}>
          <CommAutoSharingFillableList
            customerId={customerId}
            customerPolicyId={customerPolicyId}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={14}>
          <CommAutoSupplementaryFormList
            customerId={customerId}
            customerPolicyId={customerPolicyId}
          />
        </TabPanel>
        
      </StandadCard>
    </PageLoader>
  );
};
