/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, Stack } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import {
  CustomFormLabel,
  CustomTextField,
} from "../../../../../../../components/formsComponents";
import { IBusinesPolicyBasicDetailsProps } from "../BusinessPolicyBasicDetails.types";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import {
  addPolicyAdminsync,
  fetchPolicyAdminAsync,
} from "../../../../../../../redux/businessPolicy/businessPolicyBasicDetails/businessPolicyBasicDetailsActions";
import { PageLoader } from "../../../../../../../components/PageLoader/PageLoader";
import { LoadState, MODULE_IDS } from "../../../../../../../constants/enums";
import { InsurerCodeAutoSearch } from "../../../../../../../components/AutoCompleteSearches";
import { UsersAutoSearchByRole } from "../../../../../../../components/AutoCompleteSearches/UsersAutoSearchByRole";
import { ComissionCodeSelect } from "../../../../../../../components/AutoCompleteSearches/ComissionCodeSelect";
import { RoleBasedCustomButton } from "../../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { fetchMyProfileAsync } from "../../../../../../../redux/UserProfileList/userProfileActions";
import { useTabsUncontrolledContext } from "../../../../../../../contexts/TabsContextProvider/TabsContextUncontrolledProvider";

export const PolicyAdmin: React.FC<IBusinesPolicyBasicDetailsProps> = (
  props
) => {
  const { customerpolicyId } = props;
  const { data, loading } = useSelector(
    (storeState: IStoreState) => storeState.business.basicDetails.policyAdmin
  );
  const { userProfile } = useSelector(
    (storeState: IStoreState) => storeState.userprofile
  );

  const dispatch = useDispatchWrapper();
  const [saveLoading, setSaveLoading] = React.useState(false);
  const { customerProspect } = useSelector(
    (storeState: IStoreState) => storeState.customer
  );
  const [buttonClicked, setButtonClicked] = React.useState<string | null>(null);
  const {changeStep} = useTabsUncontrolledContext();
  const {
    values,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
    errors,
  } = useFormik({
    initialValues: data,
    validate: (values) => {
      const errors: any = {};
      if(!values.producer_1){
        errors.producer_1 = "Producer 1 is requried";
      }
      return errors;
    },
    onSubmit: async (values) => {
      setSaveLoading(true);
      dispatch(
        addPolicyAdminsync(
          {
            ...values,
            customer_policy_id: customerpolicyId,
          },
          (isSuccess) => {
            if(buttonClicked === "saveAndNext" && isSuccess){
              changeStep(2);
            }
            setSaveLoading(false);
          }
        )
      );
    },
  });

  React.useEffect(() => {
    dispatch(fetchPolicyAdminAsync(customerpolicyId));
  }, []);

  React.useEffect(() => {
    dispatch(fetchMyProfileAsync());
  }, []);

  React.useEffect(() => {
    setValues({
      ...data,
      branch_code: customerProspect.data.branch_code,
      producer_1: data.producer_1,
      producer_1_code: data.producer_1_code,
      producer_2: data.producer_2,
      producer_2_code: data.producer_2_code,
      producer_3:data.producer_3,
      producer_3_code: data.producer_3_code,
    });
  }, [data, userProfile, customerProspect]);

  // React.useEffect(() => {
  //   if (userProfile) {
  //     setFieldValue("producer_2", data.producer_2 || userProfile.producer_two);
  //     setFieldValue(
  //       "producer_3",
  //       data.producer_3 || userProfile.producer_three
  //     );
  //   }
  // }, [userProfile]);

  return (
    <PageLoader loading={loading === LoadState.InProgress}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={4}>
            <CustomFormLabel>Insurer</CustomFormLabel>
            <InsurerCodeAutoSearch
              value={values.insurer}
              onSelect={(value) => {
                setFieldValue("insurer", value.value);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <CustomFormLabel>Comission Code</CustomFormLabel>
            <ComissionCodeSelect
              insurerCode={values.insurer}
              value={values.comission_code}
              onChange={(value) => {
                setFieldValue("comission_code", value);
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <CustomFormLabel>Branch Code</CustomFormLabel>
            <CustomTextField fullWidth value={values.branch_code} disabled />
          </Grid>
          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Policy Fee</CustomFormLabel>
            <CustomTextField
              name="policy_fee"
              variant="outlined"
              size="small"
              type="number"
              fullWidth
              value={values.policy_fee}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <UsersAutoSearchByRole
              label="Producer 1"
              value={values.producer_1}
              errorMessage={errors.producer_1}
              onSelect={(data) => {
                setFieldValue("producer_1", data.user_fact_id);
                setFieldValue("producer_1_code", data.user_code);
              }}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <UsersAutoSearchByRole
              label="Producer 2"
              value={values.producer_2}
              onSelect={(data) => {
                setFieldValue("producer_2", data.user_fact_id);
                setFieldValue("producer_2_code", data.user_code);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <UsersAutoSearchByRole
              label="Producer 3"
              value={values.producer_3}
              onSelect={(data) => {
                setFieldValue("producer_3", data.user_fact_id);
                setFieldValue("producer_3_code", data.user_code);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <UsersAutoSearchByRole
              value={values.marketer}
              label="Marketer"
              onSelect={(value) => {
                setFieldValue("marketer", value.user_fact_id);
                setFieldValue("marketer_code", value.user_code);
              }}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <UsersAutoSearchByRole
              value={values.csr}
              label="CSR"
              onSelect={(value) => {
                setFieldValue("csr", value.user_fact_id);
                setFieldValue("csr_code", value.user_code);
              }}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Billing Account Number</CustomFormLabel>
            <CustomTextField
              name="billing_account_number"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.billing_account_number}
              // error={errors.name ? true : false}
              // helperText={errors.name}
              // disabled={isUpdateLead}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Broker Code</CustomFormLabel>
            {/* <BranchCodeAutoSearch
                value={values.broker_code}
                onSelect={(value) => {
                  setFieldValue("broker_code", value.value);
                }}
              /> */}
            <CustomTextField
              name="broker_code"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.broker_code}
              // error={errors.name ? true : false}
              // helperText={errors.name}
              // disabled={isUpdateLead}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Stack direction={'row'} spacing={2} sx={{ mt: 4 }}>
        <RoleBasedCustomButton
          type="button"
          moduleId={MODULE_IDS.BUSINESS_POLICY}
          disabled={saveLoading}
          variant="contained"
          onClick={() => changeStep(0)}
        >
          Back
        </RoleBasedCustomButton>
        <RoleBasedCustomButton
          type="submit"
          moduleId={MODULE_IDS.BUSINESS_POLICY}
          disabled={saveLoading}
          variant="contained"
          onClick={() => setButtonClicked("save")}
        >
          Save
        </RoleBasedCustomButton>
        <RoleBasedCustomButton
          type="submit"
          moduleId={MODULE_IDS.BUSINESS_POLICY}
          disabled={saveLoading}
          variant="contained"
          onClick={() => setButtonClicked("saveAndNext")}
        >
          Save & Next
        </RoleBasedCustomButton>
      </Stack>
      </form>
    </PageLoader>
  );
};
