import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../messages/messagesActions";
import {
  ADD_ADDITIONAL_INTEREST,
  ADD_ADDITIONAL_INTEREST_PROGRESS,
  CLEAR_ADDITIONAL_INTEREST_STATE,
  FETCH_ADDITIONAL_INTEREST_PROGRESS,
  FETCH_ADDITIONAL_INTEREST_SUCCESS,
} from "../constants";
import { getUserAuth } from "../../helpers";
import { IAdditionalInterest } from "./additionalInterest.types";

export const ADDITIONAL_INTEREST_ERROR = "ADDITIONAL_INTEREST_ERROR";
export const ADD_ADDITIONAL_INTEREST_ERROR = "ADD_ADDITIONAL_INTEREST_ERROR";

export const addAdditionalInterestProgress = () =>
  action(ADD_ADDITIONAL_INTEREST_PROGRESS);

export const addAdditionalInterest = (data: IAdditionalInterest) =>
  action(ADD_ADDITIONAL_INTEREST, { data: data });

export const addAdditionalInterestAsync =
  (
    data: IAdditionalInterest,
    onSuccess: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      dispatch(addAdditionalInterestProgress());
      await api.post("/insurance/create-additional-interest", {
        ...data,
        id: data.id,
      });
      dispatch(addAdditionalInterest(data));
      dispatch(
        showMessage({
          type: "success",
          message: "Additional Interest saved successfully!",
          displayAs: "snackbar",
        })
      );
      onSuccess();
    } catch (err: any) {
      dispatch(AdditionalInterestError(null));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted())
    }

  };

export const fetchAdditionalInterestProgress = () =>
  action(FETCH_ADDITIONAL_INTEREST_PROGRESS);

export const fetchAdditionalInterestSuccess = (data: IAdditionalInterest) =>
  action(FETCH_ADDITIONAL_INTEREST_SUCCESS, { data: data });

export const fetchAdditionalInterestAsync =
  (
    AdditionalInterestId: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const token = getUserAuth();
    try {
      if (token) {
        dispatch(fetchAdditionalInterestProgress());
        const res = await api.get(
          `/insurance/get-additional-interest?value=${AdditionalInterestId}`,
          {
            headers: {
              "auth-Token": token,
            },
          }
        );
        const data: IAdditionalInterest[] = res.data.data;
        if (data.length > 0) {
          dispatch(addAdditionalInterest(data[0]));
        } else {
          dispatch(
            AdditionalInterestError(
              "Oops! We couldn't find any records associated with your branch office at the moment. Please ensure that the provided information is accurate and try again."
            )
          );
        }
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const AdditionalInterestError = (message: string | null) =>
  action(ADDITIONAL_INTEREST_ERROR, {
    message: message,
  });

export const clearAdditionalInterestState = () =>
  action(CLEAR_ADDITIONAL_INTEREST_STATE);



  export const FETCH_ADDITIONAL_INTEREST_LIST_PROGRESS =
  "FETCH_ADDITIONAL_INTEREST_LIST_PROGRESS";
export const FETCH_ADDITIONAL_INTEREST_LIST_SUCCESS =
  "FETCHE_ADDITIONAL_INTEREST_LIST_SUCCESS";
export const FETCH_ADDITIONAL_INTEREST_LIST_FAILED =
  "FETCH_ADDITIONAL_INTEREST_LIST_FAILED";

export const fetchBrokerageAdditionalInterestProgress = () =>
  action(FETCH_ADDITIONAL_INTEREST_LIST_PROGRESS);
export const fetchBrokerageAdditionalInterestSuccess = (
  data: IAdditionalInterest[],
  totalRecords: number
) => action(FETCH_ADDITIONAL_INTEREST_LIST_SUCCESS, { data, totalRecords });
export const fetchBrokerageAdditionalInterestFailed = () =>
  action(FETCH_ADDITIONAL_INTEREST_LIST_FAILED);

export const fetchBrokerageAdditionalIntrestAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBrokerageAdditionalInterestProgress());

      const res = await api.get(
        `/insurance/get-additional-interest?status=ACTIVE`
      );
      const data: IAdditionalInterest[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchBrokerageAdditionalInterestSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchBrokerageAdditionalInterestFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };