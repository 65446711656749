/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { RightPanel } from "../../RightPanel";
import { useDispatchWrapper } from "../../../hooks";
import { useFormik } from "formik";
import {
  IEmailComposeDialog,
} from "./EmailCompose.types";
import { sendEmail } from "../../../redux/common/commonActions";
import { Button, Grid } from "@mui/material";
import { ControlledCustomSelect, CustomFormLabel, CustomTextField } from "../../formsComponents";
import { QuillEditor } from "../../QuillEditor/QuillEditor";
import { IMail } from "../../../redux/common/common.types";
import { api } from "../../../api/api";
import { fetchTemplateListAllTempsAsync } from "../../../redux/templates/templateActions";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../redux/initialStoreState";
import { ICreateTemplate } from "../../../redux/templates/templates.types";
import { useAuth } from "../../../contexts/AuthProvider/AuthProvider";

const INITIAL_MAIL_PAYLOAD: IMail = {
  emails: [],
  subject: "",
  body: "",
  templateName: null,
  objectVariables: {},
  bcc: [],
  cc: [],
  reply_to: {
    email: "",
    name: "",
  },
}

export const EmailComposeDialog: React.FC<IEmailComposeDialog> = (props) => {
  const { toEmail, open, onClose,id } = props;
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatchWrapper();
  const [selectedTemplate, setSelectedTemplate] = React.useState("");
  const templates = useSelector((storeState: IStoreState)=> storeState.templates.list);
  const {user} = useAuth();
  const { values, handleChange, handleSubmit, setFieldValue, setValues } = useFormik({
    initialValues: {
      ...INITIAL_MAIL_PAYLOAD,
      emails: toEmail ? [toEmail]: [],
      reply_to: {
        email: user.email,
        name: user.name
      }
    },
    onSubmit: async (values) => {
      setLoading(true);
      dispatch(
        sendEmail(values, (isSucess) => {
          if (isSucess) {
            onClose();
          }
          setLoading(false);
        })
      );
    },
  });

  const handleEmailReplyTo = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValues({
      ...values,
      reply_to: {
       email: e.target.value,
       name: "",
      }
    })
  }

  React.useEffect(()=>{

      dispatch(fetchTemplateListAllTempsAsync())
   
  },[])

  React.useEffect(()=>{
    const fetchTemplate = async(temp: ICreateTemplate) =>{
      try {
        const res = await api.get(`/templates/get-templates?templates_id=${temp.templates_id}`);
       const list:ICreateTemplate[] = res.data.data;
       if(list.length >0){
        setFieldValue("body", list[0].body)
       }
      } catch (err) {
        console.log(err);
      }
    }
    if(selectedTemplate){
      const template = templates.find(x=>x.template_name === selectedTemplate);
      if(template){
        fetchTemplate(template);
      }
       
    }
  }, [selectedTemplate])

  return (
    <RightPanel
      open={open}
      heading="Send Message as Email"
      subHeading="Connecting Through Email: Delivering Messages to Specified Email"
      onClose={onClose}
      isWrappedWithForm
      onFormSubmit={handleSubmit}
      actionButtons={() => {
        return (
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Button
                variant="contained"
                type="submit"
                disabled={loading}
                fullWidth
              >
                Send
              </Button>
            </Grid>
            <Grid item xs={12} md={4}>
              <Button variant="contained" fullWidth onClick={onClose}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        );
      }}
    >
      <Grid container>
        <Grid item xs={12} md={12}>
          <CustomFormLabel>To</CustomFormLabel>

          <CustomTextField
            disabled={toEmail ? true : false}
            fullWidth
            name="emails[0]"
            value={values.emails[0]}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <CustomFormLabel>Reply</CustomFormLabel>
          <CustomTextField
            name="reply_to.email"
            fullWidth
            value={values.reply_to ? values.reply_to.email : ''}
            onChange={handleEmailReplyTo}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <CustomFormLabel>Subject</CustomFormLabel>
          <CustomTextField
            name="subject"
            fullWidth
            value={values.subject}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={12}>
        <CustomFormLabel>Template</CustomFormLabel>
        <ControlledCustomSelect
                fullWidth
                value={selectedTemplate}
                name="sub_mobules"
                onChange={(e)=> setSelectedTemplate(e.target.value as string)}
                placeholder="Choose Template"
                displayEmpty
                options={templates.map((item) => {
                  return { label: item.template_name, value: item.template_name };
                })}
              ></ControlledCustomSelect>
          </Grid>
   
        <Grid item xs={12} md={12}>
          <CustomFormLabel>Message</CustomFormLabel>

          <QuillEditor
            value={values.body}
            onChange={(html) => setFieldValue("body", html)}
          />
        </Grid>
      </Grid>
    </RightPanel>
  );
};
