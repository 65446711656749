import { DOCU_SIGN_FIELD_TYPES } from "../../components/PdfViewer/enums";
import { LoadState } from "../../constants/enums";

export interface IG4SignDocument {
  document_code: string | null;
  customer_id: number | null;
  customer_policy_id: string | null;
  customer_name: string | null;
  policy_number: string | null;
  title: string;
  attachment: string;
  status: string;
  preview_attachment?: string;
  signed_document_url: string | null;
}

export interface IG4SignDocumentRecipient {
  document_code: string;
  email: string;
  signer_name: string | null;
  expired: string | null;

  status: "ACTIVE" | "INACTIVE";
}

export interface IG4SignDocumentRecipientGet {
  recipient_code: string;
  document_code: string;
  email: string;
  expired: string | null;
  status: "ACTIVE" | "INACTIVE";
  auth_key: string;
  browser: string | null;
  create_ts: string;
  created_by_id: number;
  insert_ts: string;
  latitude: string | null;
  longitude: string | null;
  read_status: any;
  send_status: string;
  sign_recipient_id: number;
  signed_at: string | null;
  signed_status: string;
  signer_name: string;
  user_ip: string | null;
}

export interface IG4SignEditior {
  field_code: string | null;
  document_code: string;
  recipient_code: string;
  field_type: DOCU_SIGN_FIELD_TYPES;
  page_no: number;
  position_x: number;
  position_y: number;
  customer_text: string | null;
  isNewPlaceholder?: boolean;
}

export interface IG4SignState {
  list: IG4SignDocument[];
  loading: LoadState;
  document: IG4SignDocument;
  documentLoading: LoadState;
  totalRecords: number;
 
  errorMessage: string | null;
  recipientList: IG4SignDocumentRecipientGet[];
  recipientLoading: LoadState;
  editior: {
    placeholders: IG4SignEditior[];
    loading: LoadState;
    documentCode: string | null;
  };
}

export const initialG4SignDocumentState: IG4SignState = {
  list: [],
  totalRecords: 0,
  loading: LoadState.NotLoaded,
  document: {
    document_code: null,
    customer_id: null,
    customer_policy_id: null,
    title: "",
    attachment: "",
    status: "ACTIVE",
    customer_name: null,
    policy_number: null,
    signed_document_url: null,
  },
  documentLoading: LoadState.NotLoaded,
  errorMessage: null,
  recipientList: [],
  recipientLoading: LoadState.NotLoaded,
  editior: {
    documentCode: null,
    placeholders: [],
    loading: LoadState.NotLoaded,
  },
};
