import React from "react";
import { IAddRecipientDialogProps } from "./AddRecipientDialog.types";
import { useFormik } from "formik";
import { Dialog } from "../../../../components/Dialogs/Dialog";
import { Grid, Typography } from "@mui/material";
import {
  CustomFormLabel,
  CustomTextField,
} from "../../../../components/formsComponents";
import { useDispatchWrapper } from "../../../../hooks";
import { upsertG4Recipient } from "../../../../redux/g4SignDocuments/g4SignDocumentsAction";

export const AddRecipientDialog: React.FC<IAddRecipientDialogProps> = (
  props
) => {
  const { open, data, onClose, onComplete } = props;
  const dispatch = useDispatchWrapper();
  const [saveLoading, setSaveLoading] = React.useState(false);

  const { values, errors, handleChange, handleSubmit, setValues } = useFormik({
    initialValues: data,
    validate: (values) => {
      const errors: any = {};
      if(!values.signer_name){
        errors.signer_name = "*Signer name is requried!";
      }
      else if(!values.email){
        errors.email = "*Email is requried!";
      }
      return errors;
    },
    onSubmit: (values) => {
        setSaveLoading(true);
        dispatch(upsertG4Recipient(values, (isSuccess)=>{
            if(isSuccess){
               onComplete();
            }
            setSaveLoading(false);
        }))
    },
  });

  return (
    <>
      <Dialog
        title="Recipient"
        open={open}
        onClose={onClose}
        size="sm"
        contentWrappedWithForm={{
          onSubmit: handleSubmit,
        }}
        actions={[
          {
            label: "Close",
            type: "button",
            onClick: onClose,
            variant: "contained",
          },
          { label: "Save", type: "submit", variant: "contained", disabled: saveLoading },
        ]}
      >
        {" "}
        <Typography variant="body1" fontSize={"1rem"} fontWeight={500}>
          Who needs to sign?
        </Typography>
        <Grid container spacing={2} mt={0.1}>
          <Grid item xs={12}>
            <CustomFormLabel>Signer Name</CustomFormLabel>
            <CustomTextField
              fullWidth
              id="signer_name"
              value={values.signer_name}
              onChange={handleChange}
              placeholder="Signer's Name"
              error={errors.signer_name ? true : false}
              helperText={errors.signer_name}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomFormLabel>Email</CustomFormLabel>
            <CustomTextField
              fullWidth
              id="email"
              value={values.email}
              onChange={handleChange}
              placeholder="email@example.com"
              error={errors.email ? true : false}
              helperText={errors.email}
            />
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};
