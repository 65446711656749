import React from "react";
import { api } from "../api/api";
import { IPolicy } from "../views/Policies/Policies.types";
import { useDispatchWrapper } from "./useDispatch";
import { showMessage } from "../redux/messages/messagesActions";

export const usePoliciesByCustomer = () => {
  const [policiesList, setPolicies] = React.useState<IPolicy[]>([]);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatchWrapper();

  const fetchPoliciesByCustomerId = async (customerId: number) => {
    try {
      setLoading(true);
      const res = await api.get(
        `/policy/get-all-customer-policy-details?customer_id=${customerId}`
      );
      const policiesList: IPolicy[] = res.data.data || [];
      
      setPolicies(policiesList.filter(x=>x.policy_number !== null));
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return {
    policiesList,
    fetchPoliciesByCustomerId,
    policiesLoading: loading,
  };
};
