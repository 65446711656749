export enum DOCU_SIGN_FIELD_TYPES {
  SIGNATURE = "SIGNATURE",
  FREE_SIGNATURE = "FREE_SIGNATURE",
  FULL_NAME ="FULL_NAME",
  COMPANY = "COMPANY",
  TEXT="TEXT",
  DATE="DATE",
  NONE = "NONE",
}


export enum DOCU_SIGN_FIELD_LABELS {
  SIGNATURE = "Signature",
  FREE_SIGNATURE = "Initial",
  FULL_NAME ="Full_Name",
  COMPANY="Company",
  TEXT="TEXT",
  DATE="DATE",
  NONE = "NONE",
}