import React from "react";
import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import { Dialog } from "../../../components/Dialogs/Dialog";
import { StandardCellList } from "../../../components/Standards";
import { IUserProfile } from "../../../redux/UserProfileList/userProfile.types";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import profilecover from "../../../assets/user-1.jpg";

interface IViewProfileDialogProps {
  open: boolean;
  onClose: () => void;
  information: IUserProfile;
}

export const ViewProfileDialog: React.FC<IViewProfileDialogProps> = (props) => {
  const { open, onClose, information } = props;
  const [profilePhoto, setProfilePhoto] = React.useState<string | null>(null);
  return (
    <Dialog
      open={open}
      title="View Profile"
      onClose={onClose}
      size="md"
      actions={[
        {
          type: "button",
          label: "Close",
          variant: "contained",
          onClick: onClose,
        },
      ]}
    >
      {/* <Grid container spacing={1}>
        <Grid item xs={12} lg={12}>
          <StandardCellList
            heading="About Customer"
            colSize={3}
            list={[
              {
                heading: "First Name",
                data: {
                  type: "text",
                  value: information.first_name,
                },
              },
              {
                heading: "Last Name",
                data: {
                  type: "text",
                  value: information.last_name,
                },
              },
              {
                heading: "User Code",
                data: {
                  type: "text",
                  value: information.user_code,
                },
              },
              {
                heading: "Job Title",
                data: { type: "text", value: information.job_title },
              },

              {
                heading: "Assigned Phone Numer",
                data: {
                  type: "text",
                  value: information.assigned_phone_number,
                },
              },
              {
                heading: "Assigned Email",
                data: {
                  type: "text",
                  value: information.email,
                },
              },
              {
                heading: "Branch",
                data: {
                  type: "text",
                  value: information.branch,
                },
              },
            ]}
          />
        </Grid>
      </Grid> */}

      <Grid item xs={12} lg={2.8} sx={{ mr: 3 }}>
        {/* <StandadCard sx={{ mt: 2 }}> */}
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
          marginTop={1}
        >
          <Box>
            {profilePhoto && (
              <img
                src={profilePhoto}
                alt="log not found"
                style={{
                  height: "150px",
                  width: "150px",
                  borderRadius: "100px",
                }}
              />
            )}
            {!profilePhoto && (
              <img
                src={profilecover}
                alt="log not found"
                style={{ height: "150px", borderRadius: "100px" }}
              />
            )}
          </Box>

          <Stack direction="column" sx={{ mt: 1 }}>
            <Typography
              variant="h3"
              color="primary.main"
              textAlign={"center"}
              fontWeight={600}
            >
              {information.username}
            </Typography>
            <Typography variant="h5">{information.email}</Typography>
          </Stack>

          <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
            <Typography variant="h5">Code : </Typography>
            <Typography variant="h5" fontWeight={"bold"}>
              {information.user_code}
            </Typography>
          </Stack>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Grid container spacing={1}>
          <Grid item xs={12} md={3.5}>
            <Stack direction="column" sx={{ mt: 1 }}>
              <Typography variant="h5">Assigned Phone Number</Typography>
              <Typography variant="h5" color="grey">
                {information.assigned_phone_number}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={5}>
            <Stack direction="column" sx={{ mt: 1 }}>
              <Typography variant="h5">Assigned Email</Typography>
              <Typography variant="h5" color="grey">
                {information.email}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={3.5}>
            <Stack direction="column" sx={{ mt: 1 }}>
              <Typography variant="h5">MGA/AGA</Typography>
              <Typography variant="h5" color="grey">
                {information.branch}
              </Typography>
            </Stack>
          </Grid>
        </Grid>

        <Stack direction="row" spacing={2} sx={{ mt: 4 }}>
          <Button variant="contained">
            <FacebookIcon />
          </Button>
          <Button variant="contained">
            <TwitterIcon />
          </Button>
          <Button variant="contained">
            <YouTubeIcon />
          </Button>
        </Stack>
        {/* </StandadCard> */}
      </Grid>
    </Dialog>
  );
};
