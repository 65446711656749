import { LoadState } from "../../../constants/enums";

export interface IAccountingBankAccountState {
  list: IAccountingBankAccount[];
  listLoading: LoadState;
  data: IAccountingBankAccount;
  loading: LoadState;
  error: string | null;
}

export interface IAccountingBankAccount {
  bank_id: string | null;
  bank_account_code: string | null;
  bank_account_name: string | null;
  account_number: string | null;
  gl_account_code: string | null;
  branch_code: string | null;
  last_used_cheque_number: string | null;
  last_used: string | null;
  last_used_receipts_batch_number: string | null;
  original_opening_balance: string | null;
  original_opening_date: string | null;
  current_month_opening_balance: string | null;
  current_month_opening_date: string | null;
  current_balance: string | null;
  current_balance_date: string | null;
  status: string | null;
}

export const initialAccountingBankAccountState: IAccountingBankAccountState = {
  list: [],
  listLoading: LoadState.NotLoaded,
  data: {
    bank_id: null,
    bank_account_code: null,
    bank_account_name: null,
    account_number: null,
    gl_account_code: null,
    branch_code: null,
    last_used_cheque_number: null,
    last_used: null,
    last_used_receipts_batch_number: null,
    original_opening_balance: null,
    original_opening_date: null,
    current_month_opening_balance: null,
    current_month_opening_date: null,
    current_balance: null,
    current_balance_date: null,
    status: "Active",
  },
  loading: LoadState.NotLoaded,
  error: null,
};
