import { Box, Grid, InputBase, Typography } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import { ITableCommandBarProps } from "./interfaces/ITableCommandBarProps";
import React from "react";
import { useSearch } from "../../../../hooks";
import { TableSearchBar } from "../TableSearchBar/TableSearhBar";
import "../../DataTable.css";
import { borderColor } from "@mui/system";
import Card from "@mui/material/Card";

export const TableCommandBar: React.FC<ITableCommandBarProps> = (props) => {
  const { leftItems, rightItems } = props.tableCommandBarProps;
  const { onRowFilteredBySearch,columns, items } = props;

  const renderLeftItems = React.useMemo(() => {
    const plugins = leftItems.plugins;
    const customPlugins = leftItems.customPlugins;
    let leftNodes: React.ReactNode[] = [];
    if (plugins) {
      leftNodes = Object.keys(plugins).map((key) => {
        switch (key) {
          case "leftText":
            const text = plugins.leftText || "";

            return (
              <Box sx={{ mr: 1,mb: 1 }} display="flex" justifyContent={"flex-start"}>
                <Typography variant="h4" fontWeight="600">
                  {text}
                </Typography>
              </Box>
            );

          default:
            return <></>;
        }
      });
    }
    if (customPlugins) {
      customPlugins.forEach((customPlugin) => {
        leftNodes.push(
          <Box
            sx={{ mr: 1,mb:1,p:'9px 0px' }}
            display="flex"
            justifyContent={"flex-start"}
          >
            {customPlugin.onRender(columns,items)}
          </Box>
        );
      });
    }

    return leftNodes;
  }, [leftItems,columns,items]);

  const renderRightItems = React.useMemo(() => {
    const plugins = rightItems.plugins;
    const customPlugins = rightItems.customPlugins;
    let rightNodes: React.ReactNode[] = [];

    if (plugins) {
      Object.keys(plugins).forEach((key) => {
        switch (key) {
          case "searchField":
            const searchField = plugins.searchField;
            if (searchField) {
              rightNodes.push(
                <Box
                  sx={{ ml: 1, mb: 1}}
                  display="flex"
                  justifyContent={"flex-start"}
                >
                  <TableSearchBar
                    items={searchField.items}
                    searchKeys={searchField.searchKeys}
                    onRowFilteredBySearch={onRowFilteredBySearch}
                  />
                </Box>
              );
            }
            break;
        }
      });
    }
    if (customPlugins) {
      customPlugins.forEach((customPlugin) => {
        rightNodes.push(
          <Box sx={{ ml: 1, mb: 1 }} display="flex" justifyContent={"flex-start"}>
            {customPlugin.onRender(columns,items)}
          </Box>
        );
      });
    }

    return rightNodes;
  }, [rightItems,columns,items]);

  return (
    <Card
      sx={{
        display: "flex",
        flexWrap: "wrap",
        marginBottom: 0,
        border: 0,
        borderRadius: '5px', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 2px 3px',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        justifyContent: 'space-between'
      }}
    >
      <Box
        sx={{ mb: 1,display: 'flex',justifyContent: { xs: "center", md: "flex-start" }, flexWrap: { xs: "wrap", md: "unset" } }}
      >
        {renderLeftItems}
      </Box>
      <Box
        sx={{mb: 1,display: 'flex',justifyContent: { xs: "center",lg: "flex-end" , }, flexWrap: { xs: "wrap", md: "unset" }}}
      >
        {renderRightItems}
      </Box>
    </Card>
  );
};
