import { IHrRequestFormDialog } from "../../../../redux/allApplications/allApplications.types";

export interface IAddRequestFormDialogProps {
    open: boolean;
    onClose: () => void;
    data: IHrRequestFormDialog;
}

export const INITIAL_STATE_REQUEST_FORM = {
    branch_name: "",
    type: "ADD USER",
    role_id: "35",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address: "",
    unit_or_suite: "",
    city: "",
    province_or_state: "",
    postal_code: "",
    country: "",
    details: "",
    status: "Active",
    server: "Y",
    powerbroker: "Y",
    portal: "Y",
    applied_rating_services: "Y",
    insurer_portal_ids: "Y",
    notify_ribo: "Y",
    order_business_card: "Y",
}