import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { action } from "typesafe-actions";
import { IPolicyNote } from "./notes.types";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { api } from "../../api/api";
import { uploadFile } from "../../helpers";
import { IDate } from "../../components/Table/hooks/useDateFilter";

export const FETCH_NOTE_LIST_PROGRESS = "FETCH_NOTE_LIST_PROGRESS";
export const FETCH_NOTE_LIST_SUCCESS = "FETCH_NOTE_LIST_SUCCESS";
export const FETCH_NOTE_LIST_FAILED = "FETCH_NOTE_LIST_FAILED";

export const fetchNoteListProgress = () => action(FETCH_NOTE_LIST_PROGRESS);
export const fetchNoteListSuccess = (data: IPolicyNote[]) =>
  action(FETCH_NOTE_LIST_SUCCESS, { data });
export const fetchNoteListFailed = () => action(FETCH_NOTE_LIST_FAILED);

export const fetchNoteListFromPolicyAsync =
  (customerPolicyId?: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchNoteListProgress());
      let finalUrl = `/general/get-notes`;
      if (customerPolicyId) {
        finalUrl = finalUrl + `?customer_policy_id=${customerPolicyId}`;
      }
      const res = await api.get(finalUrl);
      const data: IPolicyNote[] = res.data.data;
      dispatch(fetchNoteListSuccess(data));
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const fetchNoteListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    status: string,
    date: IDate,
    searchValue: string,
    searchType: string[]
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchNoteListProgress());
      let url = "";
      if (status !== "-1") {
        url = "&status=" + status;
      }

      let finalUrl = `/general/get-notes?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}${url}`;
      if (searchType.length > 0 && searchValue) {
        finalUrl = `/general/get-notes?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&column=${[
          searchType,
        ]}&value=${searchValue}`;
      }
      const res = await api.get(finalUrl);
      const data: IPolicyNote[] = res.data.data;
      dispatch(fetchNoteListSuccess(data));
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_NOTE_PROGRESS = "FETCH_NOTE_PROGRESS";
export const FETCH_NOTE_SUCCESS = "FETCH_NOTE_SUCCESS";
export const FETCH_NOTE_FAILED = "FETCH_NOTE_FAILED";

export const fetchNoteProgress = () => action(FETCH_NOTE_PROGRESS);
export const fetchNoteSuccess = (data: IPolicyNote) =>
  action(FETCH_NOTE_SUCCESS, { data: data });
export const fetchNoteFailed = (errorMessage: string) =>
  action(FETCH_NOTE_FAILED, { errorMessage });

export const upsertNoteFromPolicyAysnc =
  (
    note: IPolicyNote,
    customerId: number,
    policy_type: string,
    file: any | null,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const asPayload = {
        customer_id: customerId,
        customer_policy_id: note.customer_policy_id,
        policy_type,
        page_name: "Notes",
      };
      const path = await uploadFile(
        file,
        "POLICY",
        note.file_upload || "",
        asPayload
      );
      await api.post("/general/upsert-notes", { ...note, file_upload: path });
      dispatch(
        showMessage({
          type: "success",
          message: "Note saved succesfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const upsertNoteAysnc =
  (
    note: IPolicyNote,
    file: any | null,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const asPayload = {
        customer_id: note.customer,
        customer_policy_id: note.customer_policy_id,
        page_name: "Notes",
      };
      const path = await uploadFile(
        file,
        "POLICY",
        note.file_upload || "",
        asPayload
      );
      await api.post("/general/upsert-notes", { ...note, file_upload: path });
      dispatch(
        showMessage({
          type: "success",
          message: "Note saved succesfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchNoteAsync =
  (
    customerPolicyId: number,
    serialNumber: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchNoteProgress());

      const res = await api.get(
        `/general/get-notes?customer_policy_id=${customerPolicyId}&value=${serialNumber}&column=${[
          "customer_policy_id",
          "incremental_number",
        ]}`
      );
      const data = res.data.data;
      if (data.length > 0) {
        dispatch(fetchNoteSuccess(data[0]));
      } else {
        dispatch(
          fetchNoteFailed(
            "Unfortunately, there are no records available at the moment."
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchNoteFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_NOTE_STATE = "CLEAR_NOTE_STATE";
export const CLEAR_NOTE = "CLEAR_NOTE";

export const clearNotesState = () => action(CLEAR_NOTE_STATE);
export const clearNote = () => action(CLEAR_NOTE);
