import { LoadState } from "../../../constants/enums";


export interface IAccountingRecievableTrustState {
    data: IRecievableTrust;
    loading: LoadState;
    error: string | null;
    list: IRecievableTrust[];
    listLoading: LoadState;
}

export interface IRecievableTrust {
    customer_id: number | null;
    customer_policy_id: string | null;
    payment_id: string | null;
    customer: string | null;
    policy_number: string | null;
    branch: string | null;
    agency_or_direct_bill: string | null;
    finance: string | null;
    bank_id: string | null;
    bank: string | null;
    tax_1_percentage: string | null;
    tax_1_amount: string | null;
    tax_2_percentage: string | null;
    tax_2_amount: string | null;
    commission_percentage: string | null;
    status: string;
}

export const initialAccountingRecievableTrustState: IAccountingRecievableTrustState = {
    data: {
        customer_id: null,
        customer_policy_id: null,
        payment_id: null,
        customer: null,
        policy_number: null,
        branch: null,
        agency_or_direct_bill: null,
        finance: null,
        bank_id: null,
        bank: null,
        tax_1_percentage: null,
        tax_1_amount: null,
        tax_2_percentage: null,
        tax_2_amount: null,
        commission_percentage: null,
        status: "ACTIVE"
    },
    loading: LoadState.NotLoaded,
    list: [],
    listLoading: LoadState.NotLoaded,
    error: null,
}