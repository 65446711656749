import { Box, Button, Grid, MenuItem, Typography } from "@mui/material";
import React from "react";
import { SignatureStatusWidget } from "../components/SignatureStatusWidget/SignatureStatusWidget";
import { StandadCard } from "../../../components/Cards";
import { FileUploadV2 } from "../../../components/FileUpload/FileUploadV2";
import { CustomSelect } from "../../../components/formsComponents";
import { DateFilterDropdown } from "../../../components/Table/components/DateFilterDropdown/DateFilterDropdown";
import { RefreshPlugin, SearchPlugin } from "../../../components/Table/plugins";
import {
  IDataTableProps,
  RenderType,
} from "../../../components/Table/interfaces/IDataTableProps";
import { StandardTableActions } from "../../../components/StandardTableActions/StandardTableActions";
import { useDateFilter } from "../../../components/Table/hooks/useDateFilter";
import { CustomDatePicker } from "../../../components/formsComponents/CustomDatePicker";
import { DataTable } from "../../../components/Table/DataTable";
import { useAuth } from "../../../contexts/AuthProvider/AuthProvider";
import { useRoles } from "../../../security/RolesProvider/RolesProvider";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../redux/initialStoreState";
import { useDispatchWrapper, useRecordCounts } from "../../../hooks";
import { fetchG4SignDocListAsync } from "../../../redux/g4SignDocuments/g4SignDocumentsAction";
import { StatusRenderer } from "../../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import { LoadState } from "../../../constants/enums";
import { IG4SignDocument } from "../../../redux/g4SignDocuments/g4SignDocuments.types";
import {
  clearRecordCounts,
  fetchRecordCountsAsync,
} from "../../../redux/common/commonActions";
import { IconicMultiColumnCard } from "../../../components/iconicMultiColumnCard/IconicMultiColumnCard";
import { capitalizeWords, getFileExtensionFromUrl } from "../../../helpers";
import { api } from "../../../api/api";
import { downLoadFile, previewFile } from "../../../components/FileUpload/utils";
import LoadingDialog from "../../../components/Dialogs/LoadingDialog";
import { saveLoaderCompleted, saveLoaderProgress } from "../../../redux/messages/messagesActions";
import { CustomLink } from "../../../components/CustomLink/CustomLink";

export const Documents: React.FC = () => {
  const { firstName, lastName } = useRoles();

  const { list, totalRecords, loading } = useSelector(
    (storeState: IStoreState) => storeState.g4Sign.documents
  );
  const dispatch = useDispatchWrapper();
  const { date, type, handleDateSelectChange, dateDropdownList, setDate } =
    useDateFilter({ defaultType: "lastWeek" });
  const { list: recordsCounts, loading: recordCountsLoading } =
    useRecordCounts();
  const [tablePagination, setTablePagination] = React.useState({
    pageNumber: 1,
    rowsInPerPage: 25,
  });
  const [status, setStatus] = React.useState<any | "-1">("Pending_for_signature");
  const [searchType, setSearchType] = React.useState<string[]>(["customer_name"]);
  const [search, setSearch] = React.useState<string>("");
  const [downloading, setDownloading] = React.useState(false);
  const navigate = useNavigate();

  const redirectToUpsetDocument = (documentCode?: string) => {
    if (documentCode) {
      navigate(`/g4sign/documents/manage/${documentCode}`);
    } else {
      navigate(`/g4sign/documents/manage`);
    }
  };

  const documentsTableProps: IDataTableProps = {
    isPagination: true,
    totalRecords: totalRecords,
    rowsPerPageOptions: tablePagination.rowsInPerPage,
    isDataLoading: loading === LoadState.InProgress,
    selectionMode: "none",
    uniqueRowKeyName: "id",
    tableCommandBarProps: {
      leftItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={status}
                onChange={(e: any) => setStatus(e.target.value as "ACTIVE")}
              >
                <MenuItem value="-1">All Status</MenuItem>
                <MenuItem value="Draft">Draft</MenuItem>
                <MenuItem value="Pending_for_signature">Pending</MenuItem>
                <MenuItem value="Completed">Completed</MenuItem>
                {[].map((item: any) => {
                  return (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </CustomSelect>
            ),
          },

          {
            key: "byDate",
            onRender: () => (
              <DateFilterDropdown
                value={type}
                options={dateDropdownList}
                onChange={handleDateSelectChange}
              />
            ),
          },
          {
            key: "fromDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "150px" }}
                  value={date.fromDate}
                  onChange={(newValue) => {
                    setDate({ ...date, fromDate: newValue });
                  }}
                />
              ) : (
                <></>
              ),
          },
          {
            key: "toDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "150px" }}
                  value={date.toDate}
                  onChange={(newValue) => {
                    setDate({ ...date, toDate: newValue });
                  }}
                />
              ) : (
                <></>
              ),
          },

          {
            key: "refresh",
            onRender: () =><RefreshPlugin onClick=
            {fetchList} />,
          },
        ],
      },
      rightItems: {
        plugins: {},
        customPlugins: [
          {
            key: "search",
            onRender: () => {
              return (
                <SearchPlugin
                  selectedDropdownValue={searchType}
                  dropdownOptions={[
                    { label: "Customer Name", value: "customer_name" },
                    { label: "Document Code", value: "document_code" },
                    { label: "Title", value: "title" },
                    { label: "Policy Number", value: "policy_number" },
                  ]}
                  onDropdownChange={(value) => setSearchType(value)}
                  onChange={(newValue) => {
                    setSearch(newValue);
                  }}
                />
              );
            },
          },
        ],
      },
    },

    initialSortConfig: {
      insert_ts: {
        direction: "desc",
        filedName: "insert_ts",
      },
    },
    columns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        isFirstColumnSticky: true,
        onRowCellRender: (value, row: IG4SignDocument) => {
          return (
            <StandardTableActions
            onDownloadPreview={
              row.signed_document_url
                  ? async () => {
                      try {
                        dispatch(saveLoaderProgress());
                        await previewFile(row.signed_document_url);
                      } catch (err) {
                        console.log(err);
                      } finally {
                        dispatch(saveLoaderCompleted());
                      }
                    }
                  : undefined
            }
              onDownLoadClick={
                row.signed_document_url
                  ? async () => {
                      try {
                        dispatch(saveLoaderProgress());
                        await downLoadFile(row.signed_document_url);
                      } catch (err) {
                        console.log(err);
                      } finally {
                        dispatch(saveLoaderCompleted());
                      }
                    }
                  : undefined
              }
              onEditClick={() => {
                navigate(`/g4sign/documents/manage/${row.document_code}`);
              }}
            />
          );
        },
      },
      {
        key: "document_code",
        headerName: "Document Code",
        fieldName: "document_code",
        enableSorting: true,
        renderType: RenderType.CHIP_WARNING,
      },
      {
        key: "title",
        headerName: "Title",
        fieldName: "title",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row)=>{
           return  <CustomLink
           to={`/g4sign/documents/manage/${row.document_code}`}
           label={row.title}
         />
        }
      },
      {
        key: "customer_name",
        headerName: "Customer Name",
        fieldName: "customer_name",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row)=>{
          if(row.customer_name){
          return  <CustomLink
          to={`/g4sign/documents/manage/${row.document_code}`}
          label={row.customer_name}/>
          }
          return "--";
        }
      },
      {
        key: "policy_number",
        headerName: "Policy Number",
        fieldName: "policy_number",
        enableSorting: true,
        renderType: RenderType.TEXT_DARK,
      },

      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row) => {
          return <StatusRenderer status={row.status} />;
        },
      },

      {
        key: "insert_ts",
        headerName: "Date Created",
        fieldName: "insert_ts",
        enableSorting: true,
        renderType: RenderType.DATE_TIME,
        exportCellWidth: 25,
      },
    ],

    items: list,

    onPageChange: (newPageNumber: number) => {
      setTablePagination({ ...tablePagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setTablePagination({
        pageNumber: pageNumber,
        rowsInPerPage: rowsPerPage,
      });
    },
  };

  React.useEffect(() => {
    dispatch(
      fetchRecordCountsAsync(
        "latest_sign_document",
        "status",
        date.fromDate,
        date.toDate
      )
    );
    return () => {
      dispatch(clearRecordCounts());
    };
  }, [date.fromDate, date.toDate]);

  const fetchList = () => {
    dispatch(
      fetchG4SignDocListAsync(
        tablePagination.pageNumber,
        tablePagination.rowsInPerPage,
        status,
        date,
        search,
        searchType
      )
    );
  }

  React.useEffect(() => {
    fetchList();
  }, [tablePagination, status, date, search]);

  return (
    <>
      <Typography variant="h1">Hi {` ${firstName}`}!</Typography>

      <Box sx={{ mt: 5 }}>
        <IconicMultiColumnCard
          loading={recordCountsLoading !== LoadState.Loaded}
          columns={recordsCounts.map((item) => {
            return {
              heading:
                item.name === "PENDING_FOR_SIGNATURE"
                  ? "Pending for signature"
                  : capitalizeWords(item.name),
              value: item.cnt,
              colSize: item.name.length > 10 ? 3 : 2,
            };
          })}
        />
      </Box>

      {/* <StandadCard>
        <Grid container spacing={2.5}>
          <Grid item xs={12}>

   
          </Grid>
          <Grid item xs={12} md={3}>
            <SignatureStatusWidget count={5} status="Pending" />
          </Grid>
          <Grid item xs={12} md={3}>
            <SignatureStatusWidget count={5} status="Completed" />
          </Grid>
          <Grid item xs={12} md={3}>
            <SignatureStatusWidget count={20} status="Draft" />
          </Grid>
          <Grid item xs={12} md={3}>
            <SignatureStatusWidget
              count={11}
              status="Pending"
              hideRightBorder
            />
          </Grid>
        </Grid>
      </StandadCard> */}
      {/* <Box mt={5}>
        <FileUploadV2
          value={null}
          onChange={(data) => setFile(file)}
          onDelete={() => setFile(null)}
          actionButton={{
            text: "Get Signed",
            onClick: ()=> setOpen(true)
          }}
        />
      </Box> */}
    
      <Box mt={5}>
        <StandadCard
          rightHeading={
            <Button
              variant="contained"
              onClick={() => redirectToUpsetDocument()}
            >
              Create Document
            </Button>
          }
        >
          <DataTable {...documentsTableProps} />
          
        </StandadCard>
      </Box>
   
    </>
  );
};
