import { IFileUpload } from "../../components/FileUpload/FileUpload.type";
import { LoadState } from "../../constants/enums";

export interface IBrokerContactsState {
  data: IBrokerContact;
  loading: LoadState;
  error: string | null;
  list: IBrokerContact[];
  totalRecords: number;
  listLoading: LoadState;
}

export interface IBrokerContact {
  broker_contracts_code: string;
  from: string | null;
  to: string | null;
  insurer: string | null;
  line_of_business: string[] | null;
  type: string | null;
  notes: string | null;
  file_upload: IFileUpload[] | null;
  status: string;
}

export const initialBrokerContactState: IBrokerContactsState = {
  data: {
    broker_contracts_code: "string",
    from: null,
    to: null,
    insurer: null,
    line_of_business: null,
    type: null,
    notes: null,
    file_upload: null,
    status: "ACTIVE",
  },
  loading: LoadState.NotLoaded,
  list: [],
  listLoading: LoadState.NotLoaded,
  totalRecords: 0,
  error: null,
};
