import { LoadState } from "../../../constants/enums";

export interface IChartsOfAccountsState {
  list: IChartsOfAccounts[];
  listLoading: LoadState;
  data: IChartsOfAccounts;
  loading: LoadState;
  error: string;
}

export interface IChartsOfAccounts {
  incremental_number: number | null;
  prefix: string | null;
  gl_code: string | null;
  gl_code_name: string | null;
  description: string | null;
  report_heading_account: string | null;
  balance_sheet_or_income_statement_account: string | null;
  balance_sheet_and_operating_statement_combine_accounts: string | null;
  general_ledger_details_report_consolidated_or_detailed: string | null;
  custom_cash_disbursement_report_column_number: number | null;
  account_type: string | null;
  status: string;
}

export const initialChartsOfAccountsState: IChartsOfAccountsState = {
    list: [],
    listLoading: LoadState.NotLoaded,
    data: {
        incremental_number: null,
        prefix: null,
        gl_code: null,
        gl_code_name: null,
        description: null,
        report_heading_account: null,
        balance_sheet_or_income_statement_account: null,
        balance_sheet_and_operating_statement_combine_accounts: null,
        general_ledger_details_report_consolidated_or_detailed: null,
        custom_cash_disbursement_report_column_number: null,
        account_type: null,
        status: "",
    },
    loading: LoadState.NotLoaded,
    error: "",
}
