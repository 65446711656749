import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { StandardCellList } from "../../../../components/Standards";
import React from "react";
import { useDispatchWrapper } from "../../../../hooks";
import { IStoreState } from "../../../../redux/initialStoreState";
import { useSelector } from "react-redux";
import { formatDate, formatTime } from "../../../../helpers";
import { PageLoader } from "../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../constants/enums";
import { INewRemarket } from "../../../../redux/NewRemarket/NewRemarket.types";
import { fetchTaskNewRemarketAysnc } from "../../../../redux/NewRemarket/NewRemarketActions";
import { ITaskFileUploadAttachment } from "../../../../redux/tasks/task.types";
import { StandadCard } from "../../../../components/Cards";
import { FileUpload } from "../../../../components/FileUpload/FileUpload";

export const ViewNewUpload: React.FC<{
  taskCode: string;
  attachment: ITaskFileUploadAttachment[];
}> = (props) => {
  const { taskCode, attachment } = props;
  const { data, loading } = useSelector(
    (storeState: IStoreState) => storeState.NewRemarketChange.NewRemarket
  );

  const dispatch = useDispatchWrapper();
  const [taskDetails, setTaskDetails] = React.useState<INewRemarket>();

  React.useEffect(() => {
    dispatch(fetchTaskNewRemarketAysnc(taskCode));
  }, [taskCode]);

  React.useEffect(() => {
    if (data) {
      setTaskDetails(data);
    }
  }, [data]);

  return (
    <PageLoader loading={loading === LoadState.InProgress}>
      {taskDetails && (
        <Grid container spacing={1} sx={{ mt: 2 }}>
          <Grid item xs={12} lg={12}>
            <StandardCellList
              heading="Other Information"
              colSize={3}
              list={[
                {
                  heading: "Effective Date",
                  colSize: 2,
                  data: {
                    type: "text",
                    value: formatDate(
                      taskDetails.effective_date_of_change || ""
                    ),
                  },
                },
                {
                  heading: "Effective Time",
                  colSize: 2,
                  data: {
                    type: "text",
                    value: formatTime(
                      taskDetails.effective_time_of_change || ""
                    ),
                  },
                },
                {
                  heading: "Insurance Reports",
                  colSize: 3,
                  data: {
                    type: "text",
                    value: taskDetails.insurance_reports,
                  },
                },
                {
                  heading: "Quote",
                  colSize: 1,
                  data: {
                    type: "text",
                    value: taskDetails.quote_number,
                  },
                },
                {
                  heading: "CC",
                  colSize: 2,
                  data: {
                    type: "text",
                    value: taskDetails.cc,
                  },
                },
                {
                  heading: "Expiry",
                  colSize: 1.5,
                  data: {
                    type: "text",
                    value: taskDetails.expiry,
                  },
                },
                {
                  heading: "CVV",
                  colSize: 2,
                  data: {
                    type: "text",
                    value: taskDetails.cvv,
                  },
                },
                {
                  heading: "Details",
                  colSize: 10,
                  data: {
                    type: "text",
                    value: taskDetails.details,
                  },
                },
              ]}
            >
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Table>
                    <TableHead>
                      <TableCell>Checklist</TableCell>
                      <TableCell>Status</TableCell>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={taskDetails.auto_application === "Y"}
                              />
                            }
                            label="Auto Application"
                          />
                        </TableCell>
                        <TableCell>{taskDetails.auto_application_status}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={taskDetails.home_application === "Y"}
                              />
                            }
                            label="Home Application"
                          />
                        </TableCell>
                        <TableCell>{taskDetails.home_application_status}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={taskDetails.cancellation === "Y"}
                              />
                            }
                            label="Cancellation"
                          />
                        </TableCell>
                        <TableCell>{taskDetails.cancellation_status}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
              <Divider sx={{ mt: 2, mb: 2 }} />
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="body1"
                    fontSize={"1.2rem"}
                    fontWeight={600}
                  >
                    Attachments
                  </Typography>
                  {attachment.length > 0 ? (
                    attachment.map((item) => {
                      return (
                        <Stack
                          key={item.key}
                          direction={"row"}
                          justifyContent={"center"}
                          gap={2}
                          alignItems={"center"}
                        >
                          <Typography
                            variant="h4"
                            textTransform={"capitalize"}
                            width={"30%"}
                          >
                            {" "}
                            {item.fileName}{" "}
                          </Typography>
                          <FileUpload value={item.url} deleteDisabled />
                        </Stack>
                      );
                    })
                  ) : (
                    <Typography variant="body1">No attachments</Typography>
                  )}
                </Grid>
              </Grid>
            </StandardCellList>
          </Grid>
        </Grid>
      )}
    </PageLoader>
  );
};
