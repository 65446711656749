import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import { initialAccountingVendorState } from "./Vendor.types";
import { AccountingVendorActions } from ".";
import { CLEAR_ACCOUNTING_VENDOR, FETCH_ACCOUNTING_VENDOR_FAILURE, FETCH_ACCOUNTING_VENDOR_PROGRESS, FETCH_ACCOUNTING_VENDOR_SUCCESS } from "./VendorActions";

export const AccountingVendorReducer = (
  state: IStoreState["accounting"]["vendor"] = initialAccountingVendorState,
  action: AccountingVendorActions
) => {
  switch (action.type) {

    case FETCH_ACCOUNTING_VENDOR_PROGRESS: {
        const newState = produce(state, (draftState) => {
            draftState.loading = LoadState.InProgress;
        })
        return newState;
    }

    case FETCH_ACCOUNTING_VENDOR_SUCCESS: {
        const { data } = action.payload;
        const newState = produce(state, (draftState) => {
            draftState.loading = LoadState.Loaded;
            draftState.data = data;
        })
        return newState;
    }

    case FETCH_ACCOUNTING_VENDOR_FAILURE: {
        const { errorMessage } = action.payload;
        const newState = produce(state, (draftState) => {
            draftState.loading = LoadState.Failed;
            draftState.error = errorMessage;
        })
        return newState;
    }

    case CLEAR_ACCOUNTING_VENDOR: {
        const newState = produce(state, (draftState) => {
            draftState.loading = LoadState.NotLoaded;
            draftState.data = initialAccountingVendorState.data;
            draftState.error = null;
        })
        return newState;
    }

    default:
      return state;
  }
};
