import { action } from "typesafe-actions";
import {
  IBusinessAttachment,
  IClaims,
  ICustomerInfo,
  IPolicyAdmin,
  IPolicyAdminManager,
  IPremium,
  IPriorPolicy,
  initialBasicDetails,
} from "./businessPolicyBasicDetails.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { ERROR_MESSAGES, LoadState } from "../../../constants/enums";
import moment from "moment";
import { uploadFile } from "../../../helpers";

export const ADD_POLICY_ID = "ADD_POLICY_ID";
export const POLICY_BASIC_DETAILS_NOT_FOUND = "POLICY_BASIC_DETAILS_NOT_FOUND";

export const addPolicyId = () => action(ADD_POLICY_ID);

export const FETCH_CUSTOMER_INFO_PROGRESS = "FETCH_CUSTOMER_INFO_PROGRESS";
export const ADD_CUSTOMER_INFO = "ADD_CUSTOMER_INFO";
export const FETCH_CUSTOMER_INFO_FAILED = "FETCH_CUSTOMER_INFO_FAILED";

export const fetchCustomerInfoProgress = () =>
  action(FETCH_CUSTOMER_INFO_PROGRESS);

export const addCustomerInfo = (
  customerInfo: ICustomerInfo,
  customerPolicyId: number,
  isNewPolicy: boolean
) => action(ADD_CUSTOMER_INFO, { customerInfo, customerPolicyId, isNewPolicy });

export const fetchCustomerInfofailed = (errorMessage: string) =>
  action(FETCH_CUSTOMER_INFO_FAILED, { errorMessage });

export const addCustomerInfoAsync =
  (
    data: ICustomerInfo,
    onCallback: (isSuccess: boolean, policyId?: number) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.post(
        "/policy/create-policy-business-customer-info",
        {
          ...data,
          policy_id: data.sub_type,
          effective_time: data.effective_time,
        }
      );
      const customerPolicyId = res.data.data.customer_policy_id;
      dispatch(addCustomerInfo(res.data.data, customerPolicyId, false));
      dispatch(
        showMessage({
          type: "success",
          message: "Customer information saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true, customerPolicyId);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchCustomerInfoAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const isSaved =
      getState().business.basicDetails.customerInfo.loading ===
      LoadState.Loaded;

    try {
      if (!isSaved) {
        dispatch(fetchCustomerInfoProgress());
        const res = await api.get(
          `/policy/get-policy-business-customer-info?customer_policy_id=${customerPolicyId}`
        );
        const data: ICustomerInfo[] = res.data.data;
        if (data.length > 0) {
          dispatch(
            addCustomerInfo(
              {
                ...data[0],
               
              },
              customerPolicyId,
              false
            )
          );
        } else {
          dispatch(
            fetchCustomerInfofailed(
              "Oops! We couldn't find any records associated with your policy id at the moment. Please ensure that the provided information is accurate and try again."
            )
          );
        }
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      dispatch(fetchCustomerInfofailed("Something went to be wrong!"));
    }
  };

export const FETCH_PRIOR_POLICY_PROGRESS = "FETCH_PRIOR_POLICY_PROGRESS";
export const ADD_PRIOR_POLICY = "ADD_PRIOR_POLICY";
export const FETCH_PRIOR_POLICY_FAILED = "FETCH_PRIOR_POLICY_FAILED";

export const fetchPriorPolicyProgress = () =>
  action(FETCH_PRIOR_POLICY_PROGRESS);

export const addPriorPolicy = (priorPolicy: IPriorPolicy) =>
  action(ADD_PRIOR_POLICY, { priorPolicy });
export const fetchPriorPolicyFalied = () => action(FETCH_PRIOR_POLICY_FAILED);

export const addPriorPolicyInfoAsync =
  (
    data: IPriorPolicy,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/create-policy-business-prior-policies", {
        ...data,
        status: "ACTIVE",
      });
      dispatch(addPriorPolicy(data));
      dispatch(
        showMessage({
          type: "success",
          message: "Prior policy saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };
export const fetchPriorPolicyAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const isSaved =
      getState().business.basicDetails.priorPolicy.loading === LoadState.Loaded;

    try {
      if (!isSaved) {
        dispatch(fetchPriorPolicyProgress());
        const res = await api.get(
          `/policy/get-policy-business-prior-policies?customer_policy_id=${customerPolicyId}`
        );
        const data: IPriorPolicy[] = res.data.data;
        if (data.length > 0) {
          dispatch(addPriorPolicy(data[0]));
        } else {
          dispatch(addPriorPolicy(initialBasicDetails["priorPolicy"]["data"]));
        }
      }
    } catch (err: any) {
      dispatch(fetchPriorPolicyFalied());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_CLAIM_PROGRESS = "FETCH_CLAIM_PROGRESS";
export const ADD_CLAIM = "ADD_CLAIM";
export const FETCH_CLAIM_FAILED = "FETCH_CLAIM_FAILED";

export const fetchClaimProgress = () => action(FETCH_CLAIM_PROGRESS);
export const addClaim = (claim: IClaims[]) => action(ADD_CLAIM, { claim });
export const fetchClaimFailed = () => action(FETCH_CLAIM_FAILED);

export const addClaimAsync =
  (
    data: IClaims,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/create-policy-business-claims", {
        ...data,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Policy claim saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
      dispatch(fetchClaimsAsync(data.customer_policy_id));
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchClaimsAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchClaimProgress());
      const res = await api.get(
        `/policy/get-policy-business-claims?customer_policy_id=${customerPolicyId}`
      );
      const data: IClaims[] = res.data.data;
      dispatch(addClaim(data));
    } catch (err: any) {
      dispatch(fetchClaimFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_PREMIUM_PROGRESS = "FETCH_PREMIUM_PROGRESS";
export const ADD_PREMIUM = "ADD_PREMIUM";
export const FETCH_PREMIUM_FAILED = "FETCH_PREMIUM_FAILED";

export const fetchPremiumProgress = () => action(FETCH_PREMIUM_PROGRESS);
export const addPremium = (premium: IPremium) =>
  action(ADD_PREMIUM, { premium });
export const fetchPremiumFailed = () => action(FETCH_PREMIUM_FAILED);

export const addPremiumAsync =
  (
    data: IPremium,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/create-policy-business-premium", {
        ...data,
      });
      dispatch(addPremium(data));
      dispatch(
        showMessage({
          type: "success",
          message: "Policy premium saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };
export const fetchPremiumAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const isSaved =
      getState().business.basicDetails.premium.loading === LoadState.Loaded;

    try {
      if (!isSaved) {
        dispatch(fetchPriorPolicyProgress());
        const res = await api.get(
          `/policy/get-policy-business-premium?customer_policy_id=${customerPolicyId}`
        );
        const data: IPremium[] = res.data.data;
        if (data.length > 0) {
          dispatch(addPremium(data[0]));
        } else {
          dispatch(addPremium(initialBasicDetails["premium"]["data"]));
        }
      }
    } catch (err: any) {
      dispatch(fetchPremiumFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

// policy admin

export const FETCH_POLICY_ADMIN_PROGRESS = "FETCH_POLICY_ADMIN_PROGRESS";
export const ADD_POLICY_ADMIN = "ADD_POLICY_ADMIN";
export const FETCH_POLICY_ADMIN_FAILED = "FETCH_POLICY_ADMIN_FAILED";

export const fetchPolicyAdminProgress = () =>
  action(FETCH_POLICY_ADMIN_PROGRESS);
export const addPolicyAdmin = (data: IPolicyAdmin) =>
  action(ADD_POLICY_ADMIN, { data });
export const fetchPolicyAdminFailed = () => action(FETCH_POLICY_ADMIN_FAILED);

export const addPolicyAdminsync =
  (
    data: IPolicyAdmin,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const {create_ts,...rest} = data;
      dispatch(saveLoaderProgress());
      await api.post("/policy/create-policy-business-policy-admin", {
        ...rest,
      });
      dispatch(addPolicyAdmin(data));
      dispatch(
        showMessage({
          type: "success",
          message: "Policy admin saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };
export const fetchPolicyAdminAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const isSaved =
      getState().business.basicDetails.policyAdmin.loading === LoadState.Loaded;

    try {
      if (!isSaved) {
        dispatch(fetchPolicyAdminProgress());
        const res = await api.get(
          `/policy/get-policy-business-policy-admin?customer_policy_id=${customerPolicyId}`
        );
        const data: IPolicyAdmin[] = res.data.data;
        if (data.length > 0) {
          dispatch(addPolicyAdmin(data[0]));
        } else {
          dispatch(addPolicyAdmin(initialBasicDetails["policyAdmin"]["data"]));
        }
      }
    } catch (err: any) {
      dispatch(fetchPolicyAdminFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

// policy admin manager

export const FETCH_BUSINESS_POLICY_ADMIN_MANAGER_PROGRESS =
  "FETCH_BUSINESS_POLICY_ADMIN_MANAGER_PROGRESS";
export const FETCH_BUSINESS_POLICY_ADMIN_MANAGER_SUCCESS =
  "FETCH_BUSINESS_POLICY_ADMIN_MANAGER_SUCCESS";
export const FETCH_BUSINESS_POLICY_ADMIN_MANAGER_FAILED =
  "FETCH_BUSINESS_POLICY_ADMIN_MANAGER_FAILED";

export const fetchBusinessPolicyAdminManagerProgress = () =>
  action(FETCH_BUSINESS_POLICY_ADMIN_MANAGER_PROGRESS);
export const fetchBusinessPolicyAdminManagerSuccess = (
  data: IPolicyAdminManager
) => action(FETCH_BUSINESS_POLICY_ADMIN_MANAGER_SUCCESS, { data });
export const fetchBusinessPolicyAdminManagerFailed = (errorMessage: string) =>
  action(FETCH_BUSINESS_POLICY_ADMIN_MANAGER_FAILED, { errorMessage });

export const addBusinessPolicyAdminManager =
  (
    data: IPolicyAdminManager,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      //dispatch(fetchBusinessPolicyRiskProgress());
      await api.post(
        `/policy/create-policy-business-policy-admin-manager`,
        data
      );
      dispatch(
        showMessage({
          type: "success",
          message: "Policy Admin Manager saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchBusinessPolicyAdminManagerAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      // if (!isSaved) {
      dispatch(fetchBusinessPolicyAdminManagerProgress());
      const res = await api.get(
        `/policy/get-policy-business-policy-admin-manager?customer_policy_id=${customerPolicyId}`
      );
      const data: IPolicyAdminManager[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchBusinessPolicyAdminManagerSuccess(data[0]));
      } else {
        dispatch(
          fetchBusinessPolicyAdminManagerFailed(
            "Oops! We couldn't find any records associated with your fine art number at the moment. Please ensure that the provided information is accurate and try again."
          )
        );
        // }
      }
    } catch (err: any) {
      dispatch(
        fetchBusinessPolicyAdminManagerFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

/*--------*/
export const FETCH_BUSINESS_ACTIVITY_LIST_PROGRESS =
  "FETCH_BUSINESS_ACTIVITY_LIST_PROGRESS";
export const FETCH_BUSINESS_ACTIVITY_LIST_SUCCESS =
  "FETCH_BUSINESS_ACTIVITY_LIST_SUCCESS";

export const fetchBusinessActivityListProgress = () =>
  action(FETCH_BUSINESS_ACTIVITY_LIST_PROGRESS);
export const fetchBusinessActivityListSuccess = (data: any) =>
  action(FETCH_BUSINESS_ACTIVITY_LIST_SUCCESS);

export const FETCH_BUSINESS_ATTACHMENT_PROGRESS =
  "FETCH_BUSINESS_ATTACHMENT_PROGRESS";
export const FETCH_BUSINESS_ATTACHMENT_SUCCESS =
  "FETCH_BUSINESS_ATTACHMENT_SUCCESS";
export const FETCH_BUSINESS_ATTACHMENT_FAILED =
  "FETCH_BUSINESS_ATTACHMENT_FAILED";

export const fetchBusinessAttachmentProgress = () =>
  action(FETCH_BUSINESS_ATTACHMENT_PROGRESS);
export const fetchBusinessAttachmentSuccess = (
  data: IBusinessAttachment
  // customerPolicyId: number
) => action(FETCH_BUSINESS_ATTACHMENT_SUCCESS, { data });
export const fetchBusinessAttachmentFailed = (errorMessage: string) =>
  action(FETCH_BUSINESS_ATTACHMENT_FAILED, { errorMessage });

export const addBusinessAttachmentAsync =
  (
    data: IBusinessAttachment,
    policyId: number,
    file: any | null,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const asPayload = {
        customer_id: policyId,
        policy_type: "BUSINESS",
        customer_policy_id: data.customer_policy_id,
        page_name: "Attachment",
      };
      const path = await uploadFile(
        file,
        "POLICY",
        data.file_upload || "",
        asPayload
      );
      await api.post("/policy/edit-policy-business-attachments", {
        ...data,
        file_upload: path,
      });
      // const data = res.data.data;

      dispatch(fetchBusinessAttachmentSuccess(data));
      dispatch(
        showMessage({
          type: "success",
          message: "Attachment saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchBusinessAttachmentAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      // if (!isSaved) {
      dispatch(fetchBusinessAttachmentProgress());
      const res = await api.get(
        `/policy/get-policy-business-attachments?customer_policy_id=${customerPolicyId}`
      );
      const data: IBusinessAttachment[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchBusinessAttachmentSuccess(data[0]));
      } else {
        dispatch(
          fetchBusinessAttachmentFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
      // }
    } catch (err: any) {
      dispatch(fetchBusinessAttachmentFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_BUSINESS_ATTACHMENT_LIST_PROGRESS =
  "FETCH_BUSINESS_ATTACHMENT_LIST_PROGRESS";
export const FETCH_BUSINESS_ATTACHMENT_LIST_SUCCESS =
  "FETCH_BUSINESS_ATTACHMENT_LIST_SUCCESS";
export const FETCH_BUSINESS_ATTACHMENT_LIST_FAILED =
  "FETCH_BUSINESS_ATTACHMENT_LIST_FAILED";

export const fetchBusinessAttachmentListProgress = () =>
  action(FETCH_BUSINESS_ATTACHMENT_LIST_PROGRESS);
export const fetchBusinessAttachmentListSuccess = (
  data: IBusinessAttachment[]
) => action(FETCH_BUSINESS_ATTACHMENT_LIST_SUCCESS, { data });
export const fetchBusinessAttachmentListFailed = () =>
  action(FETCH_BUSINESS_ATTACHMENT_LIST_FAILED);

export const fetchBusinessAttachmentListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessAttachmentListProgress());
      const res = await api.get(
        `/policy/get-policy-business-attachments?customer_policy_id=${customerPolicyId}`
      );
      const data: IBusinessAttachment[] = res.data.data;
      dispatch(fetchBusinessAttachmentListSuccess(data));
    } catch (err: any) {
      dispatch(fetchBusinessAttachmentListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addBusinesAttachmentListAsync =
  (
    data: IBusinessAttachment,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post(`/policy/edit-policy-business-attachments`, data);
      dispatch(
        showMessage({
          type: "success",
          message: "Attachment saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLAER_BUSINESS_POLICY_ATTACHMENT_STATE =
  "CLAER_BUSINESS_POLICY_ATTACHMENT_STATE";
export const clearBusinessPolicyAttachment = () =>
  action(CLAER_BUSINESS_POLICY_ATTACHMENT_STATE);

export const CLEAR_BASIC_DETAILS_STATE = "CLEAR_BASIC_DETAILS_STATE";
export const clearBasicPolicyState = () => action(CLEAR_BASIC_DETAILS_STATE);
